import React from 'react'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import cross from '../../../../ressources/images/cross.svg'
import debounce from 'lodash/fp/debounce'

const Option = styled.div`
    display: flex;
    align-items: center;

    img {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
`

const Chip = styled.div`
    display: inline-flex;
    align-items: center;
    background: #6d77dc;
    color: #fff;
    border-radius: 16px;
    padding: 6px 10px 6px 42px;
    margin: 10px 8px 0 0;
    position: relative;
`

const ChipImg = styled.img`
    border-radius: 50%;
    position: absolute;
    left: 0;
    width: 30px;
    border: 2px solid #fff;
`

const ChipDelete = styled.img`
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background: #fff;
    margin-left: 10px;
    padding: 4px;

    &:hover {
        background: rgba(0,0,0,.4);
    }
`

export const PointOfSalesFilter = ({ values, onLoadOptions, onChange }) => {
    const optionNotAlreadySelected = option => 
        !values.map(({ value }) => value).includes(option.value)

    const getOptionLabel = option => <Option>
        <img src={option.data.logo} alt="" />
        {option.label}
    </Option>

    return (
        <>
            <AsyncSelect
                loadOptions={debounce(500)(onLoadOptions)}
                onChange={option => onChange(option, true)}
                value={null}
                placeholder="Rechercher..."
                noOptionsMessage={() => "Aucun établissement trouvé"}
                loadingMessage={() => "Chargement..."}
                filterOption={optionNotAlreadySelected}
                getOptionLabel={getOptionLabel}
            />
            {values.map(({ label, value, data }) => <Chip key={value}>
                <ChipImg src={data.logo} alt="" />
                <div>{label}</div>
                <ChipDelete src={cross} onClick={() => onChange(value, false)}></ChipDelete>
            </Chip>)}
        </>
    )
}