import React, { useEffect, useState } from "react";
import SubHeader from "../../subHeader";
import {
  PointOfSaleAdvancedSettings,
  PointOfSaleContact,
  PointOfSaleContactInformation,
  PointOfSaleDescription,
  PointOfSaleHoursInformation,
  PointOfSalePictures,
  SocialNetworks,
  Stepper,
  PointOfSaleLoader
} from "../components";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { useParams } from "react-router-dom";
import {
  createPointOfSaleAction,
  fetchCategoriesRequestAction,
  fetchPointOfSaleRequestAction,
  resetPointOfSaleAction,
  updatePointOfSaleAction
} from "../actions";
import icon_ok from "../../../ressources/images/PlacesView/icon_ok.svg";
import { useQuery } from "../../../helpers";
import { FORM_NAME } from "../constants";
import {
  selectFacebookCategories,
  selectGmbCategories,
  selectPointOfSale,
  selectRequestLoading
} from "../reducers";
import { fromJsonToState } from "../helpers";
import loadingIcon from "../../../ressources/images/loading-white.svg";
import styled from "styled-components";

const WarningSection = styled.div({
  fontSize: "16px",
  border: "solid 1px orange",
  color: "orange",
  marginBottom: "1.2rem",
  padding: "1rem",
  borderRadius: "5px"
});

const NewPointOfSale = ({
  dirty,
  handleSubmit,
  createPointOfSale,
  updatePointOfSale,
  fetchPointOfSale,
  fetchCategories,
  resetPointOfSale,
  initialize,
  history,
  pointOfSale,
  facebookCategories,
  gmbCategories,
  requestLoading,
  isTemporarilyClosed
}) => {
  const { id } = useParams();
  const query = useQuery();

  const [step, setStep] = useState(1);

  const {
    businessProfile,
    medias,
    facebookPage,
    gmbLocation,
    fetched
  } = pointOfSale;
  const { logo, coverPicture } = medias || {};
  const publishersAccess = {
    facebook: facebookPage,
    google: gmbLocation
  };
  const editMode = id !== undefined;

  useEffect(() => {
    if (facebookCategories.length === 0) fetchCategories("facebook");
    if (gmbCategories.length === 0) fetchCategories("gmb");

    return () => resetPointOfSale();
  }, []);

  useEffect(() => {
    if (editMode) {
      fetchPointOfSale(id);
      setStep(Number(query.get("tab")));
    } else {
      initialize({ contact: { phoneNumbers: [""] } });
    }
  }, [editMode]);

  useEffect(() => {
    if (fetched) initialize(fromJsonToState(businessProfile));
  }, [pointOfSale]);

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const nextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <SubHeader
        key="subHeader"
        Title={businessProfile?.name || "Mon établissement"}
        address={businessProfile?.address || ""}
        breadcrumb={{
          "Mon réseau": "/point-of-sales",
          [editMode ? "Profil établissement" : "Nouvel établissement"]: ""
        }}
        hasTab={editMode}
        tabs={{
          [`/point-of-sales/${id}/edit?tab=1`]: "Coordonnées",
          [`/point-of-sales/${id}/edit?tab=2`]: "Description",
          [`/point-of-sales/${id}/edit?tab=3`]: "Contact",
          [`/point-of-sales/${id}/edit?tab=4`]: "Photos",
          [`/point-of-sales/${id}/edit?tab=5`]: "Horaires",
          [`/point-of-sales/${id}/edit?tab=6`]: "Paramètres avancés"
        }}
        onChangeTab={tab => setStep(Number(tab.split("=")[1]))}
        history={history}
      />
      <div className="bodyContainer containerFlex">
        {!editMode && <Stepper activeStep={step} />}
        <div
          className={"containerWithFilter"}
          style={{ justifyContent: "space-between" }}
        >
          <div style={{ width: editMode ? "60%" : "100%" }}>
            {businessProfile?.isTemporarilyClosed && isTemporarilyClosed && (
              <WarningSection>
                <p>
                  <b>Attention:</b> Votre établissement est coché “ fermeture
                  temporaire” dans la section “horaires” . Décochez cette case
                  avant de modifier vos informations.
                </p>
              </WarningSection>
            )}
            {!editMode || fetched ? (
              <form>
                {step === 1 && <PointOfSaleContactInformation />}
                {step === 2 && (
                  <PointOfSaleDescription
                    GMBCategories={gmbCategories}
                    FBCategories={facebookCategories}
                  />
                )}
                {step === 3 && <PointOfSaleContact />}
                {step === 4 && (
                  <PointOfSalePictures
                    logo={logo}
                    coverPicture={coverPicture}
                  />
                )}
                {step === 5 && <PointOfSaleHoursInformation />}
                {step === 6 && <PointOfSaleAdvancedSettings />}
              </form>
            ) : (
              <PointOfSaleLoader />
            )}

            {!editMode ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                <button
                  style={{ visibility: step === 1 && "hidden" }}
                  type="button"
                  className="networkDataButton stroke"
                  onClick={prevStep}
                >
                  Passer à l'étape précédente
                </button>
                {step < 6 && (
                  <button
                    type="button"
                    className="networkDataButton"
                    onClick={handleSubmit(nextStep)}
                  >
                    Passer à l'étape suivante
                  </button>
                )}
                {step === 6 && (
                  <button
                    type="button"
                    className="networkDataButton ok"
                    onClick={handleSubmit(createPointOfSale)}
                  >
                    <img
                      style={{ marginRight: 8 }}
                      src={icon_ok}
                      alt="enregistré"
                    />
                    Enregistrer et créer mon établissement
                  </button>
                )}
              </div>
            ) : (
              <button
                type="button"
                className="networkDataButton ok"
                style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                disabled={!dirty || requestLoading}
                onClick={handleSubmit(values => updatePointOfSale(values))}
              >
                <img
                  style={{ marginRight: 8 }}
                  src={requestLoading ? loadingIcon : icon_ok}
                  alt=""
                />
                {requestLoading
                  ? "En cours de mise à jour"
                  : dirty
                  ? "Enregister pour mise à jour"
                  : "À jour"}
              </button>
            )}
          </div>
          {editMode && (
            <div style={{ width: "35%" }}>
              <SocialNetworks
                pointOfSaleId={id}
                publishersAccess={publishersAccess}
                loading={!fetched}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const postFormValueSelector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  pointOfSale: selectPointOfSale(state),
  facebookCategories: selectFacebookCategories(state),
  gmbCategories: selectGmbCategories(state),
  requestLoading: selectRequestLoading(state),
  isTemporarilyClosed: postFormValueSelector(state, "hours.isTemporarilyClosed")
});

const mapDispatchToProps = (dispatch, { history, match }) => ({
  createPointOfSale: values =>
    dispatch(createPointOfSaleAction(values, history)),
  updatePointOfSale: values =>
    dispatch(updatePointOfSaleAction(match.params.id, values)),
  fetchPointOfSale: id => dispatch(fetchPointOfSaleRequestAction(id, history)),
  resetPointOfSale: () => dispatch(resetPointOfSaleAction()),
  fetchCategories: publisher =>
    dispatch(fetchCategoriesRequestAction(publisher))
});

export const ConnectedNewPointOfSale = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(NewPointOfSale)
);
