import {
  FETCH_CATEGORIES_SUCCESS,
  FETCH_LIST_POINT_OF_SALE_REQUEST,
  FETCH_LIST_POINT_OF_SALE_SUCCESS,
  FETCH_POINT_OF_SALE_SUCCESS,
  RESET_POINT_OF_SALE,
  RESET_LIST_POINT_OF_SALE,
  UPDATE_POINT_OF_SALE,
  UPDATE_POINT_OF_SALE_SUCCESS,
  UPLOAD_MEDIAS_POINT_OF_SALE_SUCCESS,
  UPDATE_POINT_OF_SALES,
  UPDATE_POINT_OF_SALES_SUCCESS,
  UPDATE_POINT_OF_SALE_FAILURE,
  UPDATE_POINT_OF_SALES_FAILURE,
  UPDATE_SENSITIVE_FIELDS_SUCCESS,
  FETCH_POINT_OF_SALE_STATS_SUCCESS,
  BULK_UPDATE_SENSITIVE_FIELDS_FAILURE,
  UPDATE_END,
  RESET_BULK_UPDATE
} from "../constants";
import { createSelector } from "reselect";

const INITIAL_STATE = {
  pointOfSales: {
    businessProfiles: [],
    totalCount: 0,
    totalSync: 0,
    totalDesync: 0,
    fetched: false
  },
  pointOfSale: {
    fetched: false
  },
  stats: {
    facebook: {
      sync: 0,
      unsync: 0,
      disconnected: 0,
      count: 0
    },
    gmb: {
      sync: 0,
      unsync: 0,
      disconnected: 0,
      count: 0
    }
  },
  categories: {
    facebook: [],
    gmb: []
  },
  bulkUpdateErrors: {},
  requestLoading: false
};

export const PointOfSaleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LIST_POINT_OF_SALE_REQUEST:
      return {
        ...state,
        pointOfSales: { ...state.pointOfSales, fetched: false }
      };
    case FETCH_LIST_POINT_OF_SALE_SUCCESS:
      return { ...state, pointOfSales: { ...action.data, fetched: true } };
    case FETCH_POINT_OF_SALE_SUCCESS:
      const {
        gmbLogo,
        gmbCoverPicture
      } = action.data.businessProfile.gmbInformations;
      return {
        ...state,
        pointOfSale: {
          ...action.data,
          medias: { logo: gmbLogo, coverPicture: gmbCoverPicture },
          fetched: true
        }
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: { ...state.categories, [action.publisher]: action.data }
      };
    case UPDATE_POINT_OF_SALE:
    case UPDATE_POINT_OF_SALES:
      return { ...state, requestLoading: true };
    case UPDATE_POINT_OF_SALE_SUCCESS:
      return {
        ...state,
        pointOfSale: { ...state.pointOfSale, businessProfile: action.data },
        requestLoading: false
      };
    case UPDATE_POINT_OF_SALES_SUCCESS:
    case UPDATE_POINT_OF_SALE_FAILURE:
    case UPDATE_POINT_OF_SALES_FAILURE:
    case UPDATE_END:
      return { ...state, requestLoading: false };
    case UPDATE_SENSITIVE_FIELDS_SUCCESS:
      return {
        ...state,
        pointOfSale: {
          ...state.pointOfSale,
          businessProfile: {
            ...state.pointOfSale.businessProfile,
            ...action.data
          }
        }
      };
    case UPLOAD_MEDIAS_POINT_OF_SALE_SUCCESS:
      const { logo, coverPicture } = action.data;
      return {
        ...state,
        pointOfSale: {
          ...state.pointOfSale,
          medias: {
            logo: logo?.url || "",
            coverPicture: coverPicture?.url || ""
          }
        }
      };
    case BULK_UPDATE_SENSITIVE_FIELDS_FAILURE:
      const { ids, field } = action;
      let bulkUpdateErrors = {};
      ids.forEach(id => {
        const prevFields = state.bulkUpdateErrors[id] || [];
        bulkUpdateErrors = {
          ...bulkUpdateErrors,
          [id]: [...prevFields, field]
        };
      });
      return {
        ...state,
        bulkUpdateErrors
      };
    case FETCH_POINT_OF_SALE_STATS_SUCCESS:
      return { ...state, stats: action.data };
    case RESET_LIST_POINT_OF_SALE:
      return { ...state, pointOfSales: INITIAL_STATE.pointOfSales };
    case RESET_POINT_OF_SALE:
      return { ...state, pointOfSale: INITIAL_STATE.pointOfSale };
    case RESET_BULK_UPDATE:
      return { ...state, bulkUpdateErrors: INITIAL_STATE.bulkUpdateErrors };
    default:
      return state;
  }
};

export const selectPointOfSales = ({ PointOfSaleReducer }) =>
  PointOfSaleReducer.pointOfSales;
export const selectPointOfSale = ({ PointOfSaleReducer }) =>
  PointOfSaleReducer.pointOfSale;
export const selectPointOfSaleStats = ({ PointOfSaleReducer }) =>
  PointOfSaleReducer.stats;
export const selectCategories = ({ PointOfSaleReducer }) =>
  PointOfSaleReducer.categories;
export const selectRequestLoading = ({ PointOfSaleReducer }) =>
  PointOfSaleReducer.requestLoading;
export const selectBulkUpdateErrors = ({ PointOfSaleReducer }) =>
  PointOfSaleReducer.bulkUpdateErrors;

export const selectPointOfSaleByIds = pointOfSalesIds =>
  createSelector(selectPointOfSales, pointOfSales =>
    pointOfSales.businessProfiles.filter(bp =>
      pointOfSalesIds.includes(bp.businessProfile.id)
    )
  );

export const selectTemporarilyClosedPointOfSaleIds = pointOfSalesIds =>
  createSelector(selectPointOfSaleByIds(pointOfSalesIds), pointOfSales =>
    pointOfSales
      .filter(({ businessProfile }) => businessProfile.isTemporarilyClosed)
      .map(({ businessProfile }) => businessProfile.id)
  );

export const selectFacebookCategories = createSelector(
  selectCategories,
  categories =>
    categories.facebook
      .map(({ facebook_category_id, name }) => ({
        label: name,
        value: facebook_category_id
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || []
);

export const selectGmbCategories = createSelector(
  selectCategories,
  categories =>
    categories.gmb
      .map(({ category_id, name }) => ({ label: name, value: category_id }))
      .sort((a, b) => a.label.localeCompare(b.label)) || []
);

export const selectPointOfSalesSelector = createSelector(
  selectPointOfSales,
  pointOfSales =>
    pointOfSales.businessProfiles.map(({ businessProfile }) => ({
      label: businessProfile.name,
      value: businessProfile.id,
      data: { logo: businessProfile.gmbInformations.gmbLogo }
    }))
);
