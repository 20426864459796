import React from "react"
import like from '../../../../ressources/images/likeFacebook.svg'
import comment from '../../../../ressources/images/commentFacebook.svg'
import share from '../../../../ressources/images/shareFacebook.svg'
import LANGUAGES from '../../../../ressources/Languages'
import { FACEBOOK_PREVIEW_GRID_TEMPLATE_AREAS, FACEBOOK_PREVIEW_MAX_MEDIA_SHOWING } from '../../constants'

export const PreviewFacebook = ({ description, businessName, logo, medias, totalMedias }) => {
    const renderHeader = () => (
        <div className="flex">
            <div>
                <img src={logo} className="previewFacebookLogo" alt={businessName} width={30} height={30} />
            </div>
            <div className="previewFacebookTopContainer">
                <span className="previewFacebookTopName">{businessName}</span><br />
                <span className="previewFacebookTopDate">{LANGUAGES.preview.now}</span>
            </div>
        </div>
    )

    const renderIcon = (icon, text) => (
        <div>
            <img src={icon} alt={text} />
            <span>{text}</span>
        </div>
    )

    return (
        <div className={'previewFacebookContainer'}>
            <div className={'previewFacebookMain'}>
                {renderHeader()}
                <div className={'previewFacebookText'}>
                    {description?.map((text, index) => <div key={index}>{text}</div>)}
                </div>
                {medias.length > 0 && <div style={{position: "relative"}}>
                    <div style={{
                        display: 'grid',
                        gridGap: 2,
                        gridTemplateAreas: FACEBOOK_PREVIEW_GRID_TEMPLATE_AREAS[medias.length],
                        gridTemplateRows: medias.length > 2 ? "333px 165px" : medias.length > 1 ? "repeat(2, 243px)" : 333,
                        overflow: "hidden"
                    }}>
                        {medias.map(({ mimeType, data }, index) => (
                            <div key={index} style={{gridArea: `m${index+1}`, overflow: "hidden", display: "flex", justifyContent: "center", border: "1px solid #E3E4E5"}}>
                                {mimeType.startsWith("image") && <img src={atob(data)} style={{objectFit: 'cover'}} alt="" />}
                                {mimeType.startsWith("video") && <video src={atob(data)} controls />}
                            </div>
                        ))}
                    </div>
                    {totalMedias > FACEBOOK_PREVIEW_MAX_MEDIA_SHOWING && <div style={{position: "absolute", top: 335, left: 334, right: 0, bottom: 0, background: "rgba(0,0,0,.35)", color: "#fff", fontSize: "4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        + {totalMedias - medias.length}
                    </div>}
                </div>}
                <div className={'previewFacebookBottomContainer'}>
                    {renderIcon(like, 'Like')}
                    {renderIcon(comment, 'Comment')}
                    {renderIcon(share, 'Share')}
                </div>
            </div>
        </div>
    )
}