import React, { useState } from 'react'
import { FieldWrapper, renderCounterIcon } from '../../commons/components'
import { EditorState, CompositeDecorator, convertToRaw } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createEmojiPlugin from 'draft-js-emoji-plugin'
import createMentionPlugin from 'draft-js-mention-plugin'
import styled from 'styled-components'
import 'draft-js-emoji-plugin/lib/plugin.css'
import 'draft-js-mention-plugin/lib/plugin.css'
import { POST_MENTIONS } from '../mocks'

const EditorWrapper = styled.div`
    .DraftEditor-root {
        background: #fff
        height: 150px
        border: 1px solid #f2f2f2
        overflow-y: auto
    }
    .DraftEditor-editorContainer, .public-DraftEditor-content {
        height: 100%
        padding: 5px
    }
`

const EditorToolbar = styled.div`
    margin-top: 5px
    display: flex
    align-items: center
    .draftJsEmojiPlugin__emojiSelectButton__3sPol, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
        width: 1.5em
        font-size: 1.6em
        color: #6d77dc
        background: transparent
        border-radius: 50%
        border: none
        &:hover {
            background: rgba(109,119,220,.1)
        }
    }
`

const findWithRegex = (regex, contentBlock, callback) => {
    let result
    while ((result = regex.exec(contentBlock.getText())) !== null) {
        const start = result.index
        callback(start, start + result[0].length)
    }
}

const decorators = [
    {
        strategy: (contentBlock, callback) =>
            findWithRegex(/#[A-Za-z0-9]*/g, contentBlock, callback),
        component: props =>
            (<span {...props} style={{background: "rgba(109, 119, 220, .15)", borderBottom: "1px solid rgba(109, 119, 220, .3)"}}>{props.children}</span>)
    },
    {
        strategy: (contentBlock, callback) =>
            findWithRegex(/https:\/\/[A-Za-z0-9./]*/g, contentBlock, callback),
        component: props =>
            (<a style={{cursor: "pointer"}} href={props.children} {...props}>{props.children}</a>)
    }
]

const emojiPlugin = createEmojiPlugin()
const mentionPlugin = createMentionPlugin()
const hashtagPlugin = { decorators }

const { EmojiSuggestions, EmojiSelect } = emojiPlugin
const { MentionSuggestions } = mentionPlugin

export const PostMessage = ({ input, counterIcon, counterEnable, maxLength, ...props }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [suggestions, setSuggestions] = useState(POST_MENTIONS)

    const onEditorChange = newEditorState => {
        input.onChange(convertToRaw(newEditorState.getCurrentContent()))
        setEditorState(newEditorState)
    }

    const onSearchChange = value => {

    }

    return (
        <FieldWrapper {...props}>
            <EditorWrapper>
                <Editor editorState={editorState} onChange={onEditorChange} plugins={[emojiPlugin, hashtagPlugin, mentionPlugin]} onBlur={() => input.onBlur(input.value)} />
                <MentionSuggestions onSearchChange={onSearchChange} suggestions={suggestions}/>
                <EditorToolbar>
                    <div>
                        <EmojiSelect />
                        <EmojiSuggestions />
                    </div>
                    {counterEnable && renderCounterIcon(counterIcon, editorState.getCurrentContent().getPlainText('').length, maxLength)}
                </EditorToolbar>
            </EditorWrapper>
        </FieldWrapper>
    )
}