import React from "react";
import { Link } from "react-router-dom";

const warningMessageStyle = {
  fontFamily: "Roboto",
  fontSize: 14,
  color: "#B7544D"
};
const buttonBackStyle = {
  border: "1px solid #6D77DC",
  borderRadius: 5,
  fontFamily: "Barlow",
  fontWeight: "600",
  fontSize: 15,
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  color: "#FFFFFF",
  background: "#6D77DC",
  padding: "10px 80px",
  height: "60%",
  marginTop: 32
};

export const MultiEditConfirm = ({ pointOfSales, errors }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    }}
  >
    {Object.keys(errors).length > 0 && (
      <div style={warningMessageStyle}>
        <p>
          En raison de la situation actuelle, Google a rejeté votre demande de
          modification pour les points de vente suivants:
        </p>
        <ul>
          {Object.entries(errors).map(([id, fields]) => (
            <li key={id}>
              <strong>
                {
                  pointOfSales.find(
                    ({ businessProfile }) => businessProfile.id === id
                  ).businessProfile.name
                }
              </strong>
              <ul>
                {fields.map(field => (
                  <li key={field}>{field}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <p>Veuillez réessayer plus tard</p>
      </div>
    )}
    <Link to="/point-of-sales" style={{ ...buttonBackStyle, marginRight: 10 }}>
      RETOUR AU PRESENCE MANAGEMENT
    </Link>
  </div>
);
