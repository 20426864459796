import React, { useState } from "react";
import { Link } from "react-router-dom";
import splash from "../../../ressources/images/login/splash.jpg";
import splash2 from "../../../ressources/images/login/splash2.jpg";

export const Slide = () => {
    const [slidesActive, setSlidesActive] = useState([true, false, false]);

    const activateSlide = i => {
        const temp = Array(3).fill(false);
        temp[i] = true;
        setSlidesActive(temp);
    };

    return (
        <>
            <div className='slide_main'>
                <div className={'mySlides img-responsive'} style={{display: slidesActive[0] ? 'block' : 'none', backgroundImage: `url(${splash})`}}>
                    <div className={'slideShadow'} />
                    <div className={'slideContainer'}>
                        <h4>{'Toutes nos solutions sur Visiretail ®'}</h4>
                        <div style={{display: 'flex', position: 'relative', marginTop: 23, marginBottom: 23}}>
                            <div className={'localPub'}>{'Publicité locale'}</div>
                            <div className={'localPubOff'} onClick={() => activateSlide(1)}>{'Google My business, Facebook...'}</div>
                        </div>
                        <h2>{'Activez et suivez des '}</h2>
                        <h2>{'campagnes de publicités'}</h2>
                        <h2>{'locales avec votre réseau'}</h2>
                    </div>
                </div>
                <div className={'mySlides img-responsive'} style={{display: slidesActive[1] ? 'block' : 'none', backgroundImage: `url(${splash2})`}}>
                    <div className={'slideShadow'} />
                    <div className={'slideContainer'}>
                        <h4>{'Toutes nos solutions sur Visiretail ®'}</h4>
                        <div style={{display: 'flex', position: 'relative', marginTop: 23, marginBottom: 23}}>
                            <div className={'visibilityOff'} onClick={() => activateSlide(0)}>{'Publicité locale'}</div>
                            <div className={'visibility'}>{'Google My business, Facebook...'}</div>
                        </div>
                        <h2>{'Créez et gérez vos fiches Google'}</h2>
                        <h2>{'My Business, vos pages'}</h2>
                        <h2>{'Facebook et vos comptes Twitter'}</h2>
                        <h2>{'avec votre réseau'}</h2>
                    </div>
                </div>
                <div className="slide_rgpd">
                    <Link className="slide_rgpd-link" to="/terms-of-conditions">Conditions générales de vente</Link>
                    <Link className="slide_rgpd-link" to="/privacy-policies">Politique de confidentialité</Link>
                </div>
            </div>
        </>
    );
};