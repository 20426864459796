import { WHO_AM_I, AUTHENTICATION_REQUEST, LOGOUT_REQUEST } from "../constants";

export const authenticationRequestAction = (credentials, history) => ({
  type: AUTHENTICATION_REQUEST,
  credentials,
  history,
});

export const logoutAction = (history) => ({
  type: LOGOUT_REQUEST,
  history,
});

export const whoAmIAction = () => ({
  type: WHO_AM_I,
});
