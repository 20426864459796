import {
  CREATE_POINT_OF_SALE,
  FETCH_LIST_POINT_OF_SALE_REQUEST,
  FETCH_LIST_POINT_OF_SALE_SUCCESS,
  UPDATE_POINT_OF_SALES,
  UPDATE_POINT_OF_SALE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_POINT_OF_SALE_SUCCESS,
  FETCH_POINT_OF_SALE_REQUEST,
  RESET_POINT_OF_SALE,
  RESET_LIST_POINT_OF_SALE,
  UPDATE_POINT_OF_SALE_SUCCESS,
  UPLOAD_MEDIAS_POINT_OF_SALE_REQUEST,
  UPLOAD_MEDIAS_POINT_OF_SALE_SUCCESS,
  UPLOAD_MEDIAS_POINT_OF_SALES_REQUEST,
  UPDATE_POINT_OF_SALES_SUCCESS,
  UPDATE_POINT_OF_SALE_FAILURE,
  UPDATE_POINT_OF_SALES_FAILURE,
  FETCH_POINT_OF_SALE_STATS_REQUEST,
  FETCH_POINT_OF_SALE_STATS_SUCCESS,
  UPDATE_SENSITIVE_FIELDS_REQUEST,
  BULK_UPDATE_SENSITIVE_FIELDS_REQUEST,
  BULK_UPDATE_SENSITIVE_FIELDS_FAILURE,
  UPDATE_SENSITIVE_FIELDS_SUCCESS,
  UPDATE_END,
  RESET_BULK_UPDATE
} from "../constants";

export const createPointOfSaleAction = (values, history) => ({
  type: CREATE_POINT_OF_SALE,
  values,
  history
});

export const updatePointOfSaleAction = (id, values) => ({
  type: UPDATE_POINT_OF_SALE,
  id,
  values
});

export const updatePointOfSaleSuccessAction = data => ({
  type: UPDATE_POINT_OF_SALE_SUCCESS,
  data
});

export const updatePointOfSaleFailure = () => ({
  type: UPDATE_POINT_OF_SALE_FAILURE
});

export const updatePointOfSalesAction = (pointOfSales, values, history) => ({
  type: UPDATE_POINT_OF_SALES,
  pointOfSales,
  values,
  history
});

export const updatePointOfSalesSuccessAction = data => ({
  type: UPDATE_POINT_OF_SALES_SUCCESS,
  data
});

export const updatePointOfSalesFailure = () => ({
  type: UPDATE_POINT_OF_SALES_FAILURE
});

export const fetchListPointOfSaleRequestAction = filters => ({
  type: FETCH_LIST_POINT_OF_SALE_REQUEST,
  filters
});

export const fetchListPointOfSaleSuccessAction = data => ({
  type: FETCH_LIST_POINT_OF_SALE_SUCCESS,
  data
});

export const fetchPointOfSaleRequestAction = (id, history) => ({
  type: FETCH_POINT_OF_SALE_REQUEST,
  id,
  history
});

export const fetchPointOfSaleSuccessAction = data => ({
  type: FETCH_POINT_OF_SALE_SUCCESS,
  data
});

export const resetListPointOfSaleAction = () => ({
  type: RESET_LIST_POINT_OF_SALE
});

export const resetPointOfSaleAction = () => ({
  type: RESET_POINT_OF_SALE
});

export const fetchCategoriesRequestAction = publisher => ({
  type: FETCH_CATEGORIES_REQUEST,
  publisher
});

export const fetchCategoriesSuccessAction = (publisher, data) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  publisher,
  data
});

export const uploadMediasPointOfSaleRequest = (id, values) => ({
  type: UPLOAD_MEDIAS_POINT_OF_SALE_REQUEST,
  id,
  values
});

export const uploadMediasPointOfSaleSuccess = data => ({
  type: UPLOAD_MEDIAS_POINT_OF_SALE_SUCCESS,
  data
});

export const uploadMediasPointOfSalesRequest = (ids, values) => ({
  type: UPLOAD_MEDIAS_POINT_OF_SALES_REQUEST,
  ids,
  values
});

export const fetchPointOfSaleStatsRequestAction = () => ({
  type: FETCH_POINT_OF_SALE_STATS_REQUEST
});

export const fetchPointOfSaleStatsSuccessAction = data => ({
  type: FETCH_POINT_OF_SALE_STATS_SUCCESS,
  data
});

export const updateSensitiveFieldsRequestAction = (id, values) => ({
  type: UPDATE_SENSITIVE_FIELDS_REQUEST,
  id,
  values
});

export const updateSensitiveFieldsRequestSuccessAction = data => ({
  type: UPDATE_SENSITIVE_FIELDS_SUCCESS,
  data
});

export const bulkUpdateSensitiveFieldsRequestAction = (ids, values) => ({
  type: BULK_UPDATE_SENSITIVE_FIELDS_REQUEST,
  ids,
  values
});

export const bulkUpdateSensitiveFieldsFailureAction = (ids, field) => ({
  type: BULK_UPDATE_SENSITIVE_FIELDS_FAILURE,
  ids,
  field
});

export const updateEndAction = () => ({
  type: UPDATE_END
});

export const resetBulkUpdateAction = () => ({
  type: RESET_BULK_UPDATE
});
