import React from "react"
import {FieldArray, Field} from "redux-form";
import {renderDaysChecker, renderHoursChecker, renderCheckbox} from "../../../commons";
import { hoursValid } from '../../../../helpers'
import { datetimeValid } from '../../../../helpers/validators'

export const PointOfSaleHoursInformation = ({fields}) => (
    <>
        {(!fields || fields.hasMonday) &&
        <FieldArray
            name="hours.businessHours.monday"
            component={renderHoursChecker}
            day="Lundi"
            title="Horaires d’ouverture habituels"
            validate={hoursValid}
            isMandatory/>
        }
        {(!fields || fields.hasTuesday) &&
        <FieldArray
            name="hours.businessHours.tuesday"
            component={renderHoursChecker}
            validate={hoursValid}
            day="Mardi"/>
        }
        {(!fields || fields.hasWednesday) &&
        <FieldArray
            name="hours.businessHours.wednesday"
            component={renderHoursChecker}
            validate={hoursValid}
            day="Mercredi"/>
        }
        {(!fields || fields.hasThursday) &&
        <FieldArray
            name="hours.businessHours.thursday"
            component={renderHoursChecker}
            validate={hoursValid}
            day="Jeudi"/>
        }
        {(!fields || fields.hasFriday) &&
        <FieldArray
            name="hours.businessHours.friday"
            component={renderHoursChecker}
            validate={hoursValid}
            day="Vendredi"/>
        }
        {(!fields || fields.hasSaturday) &&
        <FieldArray
            name="hours.businessHours.saturday"
            component={renderHoursChecker}
            validate={hoursValid}
            day="Samedi"/>
        }
        {(!fields || fields.hasSunday) &&
        <FieldArray
            name="hours.businessHours.sunday"
            component={renderHoursChecker}
            validate={hoursValid}
            day="Dimanche"/>
        }
        {(!fields || fields.hasIsTemporarilyClosed) &&
        <Field 
            name="hours.isTemporarilyClosed" 
            component={renderCheckbox}
            title="Aménagement temporaire"
            description="Votre point de vente est fermé actuellement, mais rouvrira plus tard"
            text="Fermeture temporaire" />
        }
        <div style={{marginTop: 18}}/>
        {(!fields || fields.hasExceptionalOpeningHours) &&
        <FieldArray
            name="hours.exceptionalOpeningHours"
            component={renderDaysChecker}
            validate={datetimeValid}
            addLabel="Ajouter une autre date d’ouverture exceptionnelle"
            title="Horaires d’ouverture exceptionnels"/>
        }
        <div style={{marginTop: 42}}/>
        {(!fields || fields.hasExceptionalClosingTimes) &&
        <FieldArray
            name="hours.exceptionalClosingTimes"
            component={renderDaysChecker}
            validate={datetimeValid}
            addLabel="Ajouter une autre date de fermeture exceptionnelle"
            title="Horaires de fermeture exceptionnels"
            isClosing/>
        }
    </>
);