import React, { useEffect, useState } from "react";
import { useQuery } from "../../../helpers";
import { Link } from "react-router-dom";
import SubHeader from "../../subHeader";
import icon_add from "../../../ressources/images/PlacesView/icon_add.svg";
import { connect, batch } from "react-redux";
import {
  fetchListPointOfSaleRequestAction,
  resetListPointOfSaleAction,
  fetchPointOfSaleStatsRequestAction
} from "../actions";
import {
  MultiSelectorPanel,
  PointOfSaleItem,
  PointOfSaleItemLoader,
  PointOfSalePublisherStats
} from "../components";
import icon_cross from "../../../ressources/images/PlacesEdits/icon_cross.svg";
import styled from "styled-components";
import { selectPointOfSaleStats, selectPointOfSales } from "../reducers";

const PointOfSaleListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: 20px;
`;

const ListPointOfSale = ({
  match,
  history,
  pointOfSales,
  pointOfSaleStats,
  fetchListPointOfSale,
  fetchPointOfSaleStats,
  resetListPointOfSale
}) => {
  const [selectMode, setSelectMode] = useState(false);
  const [selected, setSelected] = useState([]);

  const state = useQuery().get("state") || "";

  const { businessProfiles, totalCount, fetched } = pointOfSales;

  useEffect(() => {
    batch(() => {
      fetchListPointOfSale({ state });
      fetchPointOfSaleStats();
    });

    return () => {
      resetListPointOfSale();
    };
  }, [state]);

  const handleSelect = id => {
    if (selectMode) {
      selected.includes(id)
        ? setSelected(selected.filter(s => s !== id))
        : setSelected([...selected, id]);
    } else {
      history.push(`/point-of-sales/${id}/edit?tab=1`);
    }
  };

  const handleSelectAll = () => {
    if (selected.length === businessProfiles.length) setSelected([]);
    else setSelected(businessProfiles.map(bp => bp.businessProfile.id));
  };

  const InfoBubble = ({ content, direction }) => (
    <div className={"bubbleContainer"}>
      <div className={"bubbleContent"}>
        {content}
        <div
          className={
            direction === "top" ? "bubbleArrowTop" : "bubbleArrowRight"
          }
        />
      </div>
    </div>
  );

  return (
    <>
      <SubHeader
        breadcrumb={{ "Mon réseau": "" }}
        Title="Mes établissements"
        customButton={
          <button
            className={selectMode ? "ButtonBlack" : "Button"}
            onClick={() => {
              setSelectMode(!selectMode);
              setSelected([]);
            }}
          >
            Éditer plusieurs établissements
            {selectMode && (
              <img
                src={icon_cross}
                alt="close"
                style={{ width: 12, height: 12 }}
              />
            )}
          </button>
        }
        hasTab
        history={history}
        tabs={[]}
      />
      <div className="addBusinessFloatingButtonContainer">
        <Link
          to={`${match.url}/new`}
          title="Ajouter un établissement"
          className="addBusinessFloatingButton"
        >
          <img
            src={icon_add}
            alt="Add business"
            style={{ width: 72, height: 72 }}
          />
        </Link>
        <div className="addBusinessFloatingButtonInfoBubble">
          <InfoBubble content="Ajouter un établissement" />
        </div>
      </div>
      <div key={"container"} className={"bodyContainer"}>
        <PointOfSalePublisherStats stats={pointOfSaleStats} />
        <div className={"placeSeparator"}>
          <div />
          <p className={"placeSeparatorText grid"}>
            Mes établissements ({totalCount})
          </p>
          <div />
        </div>
        <PointOfSaleListWrapper>
          {fetched
            ? businessProfiles.map(bp => (
                <PointOfSaleItem
                  key={bp.businessProfile.id}
                  selectMode={selectMode}
                  pointOfSale
                  selected={selected.includes(bp.businessProfile.id)}
                  onSelect={() => handleSelect(bp.businessProfile.id)}
                  {...bp}
                />
              ))
            : [...Array(6)]
                .fill()
                .map((_, index) => <PointOfSaleItemLoader key={index} />)}
        </PointOfSaleListWrapper>
        {selectMode && (
          <MultiSelectorPanel
            onButtonClick={() => {
              if (selected.length > 0) {
                history.push(`/point-of-sales/edit?ids=${selected.join()}`);
              }
            }}
            numberOfBusinesses={selected.length}
            selectNone={() => setSelected([])}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  pointOfSales: selectPointOfSales(state),
  pointOfSaleStats: selectPointOfSaleStats(state)
});

const mapDispatchToProps = dispatch => ({
  fetchListPointOfSale: filters =>
    dispatch(fetchListPointOfSaleRequestAction(filters)),
  fetchPointOfSaleStats: () => dispatch(fetchPointOfSaleStatsRequestAction()),
  resetListPointOfSale: () => dispatch(resetListPointOfSaleAction())
});

export const ConnectedListPointOfSale = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPointOfSale);
