import React from 'react'
import { Dialog } from '../../../commons/components'
import { useToggle } from '../../../../helpers'
import DateRangePicker from "react-daterange-picker";
import iconArrow from '../../../../ressources/images/icon_arrow.svg'
import styled from 'styled-components'
import "react-daterange-picker/dist/css/react-calendar.css";

const PublicationPeriodWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .period__label {
        font-weight: bold;
    }
    .period__arrow {
        border-radius: 50%;
        border: 1px solid #6d77dc;
        padding: 5px;
        width: 25px;
        height: 25px;
    }
`

export const PublicationPeriodFilter = ({ value, onChange }) => {
    const [dateRangeFilter, toggleDateRangeFilter] = useToggle()

    return (
        <>
            <PublicationPeriodWrapper onClick={toggleDateRangeFilter}>
                <div>
                    <div className="period__label">Début</div>
                    <div>{value?.start.format("DD/MM/YYYY") || "dd/mm/yyyy"}</div>
                </div>
                <img className="period__arrow" src={iconArrow} alt="arrow" />
                <div>
                    <div className="period__label">Fin</div>
                    <div>{value?.end.format("DD/MM/YYYY") || "dd/mm/yyyy"}</div>
                </div>      
            </PublicationPeriodWrapper>
            <Dialog title="Période de publication" isOpen={dateRangeFilter} onClose={toggleDateRangeFilter} noActions>
                <DateRangePicker
                    onSelect={dates => {
                        onChange(dates)
                        toggleDateRangeFilter()
                    }}
                    numberOfCalendars={2}
                    selectionType="range"
                    value={value}
                    locale="fr"
                />
            </Dialog>
        </>
    )
}