import { all } from "redux-saga/effects"
import { AuthSagas } from "../modules/auth/sagas";
import { PointOfSaleSagas } from "../modules/point-of-sale/sagas";
import { PostSagas } from '../modules/post/sagas'

export function* sagas() {
    yield all([
        ...AuthSagas,
        ...PointOfSaleSagas,
        ...PostSagas,
    ]);
}