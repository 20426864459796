import { delay, fork, put, takeEvery, call } from 'redux-saga/effects'
import { FETCH_LIST_POST_REQUEST, CREATE_POST, FETCH_POST_REQUEST, DELETE_POST } from '../constants'
import { fetchListPostSuccessAction, fetchPostSuccessAction, deletePostSuccessAction } from '../actions'
import { POSTS } from '../mocks'
import { toast } from 'react-toastify'
import { createPost, deletePost } from '../api'

function* fetchListPostWorker({ offset, filters }) {
    const { status, publishers } = filters

    const limit = 3
    const start = (offset - 1) * limit
    const end = offset * limit

    let postsFiltered = [...POSTS].filter(post => post.status === status)
    if (publishers.length > 0) {
        postsFiltered = postsFiltered.filter(post => publishers.includes(post.publisher))
    }
    const pageCount = Math.ceil(postsFiltered.length / limit)

    const posts = postsFiltered
        .reverse()
        .slice(start, end)

    try {
        yield delay(500)
        yield put(fetchListPostSuccessAction({ posts, pageCount }));
    } catch (e) {
        console.error(e)
    }
}

function* watchFetchListPostWorker() {
    yield takeEvery(FETCH_LIST_POST_REQUEST, fetchListPostWorker);
}

function* fetchPostWorker({ id }) {
    try {
        yield put(fetchPostSuccessAction(POSTS.find(post => post.id === id)))
    } catch (e) {
        console.error(e)
    }
}

function* watchFetchPostWorker() {
    yield takeEvery(FETCH_POST_REQUEST, fetchPostWorker)
}

function* createPostWorker({ values, history }) {
    try {
        //yield call(createPost, values);
        history.push(`/posts?status=published`)
        toast.success('Publication créée avec succès !')
    } catch (e) {
        console.error(e);
    }
}

function* watchCreatePost() {
    yield takeEvery(CREATE_POST, createPostWorker);
}

function* deletePostWorker({ id }) {
    try {
        //yield call(deletePost, id)
        yield put(deletePostSuccessAction(id))
        toast.success('Publication supprimée')
    } catch (e) {
        console.error(e)
    }
}

function* watchDeletePost() {
    yield takeEvery(DELETE_POST, deletePostWorker);
}

export const PostSagas = [
    fork(watchFetchListPostWorker),
    fork(watchFetchPostWorker),
    fork(watchCreatePost),
    fork(watchDeletePost)
]