export const POSTS = [
    {
        id: 1,
        publisher: "google",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=1", "https://picsum.photos/200/300?random=2", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3", "https://picsum.photos/200/300?random=3"],
        status: "published",
        description: "Lorem ipsum dolor sit amet nullam sodales.",
        createdAt: "2020-03-20 10:00:00"
    },
    {
        id: 2,
        publisher: "google",
        businessName: "[Mock] BusinessName",
        medias: [],
        status: "published",
        description: "Proin nisl dolor, suscipit sit amet metus.",
        createdAt: "2020-03-20 11:00:00"
    },
    {
        id: 3,
        publisher: "google",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=6", "https://picsum.photos/200/300?random=7"],
        status: "published",
        description: "Sed a ex iaculis, accumsan elit in nullam. ",
        createdAt: "2020-03-20 17:00:00"
    },
    {
        id: 4,
        publisher: "facebook",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=8"],
        status: "archived",
        description: "Nunc sed turpis et sem maximus massa nunc. ",
        createdAt: "2020-03-20 17:22:00"
    },
    {
        id: 5,
        publisher: "facebook",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=9", "https://picsum.photos/200/300?random=10", "https://picsum.photos/200/300?random=11"],
        status: "published",
        description: "Donec porttitor fringilla eros massa nunc. ",
        createdAt: "2020-03-21 14:00:00"
    },
    {
        id: 6,
        publisher: "google",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=12"],
        status: "archived",
        description: "Fusce non ante magna. Cras commodo nullam. ",
        createdAt: "2020-03-21 17:42:00"
    },
    {
        id: 7,
        publisher: "facebook",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=13", "https://picsum.photos/200/300?random=14", "https://picsum.photos/200/300?random=15"],
        status: "published",
        description: "Praesent sodales felis diam, sit amet sed. ",
        createdAt: "2020-03-22 10:00:00"
    },
    {
        id: 8,
        publisher: "facebook",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=16", "https://picsum.photos/200/300?random=17", "https://picsum.photos/200/300?random=18"],
        status: "published",
        description: "Ut nec varius tellus. Phasellus odio amet. ",
        createdAt: "2020-03-22 12:00:00"
    },
    {
        id: 9,
        publisher: "google",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=19"],
        status: "published",
        description: "Suspendisse id mollis justo, sit amet sed. ",
        createdAt: "2020-03-23 14:00:00"
    },
    {
        id: 10,
        publisher: "google",
        businessName: "[Mock] BusinessName",
        medias: ["https://picsum.photos/200/300?random=20", "https://picsum.photos/200/300?random=21"],
        status: "scheduled",
        description: "Duis tellus velit, semper quis massa nunc. ",
        createdAt: "2020-03-23 16:00:00"
    }
]

export const POST_MENTIONS = [
    {
        name: 'Matthew Russell',
        link: 'https://twitter.com/mrussell247',
        avatar: 'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
    },
    {
        name: 'Julian Krispel-Samsel',
        link: 'https://twitter.com/juliandoesstuff',
        avatar: 'https://avatars2.githubusercontent.com/u/1188186?v=3&s=400',
    },
    {
        name: 'Jyoti Puri',
        link: 'https://twitter.com/jyopur',
        avatar: 'https://avatars0.githubusercontent.com/u/2182307?v=3&s=400',
    },
    {
        name: 'Max Stoiber',
        link: 'https://twitter.com/mxstbr',
        avatar: 'https://pbs.twimg.com/profile_images/763033229993574400/6frGyDyA_400x400.jpg',
    },
    {
        name: 'Nik Graf',
        link: 'https://twitter.com/nikgraf',
        avatar: 'https://avatars0.githubusercontent.com/u/223045?v=3&s=400',
    },
    {
        name: 'Pascal Brandt',
        link: 'https://twitter.com/psbrandt',
        avatar: 'https://pbs.twimg.com/profile_images/688487813025640448/E6O6I011_400x400.png',
    }
]