import React from 'react'
import ContentLoader from 'react-content-loader'

export const PointOfSaleLoader = () => (
    <ContentLoader 
        width="100%"
        height={604}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        viewBox="0 0 100% 75"
    >
        <rect x="0" y="0" width="38" height="16" /> 
        <rect x="0" y="22" width="100%" height="52" /> 
        <rect x="0" y="88" width="119" height="16" /> 
        <rect x="0" y="110" width="100%" height="52" />
        <rect x="0" y="176" width="52" height="16" /> 
        <rect x="0" y="198" width="100%" height="52" /> 
        <rect x="0" y="264" width="124" height="16" /> 
        <rect x="0" y="286" width="100%" height="52" /> 
        <rect x="0" y="352" width="75" height="16" /> 
        <rect x="0" y="374" width="100%" height="52" /> 
        <rect x="0" y="440" width="28" height="16" /> 
        <rect x="0" y="462" width="100%" height="52" />
        <rect x="0" y="528" width="39" height="16" /> 
        <rect x="0" y="550" width="100%" height="52" /> 
    </ContentLoader>
)