import React from 'react'
import ContentLoader from 'react-content-loader'

export const PointOfSaleItemLoader = () => (
    <ContentLoader 
        speed={2}
        width={200}
        height={250}
        viewBox="0 0 200 250"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{background: "#fff"}}
    >
        <rect x="0" y="0" rx="0" ry="0" width="200" height="120" /> 
        <rect x="0" y="120" rx="0" ry="0" width="7" height="130" /> 
        <rect x="193" y="120" rx="0" ry="0" width="7" height="130" /> 
        <rect x="0" y="243" rx="0" ry="0" width="200" height="7" /> 
        <rect x="24" y="134" rx="3" ry="3" width="70" height="7" /> 
        <rect x="24" y="154" rx="3" ry="3" width="120" height="7" /> 
        <rect x="24" y="168" rx="3" ry="3" width="86" height="7" />
    </ContentLoader>
)