export const fromStateToJson = ({
    publishers,
    pointOfSales,
    description,
    medias,
    publicationChoice,
    schedulePublicationDate,
    schedulePublicationTime
}) => ({
    publishers,
    businessProfiles: pointOfSales || [],
    description,
    medias: medias || [],
    scheduledPublishedTime: publicationChoice !== "now" ? new Date(`${schedulePublicationDate} ${schedulePublicationTime}`) : new Date()
})

export const fromJsonToState = ({
    publisher,
    status
}) => ({
    publishers: [publisher],
    pointOfSales: [{ value: 1, label: '[MOCK] BusinessName' }],
    publicationChoice: status === 'published' ? 'now' : 'schedule'
})
