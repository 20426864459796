import React, { useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import { reduxForm, Field, formValueSelector, initialize } from 'redux-form'
import {
    RenderDropZone,
    renderInput,
    renderRadioGroup,
    renderSelector,
    renderTime
} from '../../commons'
import { maxMedia, required, useOutsideClick } from '../../../helpers'
import { FACEBOOK_PUBLISHER, GOOGLE_PUBLISHER } from '../../commons'
import LANGUAGES from '../../../ressources/Languages'
import { PostPreview } from './PostPreview'
import { fetchListPointOfSaleRequestAction } from '../../point-of-sale/actions'
import pick from 'lodash/fp/pick'
import minBy from 'lodash/fp/minBy'
import min from 'lodash/fp/min'
import GiphySelect from 'react-giphy-select'
import 'react-giphy-select/lib/styles.css';
import LOGO from '../../../ressources/functions/logo'
import { ALL_POSTS_CONFIG } from '../constants'
import { PostMessage } from './PostMessage'
import { NO_PUBLISHER_SELECTED } from '../constants/config'
import { fromJsonToState } from "../helpers"
import { selectPointOfSalesSelector } from "../../point-of-sale/reducers"

const PUBLISHER_OPTIONS = [
    { label: "Facebook", value: FACEBOOK_PUBLISHER },
    { label: "Google", value: GOOGLE_PUBLISHER },
]

const PostForm = ({ post, mode, onSubmit, handleSubmit, initialize, publishers, isSchedulePublication, firstPointOfSaleID, medias, description, pointOfSales, fetchListPointOfSale }) => {
    const [isGifMenuOpen, setIsGifOpenMenu] = useState(false)
    const gifMenu = useRef()
    useOutsideClick(gifMenu, () => setIsGifOpenMenu(false))

    const [rules, setRules] = useState({})

    useEffect(() => {
        //fetchListPointOfSale()
    }, [])

    useEffect(() => {
        if (mode === 'edit') {
            initialize(fromJsonToState(post))
        }
    }, [mode])

    useEffect(() => {
        const publishersConfig = Object.values(pick(publishers)(ALL_POSTS_CONFIG))
        const minDescription = minBy('description')(publishersConfig)
        const maxLengthDescription = minDescription?.description || 5
        const maxMedia = minBy('maxMedia')(publishersConfig)
        const videoMaxUpload = minBy('video.maxUpload')(publishersConfig)?.video.maxUpload
        const imageMaxUpload = minBy('image.maxUpload')(publishersConfig)?.image.maxUpload
        setRules({
            description: {
                publisher: minDescription?.label || "",
                maxLength: maxLengthDescription
            },
            medias: {
                publisher: maxMedia?.label,
                video: {
                    maxDuration: minBy('video.maxDuration')(publishersConfig)?.video.maxDuration || 0,
                    maxWeight: minBy('video.maxWeight')(publishersConfig)?.video.maxWeight,
                    minResolution: minBy('video.minResolution')(publishersConfig)?.video.minResolution || [0, 0],
                    maxUpload: videoMaxUpload || 0
                },
                image: {
                    maxWeight: minBy('image.maxWeight')(publishersConfig)?.image.maxWeight,
                    maxGif: minBy('image.maxGif')(publishersConfig)?.image.maxGif,
                    minResolution: minBy('image.minResolution')(publishersConfig)?.image.minResolution || [0, 0],
                    maxUpload: imageMaxUpload || 0
                },
                multiMedia: maxMedia?.multiMedia || false,
                maxMedia: medias?.length === 0 ? maxMedia?.maxMedia : medias?.some(({ mimeType }) => mimeType.startsWith('video')) ? min([videoMaxUpload, imageMaxUpload]) : imageMaxUpload || 0
            },
        })
    }, [publishers])

    const renderGifButton = () => (
        <div ref={gifMenu}>
            <img
                src={require('../../../ressources/images/icon_gif.svg')}
                alt="gif"
                height={25}
                width={25}
                className="GifIcon"
                onClick={() => setIsGifOpenMenu(!isGifMenuOpen)}
            />
            {isGifMenuOpen && <div className="absolute " style={{zIndex: 5}}>
                <GiphySelect requestKey="bKlOl4nYSxFgw2xExayORtsDEMhOtA4I" onEntrySelect={gif => null} />
            </div>}
        </div>
    )

    const renderPublicationDate = () => (
        <div style={{display: "flex", alignItems: "center"}}>
            <Field style={{height: 47, margin: 0}} name="schedulePublicationDate" component={renderInput} validate={isSchedulePublication ? required : null} type="date" />
            <span style={{marginLeft: 8, marginRight: 8}}>à</span>
            <Field name="schedulePublicationTime" component={renderTime} validate={isSchedulePublication ? required : null} />
        </div>
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Field
                name="publishers"
                title="Réseaux de diffusion"
                placeholder="Sélectionnez les réseaux sociaux sur lequels publier"
                component={renderSelector}
                options={PUBLISHER_OPTIONS}
                validate={required}
                max={PUBLISHER_OPTIONS.length}
                isMulti isMandatory
                isDisabled={mode === 'edit'}
            />
            <Field
                name="pointOfSales"
                title="Établissements de diffusion"
                placeholder="Sélectionnez les établissements sur lequels publier"
                component={renderSelector}
                options={pointOfSales}
                validate={required}
                max={pointOfSales.length}
                isMulti isMandatory
                isDisabled={mode === 'edit'}
            />
            <Field
                name="description"
                title="Message"
                description="Si vous souhaitez publier à la fois sur twitter et un autre réseau social en dépassant la limite de 280 caractères, vous devez créer deux publications séparées"
                placeholder="Votre message"
                component={PostMessage}
                counterIcon={LOGO.Default[rules.description?.publisher]}
                counterEnable={publishers}
                maxLength={rules.description?.maxLength}
                isMandatory
            />
            <div style={{marginBottom: 16}}></div>
            <Field
                name="medias"
                title="Medias"
                placeholder="Ajouter une photo ou une vidéo"
                component={RenderDropZone}
                //validate={maxMedia(rules.medias?.maxMedia)}
                disable={!publishers ? NO_PUBLISHER_SELECTED : undefined}
                accept={"image/png, image/jpeg, image/gif, video/*"}
                maxLength={rules.medias?.maxMedia}
                counterIcon={LOGO.Default[rules.medias?.publisher]}
                rules={rules.medias}
            />
            <PostPreview
                publishers={publishers}
                pointOfSale={pointOfSales.find(({ value }) => value === firstPointOfSaleID)}
                description={description?.blocks.map(({ text }) => text)}
                medias={medias}
            />
            <div style={{marginBottom: 16}}></div>
            <Field
                name="publicationChoice"
                title="Date de publication"
                component={renderRadioGroup}
                options={[
                    { label: "Publier maintenant", value: "now" },
                    { label: "Programmer le post pour le", value: "schedule", afterComponent: renderPublicationDate() },
                ]}
            />
            <div className="publishButtonContainer" style={{marginBottom: 10}}>
                <button className="publishButton full" type="submit">{LANGUAGES.Publish.publish}</button>
            </div>
        </form>
    )
}

const postFormValueSelector = formValueSelector("PostForm")

const mapStateToProps = state => ({
    publishers: postFormValueSelector(state, "publishers"),
    firstPointOfSaleID: postFormValueSelector(state, "pointOfSales[0]"),
    description: postFormValueSelector(state, "description"),
    medias: postFormValueSelector(state, "medias"),
    isSchedulePublication: postFormValueSelector(state, "publicationChoice") === "schedule",
    pointOfSales: selectPointOfSalesSelector(state)
})

const mapDispatchToState = dispatch => ({
    fetchListPointOfSale: () => dispatch(fetchListPointOfSaleRequestAction())
})

export const ConnectedPostForm = connect(
    mapStateToProps, mapDispatchToState
)(reduxForm({
    form: "PostForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    initialValues: {
        publicationChoice: "now",
        medias: []
    },
    validate: values => ({
        description: required(values?.description?.blocks[0].text)
    })
})(PostForm))
