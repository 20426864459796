import React from "react";
import { PostListItem } from './PostListItem'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import moment from "moment"
import { PostListItemLoader } from "./PostListItemLoader";

export const PostList = ({ posts, loading, hasNextPage, onLoadMore, onDeletePost }) => {
    const postListInfiniteRef = useInfiniteScroll({
        loading: !loading,
        hasNextPage,
        onLoadMore
    })

    return (
        <div ref={postListInfiniteRef}>
            {Object.entries(posts).map(([createdAt, items]) => (
                <div key={createdAt} style={{marginBottom: 40}}>
                    <div className="conversationDate">{moment(createdAt).format('dddd DD MMMM YYYY')}</div>
                    {items.map(post => (
                        <PostListItem key={post.id} {...post} onDeletePost={onDeletePost} />
                    ))}
                </div>
            ))}
            {!loading && (
                <>
                    <PostListItemLoader />
                    <PostListItemLoader />
                </>
            )}
        </div>
    )
}