import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  MultiEditChecker,
  MultiEditSummary,
  MultiEditForm,
  MultiEditHeader,
  MultiEditNavigation,
  MultiEditStepper,
  MultiEditConfirm
} from "../components";
import {
  fetchCategoriesRequestAction,
  fetchListPointOfSaleRequestAction,
  resetBulkUpdateAction,
  updatePointOfSalesAction
} from "../actions";
import { change, formValueSelector, reduxForm } from "redux-form";
import icon_checkDone from "../../../ressources/images/PlacesEdits/icon_checkDone.svg";
import {
  selectFacebookCategories,
  selectGmbCategories,
  selectPointOfSaleByIds,
  selectRequestLoading,
  selectTemporarilyClosedPointOfSaleIds,
  selectBulkUpdateErrors
} from "../reducers";
import { Link } from "react-router-dom";
import styled from "styled-components";

const containerStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  width: "100%",
  minHeight: "100%",
  background: "#FFFFFF",
  paddingBottom: 85
};
const titleContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "45px 0"
};
const titleStyle = {
  fontFamily: "Roboto",
  fontSize: 24,
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  color: "#000000"
};

const INITIAL_VALUES = {
  selected: {
    publishersDescription: {
      hasGmbMainCategory: false,
      hasGmbAdditionalCategories: false,
      hasWebsiteUrl: false,
      hasFbDescription: false,
      hasGmbDescription: false,
      hasFbCategories: false
    },
    contact: {
      hasPhone: false,
      hasEmail: false
    },
    media: {
      hasLogo: false,
      hasCoverPicture: false
    },
    hours: {
      hasMonday: false,
      hasTuesday: false,
      hasWednesday: false,
      hasThursday: false,
      hasFriday: false,
      hasSaturday: false,
      hasSunday: false,
      hasIsTemporarilyClosed: false,
      hasExceptionalOpeningHours: false,
      hasExceptionalClosingTimes: false
    }
  },
  contact: {
    phoneNumbers: [""]
  },
  hours: {
    isTemporarilyClosed: false
  }
};

const WarningSection = styled.div({
  fontSize: "16px",
  border: "solid 1px orange",
  color: "orange",
  marginTop: "1.2rem",
  padding: "1rem",
  borderRadius: "5px"
});

const getTemporarilyClosedPointOfSales = (pointOfSales, closedIds) =>
  pointOfSales.filter(({ businessProfile }) =>
    closedIds.includes(businessProfile.id)
  );

const MultiEditPointOfSale = ({
  handleSubmit,
  selectedFields,
  pointOfSales,
  temporarilyClosedPointOfSaleIds,
  isTemporarilyClosed,
  bulkUpdateErrors,
  changeFieldValue,
  updatePointOfSales,
  fetchListPointOfSales,
  fetchCategories,
  facebookCategories,
  gmbCategories,
  requestLoading,
  resetBulkUpdateAction
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [canNext, setCanNext] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (!selectedFields) return;

    const { publishersDescription, contact, media, hours } = selectedFields;

    if (publishersDescription) {
      const {
        hasGmbMainCategory,
        hasGmbAdditionalCategories,
        hasFbCategories
      } = publishersDescription;
      if (
        gmbCategories.length === 0 &&
        (hasGmbMainCategory || hasGmbAdditionalCategories)
      )
        fetchCategories("gmb");
      if (facebookCategories.length === 0 && hasFbCategories)
        fetchCategories("facebook");
    }

    setCanNext(
      Object.values(publishersDescription || []).some(field => field) ||
        Object.values(contact || []).some(field => field) ||
        Object.values(media || []).some(field => field) ||
        Object.values(hours || []).some(field => field)
    );
  }, [selectedFields]);

  useEffect(() => {
    if (pointOfSales.length === 0) fetchListPointOfSales();

    return () => {
      resetBulkUpdateAction();
    };
  }, [pointOfSales]);

  const prevStep = () => setCurrentStep(currentStep - 1);

  const nextStep = values => {
    if (currentStep === 2) setValues(values);
    setCurrentStep(currentStep + 1);
  };

  const handleMultiSubmit = values => {
    updatePointOfSales(pointOfSales, values);
    nextStep(values);
  };

  const isPointOfSaleTemporarilyClosed = () =>
    temporarilyClosedPointOfSaleIds.length > 0 &&
    (!selectedFields?.hours.hasIsTemporarilyClosed || isTemporarilyClosed);

  return (
    <div style={containerStyle}>
      <MultiEditHeader totalPointOfSales={pointOfSales.length} />
      <MultiEditStepper currentStep={currentStep} />

      <div style={{ padding: "0 16%", marginBottom: 20 }}>
        {isPointOfSaleTemporarilyClosed() && (
          <WarningSection>
            <p>
              <b>Attention:</b> Certains de vos établissements sont cochés
              “fermeture temporaire” dans la section “horaires” . Décochez cette
              case avant de modifier vos informations.
            </p>
            <ul>
              {getTemporarilyClosedPointOfSales(
                pointOfSales,
                temporarilyClosedPointOfSaleIds
              ).map(({ businessProfile }) => (
                <li key={businessProfile.id}>
                  <Link
                    style={{ color: "orange" }}
                    to={`/point-of-sales/${businessProfile.id}/edit?tab=1`}
                  >
                    {businessProfile.name}
                  </Link>
                </li>
              ))}
            </ul>
          </WarningSection>
        )}
        <form>
          {currentStep === 1 && (
            <>
              <div style={titleContainerStyle}>
                <h2 style={titleStyle}>
                  Quelle(s) donnée(s) souhaitez vous modifier pour{" "}
                  {pointOfSales.length} établissement(s) ?
                </h2>
              </div>
              <MultiEditChecker
                selectedFields={selectedFields}
                changeFieldValue={changeFieldValue}
              />
            </>
          )}
          {currentStep === 2 && (
            <>
              <div style={titleContainerStyle}>
                <h2 style={titleStyle}>
                  Entrez les nouvelles données à mettre à jour
                </h2>
              </div>
              <MultiEditForm
                GMBCategories={gmbCategories}
                FBCategories={facebookCategories}
                selectedFields={selectedFields}
              />
            </>
          )}
        </form>

        {currentStep === 3 && (
          <>
            <div style={titleContainerStyle}>
              <h2 style={titleStyle}>
                Confirmation des modifications à éditer
              </h2>
            </div>
            <MultiEditSummary
              GMBCategories={gmbCategories}
              FBCategories={facebookCategories}
              values={values}
              pointOfSales={pointOfSales}
            />
          </>
        )}
        {currentStep === 4 && (
          <>
            <div style={{ ...titleContainerStyle, flexDirection: "column" }}>
              <img src={icon_checkDone} alt="checkbox" />
              <h2 style={titleStyle}>
                Votre demande de modification a bien été enregistrée.
              </h2>
            </div>
            <MultiEditConfirm
              pointOfSales={pointOfSales}
              errors={bulkUpdateErrors}
            />
          </>
        )}
      </div>

      {currentStep < 4 && (
        <MultiEditNavigation
          totalPointOfSales={pointOfSales.length}
          loading={requestLoading}
          canPrev={currentStep === 1}
          canNext={canNext}
          canEnd={currentStep === 3}
          prevStep={prevStep}
          nextStep={handleSubmit(nextStep)}
          endStep={handleSubmit(handleMultiSubmit)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const ids = new URLSearchParams(ownProps.location.search)
    .get("ids")
    .split(",");
  return {
    selectedFields: formValueSelector("PointOfSaleMultiEdit")(
      state,
      "selected"
    ),
    isTemporarilyClosed: formValueSelector("PointOfSaleMultiEdit")(
      state,
      "hours.isTemporarilyClosed"
    ),
    pointOfSales: selectPointOfSaleByIds(ids)(state),
    temporarilyClosedPointOfSaleIds: selectTemporarilyClosedPointOfSaleIds(ids)(
      state
    ),
    bulkUpdateErrors: selectBulkUpdateErrors(state),
    facebookCategories: selectFacebookCategories(state),
    gmbCategories: selectGmbCategories(state),
    requestLoading: selectRequestLoading(state)
  };
};

const mapDispatchToProps = (dispatch, { history }) => ({
  changeFieldValue: (fieldName, value) =>
    dispatch(change("PointOfSaleMultiEdit", fieldName, value)),
  updatePointOfSales: (pointOfSales, values) =>
    dispatch(updatePointOfSalesAction(pointOfSales, values, history)),
  fetchListPointOfSales: () => dispatch(fetchListPointOfSaleRequestAction()),
  fetchCategories: publisher =>
    dispatch(fetchCategoriesRequestAction(publisher)),
  resetBulkUpdateAction: () => dispatch(resetBulkUpdateAction())
});

export const ConnectedMultiEditPointOfSale = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "PointOfSaleMultiEdit",
    initialValues: INITIAL_VALUES
  })(MultiEditPointOfSale)
);
