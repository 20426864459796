/*******************************************************************************
 ** @Author:					Florian
 ** @Email:					florian@shoki.io
 ** @Date:					Wednesday 12 June 2019 - 15:41:03
 ** @Filename:				subHeader.js
 **
 ** @Last modified by:		Tbouder
 ** @Last modified time:		Tuesday 10 December 2019 - 13:22:18
 *******************************************************************************/
import React from "react";
import styled from "@emotion/styled";

/******************************************************************************
 *    !SubHeader
 *    Composant subHeader
 *
 ******************************************************************************/

const CovidSection = styled.div({
  fontSize: "16px",
  border: "solid 1px red",
  color: "red",
  margin: "1rem 5rem",
  padding: "1rem",
  borderRadius: "5px",
});

function SubHeader(props) {
  /******************************************************************************
   *    !onClickBread
   *    Lors du click sur un breadcrumb, changer de page
   *
   ******************************************************************************/
  function onClickBread(value) {
    if (value !== "") props.history.push(value);
  }
  function renderTitle() {
    return (
      <div>
        <span className={"title"}>{props.Title}</span>
      </div>
    );
  }
  function renderAddress() {
    if (props.address) {
      return <span className={"address"}>{props.address}</span>;
    }
  }
  /******************************************************************************
   *    !renderMainInfo
   *    Affiche la partie haut a gauche du subHeader (titre + breadcrumb)
   *
   ******************************************************************************/
  function renderMainInfo() {
    return (
      <div className={"mainInfo"}>
        <div className={"breadcrumbShoki"}>
          {Object.entries(props.breadcrumb).map(([key, value], index) => {
            const breadLength = Object.entries(props.breadcrumb).length;
            return (
              <div
                className={`breadContainer ${
                  index === breadLength - 1 && "active"
                }`}
                key={`breadContainer ${index}`}
                onClick={() => onClickBread(value)}
              >
                <span>{key}</span>
                {index !== breadLength - 1 && <span>&nbsp;{">>"}&nbsp;</span>}
              </div>
            );
          })}
        </div>
        {renderTitle()}
        {renderAddress()}
      </div>
    );
  }

  /******************************************************************************
   *    !renderRightInfo
   *		Information annexe a gauche.
   *
   ******************************************************************************/
  function renderRightInfo() {
    if (props.rightText) {
      return (
        <div className={"RightInfo"}>
          <span className={"RightText"}>{props.rightText}</span>
        </div>
      );
    }
  }

  /******************************************************************************
   *    !onTabClick
   *   Change de page lors du clic sur un onglet
   *
   ******************************************************************************/
  function onTabClick(tab) {
    if (props.onChangeTab) props.onChangeTab(tab);
    props.history.push(tab);
  }

  /******************************************************************************
   *    !renderTab
   *   Affiche les onglets
   *
   ******************************************************************************/
  function renderTab(key, value, index) {
    return (
      <div
        className={`mainTab ${
          key ===
            `${props.history.location.pathname}${props.history.location.search}` &&
          "active"
        }`}
        onClick={() => onTabClick(key)}
        key={`mainTab ${index}`}
      >
        <div className={"subTab"}>{value}</div>
      </div>
    );
  }

  /******************************************************************************
   *    !renderButton
   *   Affiche les boutons
   ******************************************************************************/
  function renderButton(uButton, index) {
    return (
      <div key={`Button_${index}`} className={"buttonBody"}>
        <button
          className={"Button"}
          onClick={() => props.onButtonClick(uButton)}
          index={`Button${index}`}
        >
          {uButton}
        </button>
      </div>
    );
  }
  return (
    <div className={"subHeader"}>
      <div className={"subHeaderTop"}>
        {renderMainInfo()}
        <div className={"buttonArea"}>
          {renderRightInfo()}
          <div className={"buttonContainer"}>
            {props.customButton}
            {props.buttons &&
              props.buttons.map((uButton, index) => {
                return renderButton(uButton, index);
              })}
          </div>
        </div>
      </div>
      <CovidSection>
        <p>
          <b>Info covid-19:</b> En raison de la situation actuelle, Google nous
          informe prendre plus de temps pour mettre à jour vos données. Il est
          donc possible que les données modifiées sur Visiretail n'apparaissent
          pas immédiatement sur Google. Les établissements "d'urgence" restent
          prioritaires dans leurs demandes de modifications de données.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.visiperf.io/google-my-business-bloque-face-au-covid-19/"
          >
            Cliquez ici pour en savoir plus
          </a>
          .
        </p>
      </CovidSection>
      {props.hasTab && (
        <div className={"bottom"}>
          <div className={"tabContainer"}>
            {props.hasTab &&
              Object.entries(props.tabs).map(([key, value], index) =>
                renderTab(key, value, index)
              )}
          </div>
        </div>
      )}
    </div>
  );
}
export default SubHeader;
