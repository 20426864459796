import React from "react";
import { Link } from "react-router-dom";
import icon_pen from "../../../../ressources/images/PlacesEdits/icon_pen.svg";
import icon_cross from "../../../../ressources/images/PlacesEdits/icon_cross.svg";

const headerStyle = {
    height: 62,
    width: '100%',
    padding: '10px 100px',
    display: 'flex',
    alignItems: 'center',
    background: '#6D77DC'
};
const modifTextStyle = {
    fontFamily: 'Barlow',
    fontWeight: 600,
    fontSize: 18,
    color: '#FFFFFF'
};

export const MultiEditHeader = ({ totalPointOfSales }) => (
    <div style={headerStyle}>
        <img style={{marginRight: 20}} src={icon_pen} alt={'édition'} />
        <span style={modifTextStyle}>{`Modifier les informations pour ${totalPointOfSales} établissement(s)`}</span>
        <Link to="/point-of-sales" style={{marginLeft: 'auto'}}>
            <img src={icon_cross} alt={'fermer'} />
        </Link>
    </div>
);