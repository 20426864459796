import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { PublicationPeriodFilter, PublishersFilter, PointOfSalesFilter } from "../components";
import styled from 'styled-components'
import { setPublicationPeriodFilterAction, setPublisherFilterAction, setPointOfSaleFilterAction, resetFiltersAction } from "../actions";
import { fetchListPointOfSaleRequestAction, selectPointOfSalesSelector } from "../../point-of-sale"

const FilterHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ced6dd;
    padding-bottom: 6px;
`

const FilterTitle = styled.h2`
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
`

const FilterResetButton = styled.button`
    background: transparent;
    font-size: 1.2rem;
`

const FilterBody = styled.div`
    padding: 20px 0;
`

const FilterSection = styled.div`
    margin-bottom: 40px;
    position: relative;
`

const FilterSectionTitle = styled.h3`
    color: rgba(0, 0, 0, 0.5);
	font-weight: bold;
    font-size: 1.4rem;
    margin: 0 0 20px;
`

const PostFilters = ({ filters, pointOfSales, fetchListPointOfSales, setPublicationPeriodFilter, setPublisherFilter, setPointOfSaleFilter, resetFilters }) => {    
    useEffect(() => {
        return () => resetFilters()
    }, [])
    
    const handleLoadPointOfSales = (name, callback) => {
        fetchListPointOfSales({ name })
        return callback(pointOfSales)
    }

    return (
        <>
            <FilterHeader>
                <FilterTitle>Filtres</FilterTitle>
                {/*<FilterResetButton onClick={resetFilters}>Réinitialiser</FilterResetButton>*/}
            </FilterHeader>
            <FilterBody>
                <FilterSection>
                    <FilterSectionTitle>Période de publication</FilterSectionTitle>
                    <PublicationPeriodFilter value={filters.publicationPeriod} onChange={setPublicationPeriodFilter} />
                </FilterSection>
                <FilterSection>
                    <FilterSectionTitle>Plateformes</FilterSectionTitle>
                    <PublishersFilter values={filters.publishers} onChange={setPublisherFilter} />
                </FilterSection>
                <FilterSection>
                    <FilterSectionTitle>Établissements</FilterSectionTitle>
                    <PointOfSalesFilter values={filters.pointOfSales} onLoadOptions={handleLoadPointOfSales} onChange={setPointOfSaleFilter} />
                </FilterSection>
            </FilterBody>
        </>
    )
}

const mapStateToProps = state => ({
    pointOfSales: selectPointOfSalesSelector(state)
})

const mapDispatchToProps = dispatch => ({
    fetchListPointOfSales: filters => dispatch(fetchListPointOfSaleRequestAction(filters)),
    setPublicationPeriodFilter: publicationPeriod => dispatch(setPublicationPeriodFilterAction(publicationPeriod)),
    setPublisherFilter: (publisher, selected) => dispatch(setPublisherFilterAction(publisher, selected)),
    setPointOfSaleFilter: (pointOfSale, selected) => dispatch(setPointOfSaleFilterAction(pointOfSale, selected)),
    resetFilters: () => dispatch(resetFiltersAction())
})

export const ConnectedPostFilter = connect(mapStateToProps, mapDispatchToProps)(PostFilters)