import React from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../../../ressources/images/menu/presence_management.svg";
import openLink from "../../../../ressources/images/menu/icon_openLink.svg";
import { REFRESH_TOKEN } from "../../../auth/constants";

const SidebarT = () => {
  const redirectToVisiretail = async () => {
    window.open(
      `https://${
        process.env.REACT_APP_VISIRETAIL_DOMAIN
      }/sso/${localStorage.getItem(REFRESH_TOKEN)}`,
      "_blank"
    );
  };

  return (
    <div className="menu">
      <div className="menuContainerLogo">
        <Link to="/point-of-sales">
          <img className="menuLogo" src={logo} alt="cross" />
        </Link>
      </div>
      <div
        style={{ textDecoration: "none" }}
        className="menuItemContainer menuVisiretailBackLink"
        onClick={redirectToVisiretail}
      >
        <img
          src={openLink}
          className="menuItemIcon"
          alt="Publicité locale"
          width={25}
          height={25}
        />
        <span className="menuVisiretailText" style={{ whiteSpace: "pre" }}>
          Publicité locale
        </span>
        <div className="menuVisiretailBackLinkNew">NOUVEAU</div>
      </div>
    </div>
  );
};

export const Sidebar = withRouter(SidebarT);
