export const required = value => (!Array.isArray(value) && value) || value?.length > 0 ? undefined : "Champ obligatoire";

export const emailValid = value => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Email invalide" : undefined;

export const min = minLength => value => !isNaN(value) && value?.length >= minLength ? undefined : `${minLength} chiffres obligatoire`

export const hoursValid = values =>
    !values?.length > 0 || values?.every(({ from, to }, index) => {
        let isValid = to === "00:00" || from < to
        if (!isValid || values.length === 1) return isValid

        return index > 0 ? values[index-1].to < from && values[index-1].to != "00:00" : true
    }) ? undefined : "Veuillez indiquer une heure valide"

export const datetimeValid = values =>
    !values || values?.every(({ date, timeSlots }) =>
        required(date) === undefined && hoursValid(timeSlots) === undefined
    ) ? undefined : "Veuillez indiquer une date et une heure valide"

export const maxLength = max => value => value?.length > max ? "Nombre maximum de caractères dépassé" : undefined

export const maxMedia = max => value => maxLength(max)(value) ? "Nombre maximum d'images et de vidéos dépassé" : undefined