const toTimestamp = date => new Date(date).getTime() / 1000;

const datetimePayloadToJson = datetime =>
  datetime?.map(({ date, timeSlots }) => ({
    date: toTimestamp(date),
    timeSlots
  }));

const datetimeJsonToState = datetime =>
  datetime?.map(({ date, timeSlots }) => ({
    date: new Date(date * 1000).toISOString().split("T")[0],
    timeSlots
  }));

export const formatWebsiteUrl = websiteUrl => `https://${websiteUrl}`;

export const fromStateToJson = ({
  businessDetails: { openingDate, ...businessDetails },
  publishersDescription: { gmbMainCategory, ...publishersDescription },
  hours: {
    exceptionalOpeningHours = [],
    exceptionalClosingTimes = [],
    ...hours
  } = {},
  ...rest
}) => ({
  businessDetails: {
    openingDate: toTimestamp(openingDate),
    ...businessDetails
  },
  publishersDescription: {
    gmbMainCategory: Array.isArray(gmbMainCategory)
      ? gmbMainCategory[0]
      : gmbMainCategory,
    ...publishersDescription
  },
  hours: {
    exceptionalOpeningHours: datetimePayloadToJson(exceptionalOpeningHours),
    exceptionalClosingTimes: datetimePayloadToJson(exceptionalClosingTimes),
    ...hours
  },
  ...rest
});

export const fromJsonToState = ({
  siret,
  name,
  address,
  addressAdditionalInfos,
  zipCode,
  city,
  country,
  openingDate,
  websiteUrl,
  email,
  phoneNumbers,
  businessHours,
  exceptionalOpeningHours,
  exceptionalClosingTimes,
  isTemporarilyClosed,
  gmbInformations: {
    gmbMainCategory,
    gmbAdditionalCategories,
    gmbDescription,
    gmbLogo,
    gmbCoverPicture
  },
  facebookInformations: { fbCategories, fbDescription }
}) => ({
  businessDetails: {
    siret,
    businessName: name,
    address,
    addressAdditionalInfos,
    zipCode,
    city,
    country,
    openingDate: openingDate.split("T")[0]
  },
  publishersDescription: {
    gmbMainCategory: gmbMainCategory.category_id,
    gmbAdditionalCategories: gmbAdditionalCategories?.map(
      ({ category_id }) => category_id
    ),
    fbCategories: fbCategories?.map(
      ({ facebook_category_id }) => facebook_category_id
    ),
    websiteUrl: websiteUrl.replace(/^https?:\/\//, ""),
    fbDescription,
    gmbDescription
  },
  contact: {
    email,
    phoneNumbers
  },
  medias: {
    logoURL: gmbLogo,
    coverPictureURL: gmbCoverPicture
  },
  hours: {
    businessHours,
    exceptionalOpeningHours: datetimeJsonToState(exceptionalOpeningHours),
    exceptionalClosingTimes: datetimeJsonToState(exceptionalClosingTimes),
    isTemporarilyClosed
  }
});

export const fromMultiEditToJson = (
  oldValue,
  { selected, publishersDescription, contact, hours }
) => {
  return {
    id: oldValue.id,
    businessDetails: {
      siret: oldValue.siret,
      openingDate: toTimestamp(oldValue.openingDate)
    },
    publishersDescription: {
      gmbMainCategory: selected.publishersDescription?.hasGmbMainCategory
        ? publishersDescription.gmbMainCategory[0]
        : oldValue.gmbInformations.gmbMainCategory.category_id,
      gmbAdditionalCategories: selected.publishersDescription
        ?.hasGmbAdditionalCategories
        ? publishersDescription.gmbAdditionalCategories
        : oldValue.gmbInformations.gmbAdditionalCategories?.map(
            ({ category_id }) => category_id
          ),
      fbCategories: selected.publishersDescription?.hasFbCategories
        ? publishersDescription.fbCategories
        : oldValue.facebookInformations.fbCategories?.map(
            ({ facebook_category_id }) => facebook_category_id
          ),
      gmbDescription: selected.publishersDescription?.hasGmbDescription
        ? publishersDescription.gmbDescription
        : oldValue.gmbInformations.gmbDescription,
      fbDescription: selected.publishersDescription?.hasFbDescription
        ? publishersDescription.fbDescription
        : oldValue.facebookInformations.fbDescription
    },
    contact: {
      email: selected.contact?.hasEmail ? contact.email : oldValue.email
    },
    medias: {
      logoURL: oldValue.gmbInformations.gmbLogo,
      coverPictureURL: oldValue.gmbInformations.gmbCoverPicture
    },
    hours: {
      exceptionalOpeningHours: selected.hours?.hasExceptionalOpeningHours
        ? datetimePayloadToJson(hours?.exceptionalOpeningHours || [])
        : oldValue.exceptionalOpeningHours || [],
      exceptionalClosingTimes: selected.hours?.hasExceptionalClosingTimes
        ? datetimePayloadToJson(hours?.exceptionalClosingTimes || [])
        : oldValue.exceptionalClosingTimes || []
    }
  };
};
