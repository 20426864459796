import React, { useState, useEffect } from "react"
import LOGO from '../../../ressources/functions/logo'
import LANGUAGES from '../../../ressources/Languages'
import { FACEBOOK_PUBLISHER, GOOGLE_PUBLISHER, TWITTER_PUBLISHER } from '../../commons/constants'
import { PreviewDefault, PreviewFacebook, PreviewGoogle, PreviewTwitter } from './previews'
import { FACEBOOK_PREVIEW_MAX_MEDIA_SHOWING, TWITTER_MAX_MEDIA } from '../constants'
import take from 'lodash/fp/take'
import isEmpty from 'lodash/fp/isEmpty'

export const PostPreview = ({ publishers, pointOfSale, description, medias }) => {
    const [selectedPublisher, setSelectedPublisher] = useState("")

    useEffect(() => {
        setSelectedPublisher(publishers && publishers[0])
    }, [publishers])

    return (
        <>
            <div className="networkDataFieldName">{LANGUAGES.Publish.title.preview}</div>
            <div className="publishFirstStepPreviews">
                {publishers?.map(publisher => (
                    <div key={`preview.${publisher}`} className={`publishFirstStepTitleEndItem ${selectedPublisher === publisher && 'select'}`} onClick={() => setSelectedPublisher(publisher)}>
                        <img src={selectedPublisher === publisher ? LOGO.Default[publisher] : LOGO.Black[publisher]} alt={LANGUAGES[publisher]} />
                        {LANGUAGES[publisher]}
                    </div>
                ))}
            </div>
            {selectedPublisher === FACEBOOK_PUBLISHER && <PreviewFacebook
                description={description}
                businessName={pointOfSale?.label}
                logo={pointOfSale?.data.logo}
                medias={take(FACEBOOK_PREVIEW_MAX_MEDIA_SHOWING)(medias)}
                totalMedias={medias?.length}
            />}
            {selectedPublisher === GOOGLE_PUBLISHER && <PreviewGoogle
                description={description}
                businessName={pointOfSale?.label}
                logo={pointOfSale?.data.logo}
                medias={medias || []}
            />}
            {selectedPublisher === TWITTER_PUBLISHER && <PreviewTwitter
                description={description}
                businessName={pointOfSale?.label}
                logo={pointOfSale?.data.logo}
                medias={take(TWITTER_MAX_MEDIA)(medias) || []}
            />}
            {isEmpty(selectedPublisher) && <PreviewDefault />}
        </>
    )
}