import React from 'react'
import { PUBLISHER_OPTIONS } from '../../constants'
import styled from 'styled-components'

const PublishersFilterWrapper = styled.div`
   display: flex;
   flex-direction: column;
   .publisher__label {
       display: flex;
       align-items: center;
       padding: 4px 2px;
       cursor: pointer;
   }
`

export const PublishersFilter = ({ values, onChange }) => (
    <PublishersFilterWrapper>
        {PUBLISHER_OPTIONS.map(({ label, value }) => (
            <label key={value} className="publisher__label">
                <input type="checkbox" checked={values.includes(value)} onChange={e => onChange(value, e.target.checked)} style={{marginRight: 8}} />
                {label}
            </label>
        ))}
    </PublishersFilterWrapper>
)