import React from 'react'

export const TermsOfConditions = () => (
    <>
        <div>Dernière mise à jour : 29 mai 2020</div>
        <article>
            <section>
                <h2>Préambule</h2>
                <p><a href="https://presence.visiretail.io">https://presence.visiretail.io</a> est un site Internet détenu et exploité par La Société Visiperf située au 79 boulevard Richard Lenoir, 75011 Paris. La Société est immatriculée au R.C.S de Paris sous le numéro 803 825 025, sa forme juridique est une SAS au capital de cent quatre mille euros (104 000 €) et l'offre VISIPERF vise à générer des demandes (appels, formulaire, visite en magasin...) et développer le chiffre d'affaires de ses clients grâce à des technologies de marketing digital.</p>
                <p>Presence.visiretail.io propose une solution de gestion de la visibilité de points de vente en ligne et de leur image. À cet égard, les présentes Conditions Générales de Vente régissent les relations entre le site Internet <a href="https://presence.visiretail.io">https://presence.visiretail.io</a> et la société Visiperf, nommés « La Société » et ses utilisateurs, nommés individuellement « Client ».</p>
                <p>Toute personne qui bénéficie des Services de la Société sous quelque forme que ce soit, y compris à titre gratuit, adhère de fait aux conditions générales de ventes décrites dans ce document.</p>
                <p>Les prestations à fournir par la Société découlent de l’accord en lien avec la description des produits visibles à tout moment sur le site internet : <a href="https://presence.visiretail.io">https://presence.visiretail.io</a></p>
            </section>
            <section>
                <h2>ARTICLE 1 : DÉFINITIONS</h2>
                <p>Chaque terme débutant ci-après par une majuscule a le sens qui lui est donné dans sa définition.</p>
                <p>Plateforme : désigne la plateforme proposée par La Société et à partir de laquelle les Clients peuvent utiliser les Services Presence.visiretail.io. La plateforme est disponible sur le site <a href="https://presence.visiretail.io">https://presence.visiretail.io</a>. Le client y accède à l’aide de ses identifiants personnels transmis par la Société. La Plateforme comprend l’infrastructure ainsi que le contenu, notamment les textes, sons, images fixes ou animées, vidéos et bases de données.</p>
                <p>Services : désigne les services proposés par la Société à travers le Site Internet et/ou via la Plateforme afin de permettre au Client de bénéficier des solutions pour gérer son image de marque en ligne.</p>
                <p>Compte : désigne le compte Client créé par la Société, accessible à partir du Site Internet et permettant au Client de s’abonner et d’utiliser les Services.</p>
                <p>Identifiants : désigne l’adresse email et le code confidentiel ou mot de passe permettant au Client d’accéder à son Compte.</p>
                <p>Réseaux Sociaux : désigne le(s) réseau(x) social(aux) en ligne permettant à ses utilisateurs de publier des images, des photos, des vidéos, des fichiers et documents, d’échanger des messages, rejoindre et créer des groupes et d’utiliser une variété d’applications. Par Réseaux Sociaux, la Société désigne : Facebook, Instagram, Twitter, Google my Business, englobant l’ensemble de leurs particularités. La liste des Réseaux Sociaux n’étant pas exhaustive et pouvant être amenée à évoluer.</p>
                <p>Site Internet : désigne le site internet de la Société disponible à l’adresse suivante : <a href="https://presence.visiretail.io">https://presence.visiretail.io</a> et à partir duquel le Client peut s’abonner et utiliser les Services.</p>
                <p>Le Site Internet comprend notamment l’ensemble des composants informatiques (notamment les logiciels et développements informatiques et les pages Web, incluant les programmes en code source et objet, les travaux de conception préparatoire, les cahiers des charges, et études préalables, la documentation Client relative à l’utilisation et à l’exploitation du site internet), la charte graphique (y compris les projets, maquettes, prototypes et plans), l’infrastructure ainsi que le contenu (notamment les textes, sons, images fixes ou animées, vidéos et bases de données).</p>
                <p>Données : ensemble des informations sur le Client collectées, saisies et transmises par le Client à la Société.</p>
            </section>
            <section>
                <h2>ARTICLE 2 : OBJET</h2>
                <p>Les Conditions Générales de vente ont pour objet de définir les termes et conditions selon lesquelles le Client accède et utilise les Services proposés par la Société.</p>
                <p>Les Conditions Générales de Vente sont systématiquement portées à la connaissance du Client pour lui permettre d’accéder et d’utiliser les Services. Elles s’appliquent de façon exclusive à toutes utilisations des Services et prévalent sur toutes autres conditions, à l’exception de celles qui ont été acceptées expressément par la Société. Par conséquent, toutes autres conditions n’engagent la Société qu’après confirmation écrite de sa part.</p>
                <p>IMPORTANT : NOTE AUX CLIENTS : TOUTE UTILISATION DES SERVICES IMPLIQUE L’ACCEPTATION EXPRESSE, PRÉALABLE, PLEINE ET ENTIÈRE PAR LE CLIENT DES CONDITIONS GÉNÉRALES DE VENTE.</p>
            </section>
            <section>
                <h2>ARTICLE 3 : APPLICATION / MODIFICATIONS DES CONDITIONS GÉNÉRALES D'UTILISATION</h2>
                <p>La Société se réserve le droit de modifier, à tous moments, les Conditions Générales de Vente, afin notamment de prendre en compte toute évolution légale, jurisprudentielle, éditoriale, fonctionnelle et/ou technique du Site Internet, de la Plateforme et/ou des Services.</p>
                <p>La version qui prévaut est celle qui est accessible en ligne au jour de l’utilisation du Site Internet, de la Plateforme et de l’ensemble des Services de la Société.</p>
                <p>Toute utilisation du Site Internet, de la Plateforme et/ou des Services par le Client après publication des Conditions Générales de Vente modifiées vaut acceptation par cette dernière des nouvelles Conditions Générales de Vente.</p>
                <p>Les Conditions Générales de Vente sont mises à jour régulièrement. Par conséquent, le Client est invité à les consulter à chacune de ses visites sur le Site Internet et/ou la Plateforme.</p>
                <p>Dans l’hypothèse où le Client est en désaccord avec les Conditions Générales de Vente modifiées, il devra cesser d’accéder au Site Internet et/ou à la Plateforme et d’utiliser les Services. Les conséquences prévues à l’article 10 des Conditions Générales de vente seront alors applicables. L’utilisation du Site Internet, de la Plateforme et/ou des Services par les Clients après publication des nouvelles Conditions Générales de Vente vaudra acceptation sans réserve et expresse de ces dernières par les Clients.</p>
            </section>
            <section>
                <h2>ARTICLE 4 : DURÉE DU CONTRAT</h2>
                <p>Le présent contrat est conclu pour une durée de douze (12) mois et prend effet à compter de la date de signature sauf mention clairement indiquée en première page de ce document.</p>
                <p>Le contrat se renouvellera ensuite par tacite reconduction par périodes successives de (12) mois, sauf si une dénonciation de l’une des parties est adressée à l’autre par lettre recommandée avec demande d’avis de réception au moins un (1) mois avant l’échéance de la période contractuelle en cours ou de son renouvellement.</p>
            </section>
            <section>
                <h2>ARTICLE 5 : LE SERVICE</h2>
                <h3>5.1.Accès au Service</h3>
                <p>Afin d’accéder au Service, le Client doit se connecter sur le Site, cliquer sur Mon Compte et renseigner ses Identifiants.</p>
                <h3>5.2. Inscription du Client</h3>
                <p>Le Service est réservé aux professionnels et n’est pas offert aux consommateurs.</p>
                <p>L’inscription au Service est ouverte à toute personne physique, majeure et capable, ou toute personne morale, agissant dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, y compris lorsqu’il agit au nom et pour le compte d’un autre professionnel.</p>
                <p>L'utilisation du Service est conditionnée à une inscription préalable et gratuite, entraînant l'acceptation entière des présentes CGV.</p>
                <p>Afin d’utiliser le Service, le Client doit fournir certaines données à la Société. A ce titre, le Client s’engage à fournir des informations exactes, complètes, sincères et précises.</p>
                <p>Le Client devra renseigner notamment les informations suivantes :</p>
                <ul>
                    <li>Dénomination sociale ou Raison Sociale ;</li>
                    <li>Nom et prénom du représentant ;</li>
                    <li>Email ;</li>
                    <li>Mot de passe ;</li>
                    <li>Nombre d’établissements concernés par la solution ;</li>
                    <li>L’accès à l’ensemble des données publiques présentes sur les Réseaux Sociaux ;</li>
                    <li>L’accès à certains identifiants et comptes des Réseaux Sociaux nécessaire à la bonne exécution des Services de la Société.</li>
                </ul>
                <p>En cas de changement de la situation telle que déclarée au jour de la création du compte, le Client s'engage à mettre à jour les informations le concernant, sur le Site. La Société ne saurait être tenue responsable au cas où elle n'aurait pas été avisée d'un changement de situation du Client ou d’informations erronées le concernant.</p>
                <p>ne même personne physique ou morale ne peut ouvrir plusieurs comptes sur le Site. L’utilisation d’un compte est strictement personnelle.</p>
                <p>Par ailleurs, la Société se réserve le droit d'engager des poursuites et de demander des dommages et intérêts à l'encontre de toute personne ayant essayé de tricher, tromper, ou utiliser frauduleusement le Service fourni par la Société, ou générer des avantages ou bénéfices de façon frauduleuse ou déloyale, ou plus généralement d’avoir saisi des informations dans le but d’usurper une identité.</p>
                <p>Un Client qui ouvre un compte sur le Site et, qui par ce fait, accepte les présentes CGV, est titulaire de ce compte et y a accès par l'intermédiaire des Identifiants aléatoires automatiquement attribués par La Société. Ce mot de passe pourra être modifié dans l'interface Paramètres du compte par le Client.</p>
                <p>La combinaison identifiant/mot de passe est strictement personnelle. Il appartient donc au Client de garantir le maintien de la confidentialité de cette combinaison lui permettant l'accès au Service.</p>
                <p>En cas de perte et/ou d'oubli du mot de passe, le titulaire du compte pourra demander la réinitialisation de son mot de passe à partir du Site.</p>
                <p>Le Client s’engage également à ne communiquer ces informations d’identification et de mot de passe à aucune autre personne, ni à les prêter, donner, vendre et, de manière générale, mettre le compte et/ou le mot de passe à la disposition d'un tiers, pour quelque raison que ce soit. A défaut la responsabilité de la Société ne pourra être aucunement engagée en cas d'utilisation frauduleuse de ces informations.</p>
                <p>En cas d'utilisation frauduleuse des identifiants de connexion du Client du fait d'une faute ou négligence imputable au Client, ou à l'un des salariés sous son contrôle ou son autorité hiérarchique, le Client sera responsable envers la Société de toute perte ou détérioration de données quelles qu'elles soient, et plus généralement de tout dommage subi en raison d'une utilisation du service non conforme aux règles édictées dans les présentes conditions générales.</p>
                <h3>5.3. Objet du Service</h3>
                <p>Dans le cadre de ses Services, la Société met à disposition du Client, une Plateforme permettant de gérer son image de marque en ligne grâce à un outil de centralisation de gestion de certains aspects de ses comptes et/ou fiches sur des réseaux sociaux, des annuaires en ligne, d'interaction et d’analyse.</p>
                <p>La Société évolue, de la même manière que son produit. Cela inclut le développement de nouvelles fonctionnalités se basant sur les demandes sur marché, du Client et en péréquation avec les ressources de la Société et l’état de l’art du domaine concerné.</p>
                <p>La responsabilité de la Société se limite à mettre l’ensemble des moyens dont elle dispose pour mettre ces fonctionnalités à disposition du Client. Toute obligation de résultat est à exclure en l’espèce dès lors que la Société fait preuve d’une diligence manifeste pour répondre à ses obligations.</p>
            </section>
            <section>
                <h2>ARTICLE 6 : PRIX</h2>
                <h3>6.1.Fixation du prix</h3>
                <p>Au jour de la formation du contrat et en l’absence de révision des présentes conditions et du présent contrat par la Société, le prix correspond au montant indiqué dans le devis transmis au Client et signé par celui-ci.</p>
                <h3>6.2.Barème de prix unitaire</h3>
                <p>La Société évalue le coût de ses Services en fonction des indicateurs suivants :</p>
                <ul>
                    <li>Nombre d’établissements ;</li>
                    <li>Élaboration des fonctionnalités ;</li>
                    <li>Développement de fonctionnalités personnalisées ;</li>
                    <li>Coûts d’installation et de maintenance éventuels ;</li>
                    <li>La Société calcule le coût des Services relativement au nombre d’établissements de l’entreprise concernée par le Service ;</li>
                    <li>Le prix est calculé d’après :</li>
                    <li>Une base variable selon les fonctionnalités mises en service ;</li>
                    <li>La multiplication de cette base variable par le nombre d’établissements concernés par les Services ;</li>
                    <li>S’ajoute des coûts d’installation et de maintenance éventuels.</li>
                </ul>
                <p>Dans le cas où le Client souhaiterait ajouter des établissements à la Plateforme, cet ajout peut entraîner un coût additionnel détaillé dans le devis envoyé par la Société et signé par le Client.</p>
                <h3>6.3.Révision du prix</h3>
                <p>Au gré de la Société et après la période de douze mois (12), le prix, fixé à l’article 5.1, peut être révisé unilatéralement par la Société à tout moment.</p>
                <p>La révision du prix doit être notifiée au Client par courriel et devra faire l’objet de son approbation par courrier ou retour de courriel, et prendra effet à la première échéance de paiement du prix suivant la notification.</p>
                <h3>6.4 Modalités de paiement</h3>
                <p>Le paiement du Prix est portable ; la Société doit adresser au Client le paiement du Prix de l’ensemble de la période contractuelle selon les moyens de paiement mis à sa disposition.</p>
            </section>
            <section>
                <h2>ARTICLE 7 : RÉDUCTION DE PRIX ET CONDITIONS DE RÈGLEMENT</h2>
                <h3>7.1. Réduction de prix</h3>
                <p>La Société se réserve le droit d’effectuer une réduction de prix dans le cadre de négociation avec le Client, s’inscrivant dans une démarche commerciale et/ou de fidélisation.</p>
                <p>Cette réduction peut concerner la base variable, le coût additionnel engendré par l’ajout d’établissement à la Plateforme ou les coûts d’installation.</p>
                <h3>7.2.Conditions de règlement</h3>
                <p>En vertu des présentes CGV, le Client s’engage sur une durée de douze (12) mois. Le Client s'affranchit de son abonnement par paiement mensuel. Le paiement s’effectue par prélèvement automatique à la date définie entre le Client et la Société.</p>
                <h3>7.3.Application du taux d’intérêt des pénalités de retard</h3>
                <p>Le taux d’intérêt prévu par les présentes CGV correspond au taux directeur (taux de Refi) semestriel de la Banque Centrale européenne (BCE), en vigueur au 1er janvier ou au 1er juillet, majoré de 10 points : soit 10,00 % (0,00 + 10) pour les pénalités dues à partir du 1er juillet 2019.</p>
                <h3>7.4 Calcule du taux d’intérêt des pénalités de retard</h3>
                <p>Pour calculer le taux d’intérêt des pénalités de retard, il faut le multiplier par le nombre de jours écoulés entre la date d’échéance soit la date de souscription aux Services et la date d’encaissement (ou la date à laquelle est fait le calcul, si le paiement n’est pas encore effectué).</p>
                <h3>7.5.Application du taux d’intérêt des pénalités de retard</h3>
                <p>Le taux d’intérêt est appliqué sur le montant de la facture.</p>
                <p>Les pénalités sont exigibles sans qu’un rappel soit nécessaire. L’envoi d’une lettre recommandée par la Société n’est pas requis pour déclencher le droit de percevoir des pénalités de retard.</p>
                <p>Les pénalités de retard courent dès le jour suivant la date de règlement, correspondant à la souscription aux Services par le Client ou, à défaut, le 31eme jour suivant le début d’exécution de la prestation de service par la Société.</p>
                <p>Les pénalités de retard ne sont pas soumises à TVA.</p>
            </section>
            <section>
                <h2>ARTICLE 8 : OBLIGATIONS DE LA SOCIÉTÉ</h2>
                <p>La Société s’engage à fournir, développer et améliorer les Services accessibles sur la Plateforme.</p>
                <p>La Société s’engage à mettre l’ensemble des moyens dont elle recourt pour fournir une Plateforme répondant aux besoins et problématiques exprimés par le Client.</p>
                <p>La Société fera également le maximum afin d’accompagner le Client dans le cadre de l’utilisation du produit fourni par la Société.</p>
                <p>Pour la bonne exécution des Services, le Client devra mettre à disposition du Client ses Données via la Plateforme (ou flux dédié) de la Société respectant les standards définis par la Société à l’article 4 des présentes CGV.</p>
            </section>
            <section>
                <h2>ARTICLE 9 : RESPONSABILITÉ DE LA SOCIÉTÉ</h2>
                <p>La Société est responsable des obligations de prestation principale à fournir convenues contractuellement selon les réglementations légales. Dans le cas d’un traitement erroné (intégration, liaison, etc.) des Réseaux Sociaux et des données par la Société, cette dernière n’est pas responsable si l’erreur est corrigée par la Société dans un délai acceptable pour les deux parties.</p>
                <p>La Société n’est pas responsable des dommages dus à une administration ou utilisation incorrecte ou défectueuse des API mis à disposition par le client.</p>
                <p>La Société s’engage à respecter, dans le cadre de sa fourniture des Services, toutes les réglementations légales applicables, y compris sans limitation celles concernant le droit sur les protections de données, le droit sur la concurrence, la propriété industrielle (entre autres droit d'auteur et droit de marque). Le Client est informé que selon l'état actuel de la technique, il n’est pas possible d’élaborer un logiciel de sorte qu’il fonctionne sans erreur dans toutes les applications et les combinaisons.</p>
                <p>Dans le cas d’une perte de puissance technique, la Société s’engage à utiliser l’ensemble des moyens disponible pour corriger le défaut. Une responsabilité de la Société est ici exclue, à moins que le dysfonctionnement technique soit déraisonnablement long et que la Société n'ait pas agi en conséquence à la correction du dysfonctionnement.</p>
                <p>Une responsabilité pour des dommages consécutifs, des dommages atypiques ou indirects, pour la perte d’un gain ou d’un chiffre d'affaires, la perte d'usage, la perte de données, la perte commerciale, la perte de prestige ou la perte d’opportunités commerciales est exclue. En conséquence, la Société est exclusivement responsable des dommages reposant sur une négligence ou une intention grave de la part de la Société et de l’un de ses auxiliaires d’exécution. Dans la mesure où la responsabilité de la Société est exclue ou limitée, cela s'applique également à la responsabilité personnelle des employés, des ouvriers, des collaborateurs, des représentants et des auxiliaires d’exécution de la Société.</p>
            </section>
            <section>
                <h2>ARTICLE 10 : OBLIGATIONS DU CLIENT</h2>
                <h3>10.1. Autorisation administrative</h3>
                <p>Le Client s’engage à respecter dans le cadre de l’exécution du présent contrat les règles légales, réglementaires et déontologiques pouvant régir l’exercice de sa profession.</p>
                <h3>10.2. Paiement du prix</h3>
                <p>Le Client s’engage à payer le prix au montant et dans les conditions fixées à l’ARTICLE 6 du présent contrat.</p>
                <h3>10.3. Fourniture des données</h3>
                <p>Le Client s’engage à mettre à disposition de la Société les éléments et Données indispensables à l’utilisation de la Plateforme développée par la Société. Le Client met à disposition de la Société tous les documents, matériaux et toutes les informations nécessaires à la réalisation des Services convenues. À cet égard, le Client doit veiller à ce que les documents, informations et matériaux transmis soient pertinents et sans erreur. La responsabilité de la Société due au traitement d’éventuelles informations, documents ou matériaux erronés est exclue. Les données concernées sont celles mentionnées à l’article 4.2 des présentes CGV.</p>
                <p>Dans le cas où le client refuserait de fournir l’une des Données indispensables au bon fonctionnement du Service, la Société ne peut en garantir le bon fonctionnement.</p>
                <p>Le Client s’engage à fournir ses meilleurs efforts afin d’aider la Société si celui-ci a besoin d’informations complémentaires pour se tenir aux obligations inscrites à l’Article 7 des présentes CGV.</p>
                <h3>10.4. Licéité des Données</h3>
                <p>Le Client s’engage à ne mettre à disposition de la Société qu’un contenu licite, exempt de toute atteinte à l’ordre public, aux bonnes mœurs ou aux droits des tiers.</p>
            </section>
            <section>
                <h2>ARTICLE 11 : RÉFÉRENCES</h2>
                <p>La Société est autorisée à faire mention de sa collaboration avec le Client sur tout support de communication, lors d’interview ou sur la liste de ses références commerciales.</p>
            </section>
            <section>
                <h2>ARTICLE 12 : SUSPENSION DES OBLIGATIONS</h2>
                <p>En cas de survenance d’un cas de force majeure, les obligations des parties seront suspendues. De convention expresse, constitue un cas de force majeure, les perturbations du réseau de télécommunication ainsi que toute indisponibilité imputable à des causes étrangères à la Société ou hors de son contrôle.</p>
                <p>Si le cas de force majeure persiste au-delà d’une durée de soixante (60) jours, le présent contrat pourra être résilié de plein droit par l’une ou l’autre des parties.</p>
            </section>
            <section>
                <h2>ARTICLE 13 : RÉSILIATION POUR MANQUEMENT D’UNE PARTIE À SES OBLIGATIONS</h2>
                <p>En cas de non-respect par l’une ou l’autre des parties de ses obligations au titre du présent contrat, celui-ci pourra être résilié au gré de la partie lésée. Il est expressément entendu que cette résiliation aura lieu de plein droit un (1) mois après l’envoi d’une mise en demeure de s’exécuter restée, en tout ou partie, sans effet. La mise en demeure devra être notifiée par lettre recommandée avec demande d’avis de réception. Par exception, la Société pourra décider de résilier de plein droit et sans délai le Contrat en cas de manquement à l’Article 9. La Société informera alors le Client par lettre recommandée avec demande d’avis de réception.</p>
            </section>
            <section>
                <h2>ARTICLE 14 : PROPRIÉTÉ DES DONNÉES CLIENTS</h2>
                <p>Le Client reste le propriétaire exclusif des données contractuelles (les « Données ») confiées. A l’exception des Données Clients et des Données Personnelles, le Service et l’ensemble de ses contenus sont la propriété exclusive de la Société. La Société ne consent à chaque Client qu’un droit non-exclusif, incessible et temporaire d’utiliser le Service.</p>
                <p>La Société s’interdit d’utiliser, reproduire, adapter, modifier, publier ou distribuer les Données Clients, et ce pour quelque but que ce soit, sauf autorisation expresse du Client.</p>
                <p>En utilisant le Service, le Client confie à la Société des informations sous forme de fichiers ou textes. Le Client conserve l’entière propriété de ces informations. La Société ne revendique aucun droit de propriété les concernant. Les présentes CGV n’accordent aucun droit sur ces informations ou la propriété intellectuelle, à l’exception des droits limités requis pour l’exécution du Service. Des informations confidentielles sont transmises par le Client à la Société pour les besoins du Service, dans le respect des règles de confidentialité applicables. La Société n’est pas responsable dans le cas où le Client transmettra des informations erronées de manière volontaire ou involontaire.</p>
            </section>
            <section>
                <h2>ARTICLE 15 : LICENCE ET DROIT D’AUTEUR</h2>
                <p>Tout le contenu du Site et programmes informatiques, logiciels, produits, éléments graphiques d’interface ou des autres éléments associés aux Services fournis par la Société sont protégés par des droits de propriété intellectuelle appartenant exclusivement à la Société. Ce contenu ne peut être reproduit, traduit, transcrit, ou modifié sous quelque forme ni par quelque moyen que ce soit, sans l'accord écrit préalable de la Société. Le Client n’est pas autorisé à copier, modifier, distribuer, publier, transmettre ou créer des travaux dérivés de tout élément de ce contenu.</p>
                <p>L’accès au Service est concédé en licence gratuite. Les Licences accordées par les CGV ne donnent aucun droit sur le contenu du Site, programmes informatiques, logiciels, et produits associés aux Services fournis par la Société, ni sur les logos associés et d’autres noms, logos, icônes et marques identifiant les produits et Services de la Société qui ne doivent pas être utilisés sans la permission écrite préalable de la Société.</p>
            </section>
            <section>
                <h2>ARTICLE 16 - CONFIDENTIALITÉ</h2>
                <p>Chacune des parties s’engage à tenir strictement confidentielles toutes les informations concernant l’autre partie auxquelles elle pourrait avoir accès dans le cadre du présent accord, quel que soit le mode de communication desdites informations. Chaque partie s’engage notamment à respecter le caractère confidentiel des méthodes, procédés et du savoir-faire de l’autre partie dont elle pourrait avoir connaissance dans le cadre de l'exécution des présentes conditions générales de vente.</p>
                <p>Sont considérées comme confidentielles par nature toute information commerciale, financière ou technique qui ne serait pas dans le domaine public.</p>
                <p>La partie émettrice des informations confidentielles est considérée comme étant seule détentrice de tous droits sur ces informations confidentielles. Les parties s’engagent à n’utiliser les informations, données et documents visés aux présentes que pour l’exécution de leurs engagements respectifs au titre des présentes conditions générales de vente ou sur réquisition valable des autorités compétentes.</p>
                <p>En cas de violation de la présente obligation par l’une des parties, l’autre partie pourra résilier dans les conditions stipulées à l’article 12 des présentes CGV.</p>
                <p>Par ailleurs, compte tenu du caractère personnel des renseignements ou des informations qu'elles peuvent se communiquer dans le cadre de l'exécution des présentes conditions générales de vente, les Parties s'engagent à veiller à ce que lesdits renseignements ou lesdites informations soient communiqués dans le strict respect du cadre légal et réglementaire spécifique, constitué pour la France essentiellement par le RGPD et la loi dite « Informatique et Libertés » du 6 janvier 1978 telle que dernièrement modifiée par la loi relative à la protection des données personnelles du 20 juin 2018.</p>
                <p>L’utilisateur dispose d’un droit d’opposition, d’un droit à la limitation, d’un droit d’accès, d’un droit de rectification, d’un droit à la portabilité et d’un droit à l’effacement de ses données.</p>
                <p>L’utilisateur dispose également de la faculté de donner des directives à l’Éditeur sur le sort de ses données après sa cessation d’activité ou de contrat avec VISIPERF.</p>
            </section>
            <section>
                <h2>ARTICLE 17 – ATTRIBUTION DE JURIDICTION</h2>
                <p>Les parties entendent donner attribution de compétence exclusive au Tribunal de Commerce de Paris pour connaître de tout différend relatif à la validité, l’interprétation, l’exécution ou la rupture du présent contrat.</p>
            </section>
        </article>
    </>
)