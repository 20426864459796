import React, { useState } from "react"
import { connect } from "react-redux";
import logo from "../../../ressources/images/logo/visiretail.svg";
import { authenticationRequestAction } from "../actions";
import { Slide } from "../components";

export const Login = ({ authentication }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = e => {
        authentication({ username, password });
        e.preventDefault();
    };

    return (
        <div className='visiretail_registration section'>
            <div className='login'>
                <div className='col-sm-5 col-xs-12 registration_main'>
                    <div className='registration_head'>
                        <span>Vous souhaitez tester notre plateforme ?</span>
                        <a target='_blank' href="https://www.visiperf.io/contactez-nous/" className='btn-primary login'>Demander une démo</a>
                    </div>
                    <div className='registration_con'>
                        <span className='login_visiretailLogo'>
                            <img src={logo} className='login_visiretailLogo' alt='visiretail' />
                        </span>
                        <h1 className={'login_visiretailTitle'}>Mon espace marketing</h1>

                        <div className='form_main'>
                            <form onSubmit={handleSubmit}>
                                <div className='form_raw'>
                                    <label htmlFor="username">{'E-mail'}</label>
                                    <input
                                        id='username'
                                        type='text'
                                        autoComplete='none'
                                        className='form-control'
                                        onChange={e => setUsername(e.target.value)} />
                                </div>
                                <div className='form_raw'>
                                    <label htmlFor="password">{'Mot de passe'}</label>
                                    <input
                                        id='password'
                                        type='password'
                                        autoComplete='none'
                                        className='form-control'
                                        onChange={e => setPassword(e.target.value)} />
                                </div>
                                <div>
                                    <button className='btn-primary' style={{marginBottom: 4}}>connexion</button>
                                    <a href={`https://${process.env.REACT_APP_VISIRETAIL_DOMAIN}/reset_password`} target="_blank" className='fogot'>Mot de passe oublié ?</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='col-sm-7 col-xs-12 registr_slider'>
                    <Slide />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch, { history }) => ({
    authentication: credentials => dispatch(authenticationRequestAction(credentials, history))
});

export const ConnectLogin = connect(
    null, mapDispatchToProps
)(Login);