import React from "react"
import { Field } from "redux-form";
import { RenderCropFile } from "../../../commons";
import { required } from "../../../../helpers";

const LOGO_RULES = {
    minResolution: {width: 180, height: 180},
    maxWeight: 5
}

const COVER_PICTURE_RULES = {
    minResolution: {width: 480, height: 270},
    maxResolution: {width: 2120, height: 1192},
    maxWeight: 5
}

export const PointOfSalePictures = ({ fields, logo, coverPicture }) => (
    <>
        {(!fields || fields.hasLogo) &&
            <Field
                name="upload.logo"
                validate={!logo && required}
                component={RenderCropFile}
                accept={[".png", ".jpg"]}
                cropAspect={1/1}
                rules={{ image: LOGO_RULES }}
                defaultValue={logo}
                title={`Votre logo (${LOGO_RULES.maxWeight} Mo max.)`}
                description={`Le logo doit être au format JPG ou PNG avec une résolution minimale de ${LOGO_RULES.minResolution.width}x${LOGO_RULES.minResolution.height}px. Il sera affiché sur toutes les plateformes de Presence Management.`}
                isMandatory
            />
        }
        {(!fields || fields.hasCoverPicture) &&
            <Field
                name="upload.coverPicture"
                component={RenderCropFile}
                accept={[".png", ".jpg"]}
                cropAspect={16/9}
                rules={{ image: COVER_PICTURE_RULES }}
                defaultValue={coverPicture}
                title={`Photo de couverture Google My Business et Facebook (${COVER_PICTURE_RULES.maxWeight} Mo max.)`}
                description={`La photo de couverture de Google My Business doit être au format JPG ou PNG avec une résolution minimale de ${COVER_PICTURE_RULES.minResolution.width}x${COVER_PICTURE_RULES.minResolution.height}px.`}
            />
        }
    </>
);