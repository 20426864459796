import { call, select, takeEvery, put, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import { formatWebsiteUrl, fromMultiEditToJson } from "../helpers";
import {
  selectPointOfSaleByIds,
  selectTemporarilyClosedPointOfSaleIds
} from "../reducers";
import {
  BULK_UPDATE_SENSITIVE_FIELDS_REQUEST,
  UPDATE_POINT_OF_SALES,
  SPECIFIC_FIELD_SELECTED
} from "../constants";
import {
  bulkUpdatePointOfSaleWebsiteUrl,
  bulkUpdatePointOfSalePhoneNumbers,
  bulkUpdatePointOfSaleBusinessHours,
  bulkUpdatePointOfSaleIsTemporarilyClosed,
  bulkUpdatePointOfSales
} from "../api";
import {
  bulkUpdateSensitiveFieldsRequestAction,
  uploadMediasPointOfSalesRequest,
  updatePointOfSalesSuccessAction,
  updatePointOfSalesFailure,
  updateEndAction,
  bulkUpdateSensitiveFieldsFailureAction
} from "../actions";
import { difference, omit } from "lodash/fp";

const getFailedPointOfSaleIds = data =>
  data.filter(({ error }) => error).map(({ id }) => id);

function* bulkUpdateIsTemporarilyClosed(ids, value) {
  try {
    const { data } = yield call(bulkUpdatePointOfSaleIsTemporarilyClosed, {
      ids,
      isTemporarilyClosed: value
    });
    yield put(
      bulkUpdateSensitiveFieldsFailureAction(
        getFailedPointOfSaleIds(data),
        "Fermeture temporaire"
      )
    );
  } catch (e) {
    toast.error(
      "Une erreur est survenue lors de la modification de la fermeture temporaire, veuillez réessayer plus tard"
    );
  }
}

function* bulkUpdateSensitiveFieldsWorker({ ids, values }) {
  try {
    const { selected, publishersDescription, contact, hours } = values;

    if (selected.publishersDescription.hasWebsiteUrl) {
      try {
        const { data } = yield call(bulkUpdatePointOfSaleWebsiteUrl, {
          ids,
          websiteUrl: formatWebsiteUrl(publishersDescription.websiteUrl)
        });
        yield put(
          bulkUpdateSensitiveFieldsFailureAction(
            getFailedPointOfSaleIds(data),
            "Adresse du site internet"
          )
        );
      } catch (e) {
        toast.error(
          "Une erreur est survenue lors de la modification du site web, veuillez réessayer plus tard"
        );
      }
    }
    if (selected.contact.hasPhone) {
      try {
        const { data } = yield call(bulkUpdatePointOfSalePhoneNumbers, {
          ids,
          phoneNumbers: contact.phoneNumbers
        });
        yield put(
          bulkUpdateSensitiveFieldsFailureAction(
            getFailedPointOfSaleIds(data),
            "Téléphone(s)"
          )
        );
      } catch (e) {
        toast.error(
          "Une erreur est survenue lors de la modification du numéro de téléphone, veuillez réessayer plus tard"
        );
      }
    }
    if (
      Object.values(
        omit([
          "hasIsTemporarilyClosed",
          "hasExceptionalOpeningHours",
          "hasExceptionalClosingTimes"
        ])(selected.hours)
      ).some(Boolean)
    ) {
      const pointOfSales = yield select(selectPointOfSaleByIds(ids));
      const businessHours = pointOfSales.map(({ businessProfile }) => ({
        id: businessProfile.id,
        businessHours: {
          ...businessProfile.businessHours,
          ...hours.businessHours
        }
      }));
      try {
        const { data } = yield call(
          bulkUpdatePointOfSaleBusinessHours,
          businessHours
        );
        yield put(
          bulkUpdateSensitiveFieldsFailureAction(
            getFailedPointOfSaleIds(data),
            "Horaires d’ouverture habituels"
          )
        );
      } catch (e) {
        toast.error(
          "Une erreur est survenue lors de la modification des horaires d'ouverture, veuillez réessayer plus tard"
        );
      }
    }
    if (selected.hours.hasIsTemporarilyClosed) {
      yield call(bulkUpdateIsTemporarilyClosed, ids, hours.isTemporarilyClosed);
    }
  } catch (e) {
    console.error(e);
  }
}

function* watchBulkUpdateSensitiveFields() {
  yield takeEvery(
    BULK_UPDATE_SENSITIVE_FIELDS_REQUEST,
    bulkUpdateSensitiveFieldsWorker
  );
}

function* bulkUpdatePointOfSaleWorker({ pointOfSales, values, history }) {
  try {
    const ids = pointOfSales.map(({ businessProfile }) => businessProfile.id);
    const closedIds = yield select(selectTemporarilyClosedPointOfSaleIds(ids));

    let payload = [];
    let updateIds = [];
    let bulkUpdate = false;

    const notSpecificFields = omit(SPECIFIC_FIELD_SELECTED)(values.selected);
    for (const field in notSpecificFields) {
      if (Object.values(notSpecificFields[field]).some(Boolean)) {
        bulkUpdate = true;
        break;
      }
    }

    if (
      values.selected.hours.hasIsTemporarilyClosed &&
      !values.hours.isTemporarilyClosed &&
      closedIds.length > 0
    ) {
      yield call(
        bulkUpdateIsTemporarilyClosed,
        closedIds,
        values.hours.isTemporarilyClosed
      );
      updateIds = ids;
      if (bulkUpdate) {
        payload = pointOfSales.filter(({ businessProfile }) =>
          fromMultiEditToJson(businessProfile, values)
        );
      }
    } else {
      updateIds = difference(ids, closedIds);
      if (bulkUpdate) {
        payload = pointOfSales
          .filter(({ businessProfile }) =>
            updateIds.includes(businessProfile.id)
          )
          .map(({ businessProfile }) =>
            fromMultiEditToJson(businessProfile, values)
          );
      }
    }

    yield put(bulkUpdateSensitiveFieldsRequestAction(updateIds, values));

    if (payload.length > 0) {
      const { data } = yield call(bulkUpdatePointOfSales, payload);
      yield put(updatePointOfSalesSuccessAction(data));
    }

    if (Object.values(values.selected.media).some(Boolean)) {
      yield put(uploadMediasPointOfSalesRequest(updateIds, values.upload));
    }

    yield put(updateEndAction());
  } catch (e) {
    yield put(updatePointOfSalesFailure());
    toast.error("Une erreur est survenue, veuillez réessayer plus tard");
    console.error(e);
  }
}

function* watchBulkUpdatePointOfSaleWorker() {
  yield takeEvery(UPDATE_POINT_OF_SALES, bulkUpdatePointOfSaleWorker);
}

export const PointOfSaleBulkUpdateSagas = [
  fork(watchBulkUpdateSensitiveFields),
  fork(watchBulkUpdatePointOfSaleWorker)
];
