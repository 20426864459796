import React from "react";
import emptyCover from "../../../ressources/images/emptyCover.svg";
import facebookLogo from "../../../ressources/images/logo/facebookRound.svg";
import googleLogo from "../../../ressources/images/logo/googleRound.svg";
import styled from "styled-components";

const PointOfSaleCard = styled.div`
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 7px 0 hsla(0, 0%, 43.9%, 0.25);
`;

const PointOfSaleCardHeader = styled.div`
  background: url(${props => props.coverPicture}) no-repeat center center /
    cover;
  height: 120px;
  position: relative;
  border-bottom: 1px solid #000;
  div.selected > &::after {
    content: "Sélectionné";
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    font-weight: 600;
    color: #fff;
  }
  .point-of-sale__sync {
    position: absolute;
    top: 0;
    left: 0;
  }
  .point-of-sale__selector {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const PointOfSaleCardPublishers = styled.div`
  position: absolute;
  bottom: -12px;
  left: 20px;
  display: flex;
  & > div {
    position: relative;
  }
`;

const PublisherBadge = styled.div`
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: ${props =>
    props.state === "IS_SYNC"
      ? "#2dcc4d"
      : props.state === "IS_UNSYNC"
      ? "#f14336"
      : "#C0C0C0"};
  position: absolute;
  bottom: 1px;
  right: 3px;
`;

const PointOfSaleCardBody = styled.div`
  padding: 20px;
  word-break: break-word;
  height: 130px;
  h4 {
    font-weight: 600;
    font-size: 15px;
  }
`;

const Icon = styled.img(({ state }) => ({
  background: "#fff",
  padding: "2px",
  borderRadius: "50%",
  marginRight: "3px",
  width: "24px",
  filter: `grayscale(${state ? "0%" : "100%"})`
}));

export const PointOfSaleItem = ({
  businessProfile,
  facebookPage,
  gmbLocation,
  selectMode,
  selected,
  onSelect
}) => {
  const {
    name,
    address,
    gmbInformations: { gmbCoverPicture }
  } = businessProfile;

  return (
    <PointOfSaleCard className={selected ? "selected" : ""} onClick={onSelect}>
      <PointOfSaleCardHeader coverPicture={gmbCoverPicture || emptyCover}>
        {selectMode && (
          <input
            className="point-of-sale__selector"
            type="checkbox"
            checked={selected}
            onChange={onSelect}
          />
        )}
        <PointOfSaleCardPublishers>
          <div>
            <Icon
              src={facebookLogo}
              alt="facebook"
              state={facebookPage?.state}
            />
            <PublisherBadge state={facebookPage?.state} />
          </div>
          <div>
            <Icon src={googleLogo} alt="google" state={gmbLocation?.state} />
            <PublisherBadge state={gmbLocation?.state} />
          </div>
        </PointOfSaleCardPublishers>
      </PointOfSaleCardHeader>
      <PointOfSaleCardBody>
        <h4>{name}</h4>
        <span>{address}</span>
      </PointOfSaleCardBody>
    </PointOfSaleCard>
  );
};
