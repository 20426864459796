import { ACCESS_TOKEN, ACCESS_TOKEN_EXP, REFRESH_TOKEN, REFRESH_TOKEN_EXP } from "../constants";
import moment from "moment-timezone";

export const setTokenToLocalStorage = ({
    accessToken,
    accessExp,
    refreshToken,
    refreshExp
}) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(ACCESS_TOKEN_EXP, accessExp);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(REFRESH_TOKEN_EXP, refreshExp);
};

export const removeTokenFromLocalStorage = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN_EXP);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN_EXP);
};

export const isAuthenticated = () => moment.tz(
    localStorage.getItem(ACCESS_TOKEN_EXP),
    "YYYY-MM-DD HH:mm:ss",
    "Europe/Paris"
).isValid();

export const getCompanyId = () =>
    JSON.parse(
        atob(localStorage.getItem(ACCESS_TOKEN).split('.')[1])
    ).sub.groupId;