import React, { useEffect } from "react"
import SubHeader from '../../../modules/subHeader'
import HeaderStepper from '../../../modules/HeaderStepper'
import iconNetwork from '../../../ressources/images/network_network.svg'
import { PostForm } from '../components'
import { useDispatch, useSelector } from 'react-redux'
import { createPostAction, fetchPostRequestAction } from '../actions'
import { useParams } from 'react-router-dom'
import { selectPost } from '../reducers'
 
export const NewPost = ({ history }) => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const { data, fetched } = useSelector(selectPost)

    useEffect(() => {
        if (id) {
            dispatch(fetchPostRequestAction(Number(id)))
        }
    }, [id])

    const onSubmit = values => {
        dispatch(createPostAction(values, history))
    }

    return (
        <>
            <SubHeader
                className="subHeader"
                breadcrumb={{
                    Publier: '/posts?status=published',
                    'Nouvelle publication': ''
                }}
                Title="Publier"
                history={history}
            />
            <HeaderStepper
                startWithIcon
                startIcon={iconNetwork}
                currentStep={1}
                steps={[
                    'Rédigez votre publication, et choisissez vos réseaux de diffusion',
                    'C\'est parti !'
                ]} />
            <div className="bodyContainer containerFlex">
                <PostForm onSubmit={onSubmit} post={data} mode={id ? 'edit' : 'new'} />
            </div>
        </>
    )
}