import React from "react";
import { Field } from "redux-form";

const sectionHeaderStyle = {
    background: 'rgba(109, 119, 220, 0.2)',
    borderRadius: 2,
    padding: '14px 40px',
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#6D77DC',
    cursor: 'pointer'
};
const sectionBodyStyle = {
    background: '#FFFFFF',
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    padding: '26px 39px',
    paddingBottom: '9px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
};
const sectionBodyElemStyle = {
    width: '30%',
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#6C6C6C',
    marginBottom: 30
};
const sectionBodyElemLineStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8
};
const sectionLabelStyle = {
    fontFamily: "inherit",
    cursor: "pointer",
    marginBottom: 0,
    fontSize: 14,
    color: "rgb(108, 108, 108)",
    paddingLeft: 12
}

export const MULTI_FIELDS_EDIT = [
    {
        sectionLabel: "Description",
        sectionName: "publishersDescription",
        group: [
            { groupLabel: "Catégories Google My Business", fields: [
                    {
                        label: "Catégorie principale",
                        name: "hasGmbMainCategory"
                    },
                    {
                        label: "Catégorie(s) supplémentaire(s)",
                        name: "hasGmbAdditionalCategories"
                    }
                ]},
            { groupLabel: "Site internet", fields: [
                    { label: "Adresse du site internet", name: "hasWebsiteUrl" }
                ]},
            { groupLabel: "Descriptions", fields: [
                    { label: "Description courte Facebook", name: "hasFbDescription" },
                    { label: "Description longue Google", name: "hasGmbDescription" },
                ]},
            { groupLabel: "Catégories Facebook", fields: [
                    { label: "Catégorie principale", name: "hasFbCategories" }
                ]}
        ]
    },
    {
        sectionLabel: "Contact",
        sectionName: "contact",
        group: [
            { groupLabel: "", fields: [
                    { label: "Adresse e-mail du propriétaire", name: "hasEmail" }
                ]},
            { groupLabel: "", fields: [
                    { label: "Téléphone(s)", name: "hasPhone" }
                ]},
            { groupLabel: "", fields: [] }
        ]
    },
    {
        sectionLabel: "Photos",
        sectionName: "media",
        group: [
            { groupLabel: "", fields: [
                    { label: "Logo", name: "hasLogo" }
                ]},
            { groupLabel: "", fields: [
                    { label: "Photo de couverture", name: "hasCoverPicture" }
                ]},
            { groupLabel: "", fields: [] }
        ]
    },
    {
        sectionLabel: "Horaires",
        sectionName: "hours",
        group: [
            { groupLabel: "Horaires d’ouverture habituels", fields: [
                    { label: "Lundi", name: "hasMonday" },
                    { label: "Mardi", name: "hasTuesday" },
                    { label: "Mercredi", name: "hasWednesday" },
                    { label: "Jeudi", name: "hasThursday" },
                    { label: "Vendredi", name: "hasFriday" },
                    { label: "Samedi", name: "hasSaturday" },
                    { label: "Dimanche", name: "hasSunday" },
                ]},
            { groupLabel: "Aménagement temporaire", fields: [
                    { label: "Fermeture temporaire", name: "hasIsTemporarilyClosed" }
            ]},
            { groupLabel: "Horaires exceptionnels", fields: [
                    { label: "Horaires d'ouverture exceptionnels", name: "hasExceptionalOpeningHours" },
                    { label: "Horaires de fermeture exceptionnels", name: "hasExceptionalClosingTimes" }
            ]}
        ]
    }
];

export const MultiEditChecker = ({ selectedFields, changeFieldValue }) => (
    <>
        {MULTI_FIELDS_EDIT.map(({ sectionLabel, sectionName, group }) => (
            <div key={sectionName}>
                <label style={sectionHeaderStyle}>
                    <input
                        style={{marginRight: 12}}
                        checked={selectedFields && Object.values(selectedFields[sectionName]).every(Boolean)}
                        type="checkbox" onChange={e =>
                            group.map(({ fields }) =>
                                fields.map(({ name }) =>
                                    changeFieldValue(`selected.${sectionName}.${name}`, e.target.checked)
                                )
                            )
                    } />
                    {sectionLabel}
                </label>
                <div style={sectionBodyStyle}>
                    {group.map(({ groupLabel, fields }, index) => (
                        <div key={`${sectionName}.${index}`} style={sectionBodyElemStyle}>
                            <b>{groupLabel}</b>
                            {fields.map(({ label, name }) => (
                                <div key={name} style={{...sectionBodyElemLineStyle, marginTop: 6}}>
                                    <Field id={name} name={`selected.${sectionName}.${name}`} component="input" type="checkbox" />
                                    <label style={sectionLabelStyle} htmlFor={name}>{label}</label>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </>
);