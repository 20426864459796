import { buildStoreChunk, Types } from "redux-scc";

const userInfoSchema = Types.reducer(
  Types.shape({
    username: Types.string(),
    email: Types.string(),
    name: Types.string(),
    surname: Types.string(),
  })
);

export const { reducers, selectors, actions } = buildStoreChunk("auth-store", {
  userInfo: userInfoSchema,
});

export const userInfoAction = actions.userInfo;
export const selectUserInfo = selectors.userInfo;
