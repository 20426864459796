import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

export const useQuery = () =>
    new URLSearchParams(useLocation().search);

export const usePreviewImage = file => {
    const [preview, setPreview] = useState("")

    useEffect(() => {
        if (!file) return

        const reader = new FileReader();
        reader.onload = e => {
            setPreview(e.target.result)
        }
        reader.readAsDataURL(file);
    }, [file])

    return preview
}

export const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback(e)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    })
}

export const useToggle = (initialState = false) => {
    const [state, setState] = useState(initialState)

    const toggle = () => setState(!state)
    
    return [
        state,
        toggle
    ]
}