import React from "react";
import { Route } from "react-router-dom";
import { DefaultLayout } from "../default/containers";

export const LayoutRoute = ({ layout: Layout = DefaultLayout, component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout {...rest}>
            <Component {...props} />
        </Layout>
    )} />
);