export const PUBLICATION_DATE_CHOICE = [
    { label: "Aujourd'hui", value: "today" },
    { label: "Une semaine", value: "week" },
    { label: "Un mois", value: "month" },
    { label: "Un an", value: "year" },
    { label: "Toujours", value: "all" }
]

export const PUBLISHER_OPTIONS = [
    { label: "Facebook", value: "facebook" },
    { label: "Google", value: "google" },
    //{ label: "Twitter", value: "twitter" },
]

export const PLACE_OF_PUBLICATION_OPTIONS = [
    { label: "Publiés depuis Shoki", value: "shoki" },
    { label: "Publiés depuis le réseau social", value: "socialnetwork" },
]