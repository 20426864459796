import React from "react"
import { Field } from "redux-form";
import { renderInput, renderSelector } from "../../../commons";
import { required, min } from "../../../../helpers";
import { COUNTRIES } from "../../constants";

const minSiret = min(14)
const minZipCode = min(5)

export const PointOfSaleContactInformation = () => (
    <>
        <Field name="businessDetails.siret" validate={[required, minSiret]} component={renderInput} maxLength={14} type="tel" title="SIRET" isMandatory />
        <Field name="businessDetails.businessName" description="Facebook n’accepte pas de modification de nom autrement que depuis votre page d'établissement" validate={required} component={renderInput} title="Nom du commerce" isMandatory />
        <Field name="businessDetails.address" validate={required} component={renderInput} title="Adresse" isMandatory />
        <Field name="businessDetails.addressAdditionalInfos" component={renderInput} title="Complément d'adresse" />
        <Field name="businessDetails.zipCode" validate={[required, minZipCode]} component={renderInput} maxLength={5} type="tel" title="Code postal" isMandatory />
        <Field name="businessDetails.city" validate={required} component={renderInput} title="Ville" isMandatory />
        <Field name="businessDetails.country" validate={required} component={renderSelector} options={COUNTRIES} title="Pays" isMandatory />
    </>
);