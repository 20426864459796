import { call, put, takeEvery, fork } from "redux-saga/effects";
import { createPointOfSale } from "../api";
import { CREATE_POINT_OF_SALE } from "../constants";
import { uploadMediasPointOfSaleRequest } from "../actions";
import { toast } from "react-toastify";
import omit from "lodash/fp/omit";

function* createPointOfSaleWorker({ values, history }) {
  try {
    const {
      data: { id }
    } = yield call(createPointOfSale, omit(["media", "upload"])(values));
    yield put(uploadMediasPointOfSaleRequest(id, values.upload));

    history.push(`/point-of-sales/${id}/edit?tab=1`);
    toast.success(
      "Nouvel établissement créé avec succès, vous pouvez lier vos fiches Google et Facebook"
    );
  } catch (e) {
    toast.error("Une erreur est survenue, veuillez réessayer plus tard");
    console.error(e);
  }
}

function* watchCreatePointOfSale() {
  yield takeEvery(CREATE_POINT_OF_SALE, createPointOfSaleWorker);
}

export const PointOfSaleCreateSagas = [fork(watchCreatePointOfSale)];
