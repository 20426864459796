const createImage = url => 
    new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => resolve(image)
        image.onerror = error => reject(error)
        image.src = url
    })

const getVideoMetadata = url =>
    new Promise((resolve, reject) => {
        const video = document.createElement('video')
        video.onloadedmetadata = () => resolve(video)
        video.onerror = error => reject(error)
        video.src = url
    })
    
const readFile = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
    })

export const getCroppedImage = async (imageSrc, pixelCrop) => {    
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
    );

    return new Promise(resolve =>
        canvas.toBlob(blob => 
          resolve(new File([blob], blob.name)), 'image/png')
    )
}

export const validFile = async (file, rules) => {
    let errors = {}
    const { size, type } = file
    const mimeType = type.split('/')[0]
    const { minResolution, maxResolution, maxWeight, maxDuration } = rules[mimeType]

    const result = await readFile(file)
    if (size/1024/1024 > maxWeight) {
        errors.weight = `Le poids de ${mimeType === 'image' ? 'l\'image' : 'la vidéo'} ne doit pas dépasser ${maxWeight} Mo`
    }

    switch (mimeType) {
        case 'image':
            const { width, height } = await createImage(result)
            if (width < minResolution.width && height < minResolution.height) {
                errors.resolution = `La résolution de l'image doit être au minimum de ${minResolution.width}x${minResolution.height}`
            } else if (width > maxResolution?.width && height > maxResolution?.height) {
                errors.resolution = `La résolution de l'image doit être au maximum de ${maxResolution.width}x${maxResolution.height}`
            }
            break
        case 'video':
            const { duration, videoWidth, videoHeight } = await getVideoMetadata(result)
            if (videoWidth < minResolution.width && videoHeight < minResolution.height)  {
                errors.resolution = `La résolution de la vidéo doit être au minimum de ${minResolution.width}x${minResolution.height}`
            }
            if (duration > maxDuration) {
                errors.duration = `La durée de la vidéo ne doit pas excéder ${maxDuration} secondes`
            }
            break
    }

    return errors
}