import React, { useEffect, useState } from "react";
import { connect } from "react-redux"
import SubHeader from '../../../modules/subHeader'
import { PostList } from '../components'
import { ConnectedPostFilter as PostFilters } from './PostFilters'
import { useQuery } from '../../../helpers'
import { fetchListPostRequestAction, resetListPostAction, deletePostAction } from '../actions'
import { selectPostsGroupByDate, selectFilters } from '../reducers'

const Posts = ({ history, posts, filters, fetchListPosts, deletePost, resetListPosts }) => {
    const status = useQuery().get('status')
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        return () => resetListPosts()
    }, [])

    useEffect(() => {
        resetListPosts()
        setCurrentPage(1)
    }, [status, filters])

    useEffect(() => {
        fetchListPosts(currentPage, { status, ...filters })
    }, [currentPage, status, filters])

    const { data, pageCount, fetched } = posts
    return (
        <>
            <SubHeader
                breadcrumb={{Publier: ''}}
                Title="Toutes vos publications"
                hasTab
                tabs={{
                    '/posts?status=published': 'Posts publiés',
                    '/posts?status=scheduled': 'Posts programmés',
                    '/posts?status=archived': 'Posts supprimés',
                }}
                buttons={['Nouvelle publication']}
                onButtonClick={() => history.push('/posts/new')}
                history={history} />
            <div className="bodyContainer containerFlex">
                <div className="containerWithFilter">
                    <div className="conversationWrapper">
                        <PostList
                            posts={data}
                            loading={fetched}
                            hasNextPage={currentPage < pageCount}
                            onLoadMore={() => setCurrentPage(currentPage + 1)}
                            onDeletePost={deletePost}
                        />
                    </div>
                    <div className="filtersFilter">
                        <PostFilters filters={filters} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    posts: selectPostsGroupByDate(state),
    filters: selectFilters(state)
})

const mapDispatchToProps = dispatch => ({
    fetchListPosts: (currentPage, filters) => dispatch(fetchListPostRequestAction(currentPage, filters)),
    deletePost: id => dispatch(deletePostAction(id)),
    resetListPosts: () => dispatch(resetListPostAction())
})

export const ConnectedPost = connect(
    mapStateToProps, mapDispatchToProps
)(Posts)