/*******************************************************************************
** @Author:					Thomas Bouder <Tbouder>
** @Email:					Tbouder@protonmail.com
** @Date:					Tuesday 16 April 2019 - 09:20:50
** @Filename:				Popup.js
**
** @Last modified by:		Florian
** @Last modified time:		Friday 23 August 2019 - 14:58:26
*******************************************************************************/

import	React							from	'react';
import	ReactDOM						from	'react-dom';

import	cross							from	'../ressources/images/cross.svg';
// import									'./Popup.css';

function	Popup(props) {
	function	renderButtons() {
		const	buttons = [];
		if (props.cancelText) {
			buttons.push(
				<div
					key={'cancel'}
					className={'placeModalButton empty'}
					onClick={props.onCancel}>
					{props.cancelText}
				</div>
			);
		}
		if (props.confirmText) {
			buttons.push(
				<div
					key={'confirm'}
					className={'placeModalButton'}
					onClick={props.onConfirm}>
					{props.confirmText}
				</div>
			);
		}
		return (buttons);
	}
	function	renderPopup() {
		return (
			<div className={'modalBackground'} onClick={props.onClose}>
				<div className={'modal'} onClick={e => e.stopPropagation()}>
					<img
						src={cross}
						alt={'close'}
						className={'modalClose'}
						onClick={props.onClose} />
					{props.hasImage &&
						<img
							src={props.image}
							alt={'success'}
							className={'modalImage'} />
					}
					<span className={'modalTitle'}>{props.title}</span>
					<span className={'modalSubtitle'}>{props.subtitle}</span>
					<div className={'placeModalButtonContainer'}>
						{renderButtons()}
					</div>
				</div>
			</div>
		);
	}

	return (ReactDOM.createPortal(renderPopup(), window.document.getElementById('root')));
}
export default Popup;
