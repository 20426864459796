import axios from "axios";
import { refreshTokens } from '../modules/auth'
import { removeTokenFromLocalStorage } from "../modules/auth";
import moment from "moment-timezone";
import { ACCESS_TOKEN, ACCESS_TOKEN_EXP, REFRESH_TOKEN_EXP } from "../modules/auth/constants";

export const authenticationInstance = axios.create({
    baseURL: `https://auth.${process.env.REACT_APP_SERVER_DOMAIN}`,
    timeout: 10000,
    headers: {
        Accept: "application/json"
    },
});

export const shokiInstance = axios.create({
    baseURL: `https://presence-local.${process.env.REACT_APP_SERVER_DOMAIN}`,
    headers: {
        Accept: "application/json",
    }
});

const logoutAndRedirect = () => {
    removeTokenFromLocalStorage();
    window.location = "/";
};

shokiInstance.interceptors.request.use(
    async request => {
        const refreshTokenExp = moment.tz(
            localStorage.getItem(REFRESH_TOKEN_EXP),
            "YYYY-MM-DD HH:mm:ss",
            "Europe/Paris"
        );

        if (!refreshTokenExp.isValid() || refreshTokenExp.isBefore()) {
            logoutAndRedirect();
            throw new axios.Cancel("Refresh Token is expired, disconnecting...")
        }

        const accessTokenExp = moment.tz(
            localStorage.getItem(ACCESS_TOKEN_EXP),
            "YYYY-MM-DD HH:mm:ss",
            "Europe/Paris"
        );

        if (!accessTokenExp.isValid() || accessTokenExp.isBefore()) {
            try {
                await refreshTokens()
            } catch (e) {
                console.error("An error occurred while refreshing token.", e)
            }
        }

        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        };

        return request;
    }
);

shokiInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            logoutAndRedirect();
        }

        return Promise.reject(error);
    }
);
