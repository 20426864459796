import { 
    FETCH_LIST_POST_REQUEST, 
    FETCH_LIST_POST_SUCCESS,
    FETCH_POST_REQUEST,
    FETCH_POST_SUCCESS,
    RESET_LIST_POST,
    SET_PUBLICATION_PERIOD_FILTER,
    SET_PUBLISHER_FITLTER,
    SET_POINT_OF_SALE_FILTER,
    RESET_FILTERS,
    CREATE_POST,
    DELETE_POST,
    DELETE_POST_SUCCESS
} from '../constants'

export const fetchListPostRequestAction = (offset, filters) => ({
    type: FETCH_LIST_POST_REQUEST,
    offset,
    filters
})

export const fetchListPostSuccessAction = data => ({
    type: FETCH_LIST_POST_SUCCESS,
    data
})

export const fetchPostRequestAction = id => ({
    type: FETCH_POST_REQUEST,
    id
})

export const fetchPostSuccessAction = data => ({
    type: FETCH_POST_SUCCESS,
    data
})

export const resetListPostAction = () => ({
    type: RESET_LIST_POST
})

export const setPublicationPeriodFilterAction = publicationPeriod => ({
    type: SET_PUBLICATION_PERIOD_FILTER,
    publicationPeriod
})

export const setPublisherFilterAction = (publisher, selected) => ({
    type: SET_PUBLISHER_FITLTER,
    publisher,
    selected
})

export const setPointOfSaleFilterAction = (pointOfSale, selected) => ({
    type: SET_POINT_OF_SALE_FILTER,
    pointOfSale,
    selected
})

export const resetFiltersAction = () => ({
    type: RESET_FILTERS
})

export const createPostAction = (values, history) => ({
    type: CREATE_POST,
    values,
    history
});

export const deletePostAction = id => ({
    type: DELETE_POST,
    id
})

export const deletePostSuccessAction = id => ({
    type: DELETE_POST_SUCCESS,
    id
})