import React from "react";
import { PointOfSaleContact, PointOfSaleDescription, PointOfSaleHoursInformation, PointOfSalePictures } from "../forms";

const sectionHeaderStyle = {
    background: 'rgba(109, 119, 220, 0.2)',
    borderRadius: 2,
    padding: '14px 40px',
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#6D77DC',
    cursor: 'pointer'
};
const sectionBodyStyle = {
    background: '#FFFFFF',
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    padding: '26px 39px',
    paddingBottom: '9px',
};

export const MultiEditForm = ({ selectedFields: {
    publishersDescription,
    contact,
    media,
    hours
}, GMBCategories, FBCategories }) => (
    <>
        {publishersDescription && Object.values(publishersDescription).includes(true) && (
            <>
                <div style={sectionHeaderStyle}>Description</div>
                <div style={sectionBodyStyle}>
                    <PointOfSaleDescription fields={publishersDescription} GMBCategories={GMBCategories} FBCategories={FBCategories} />
                </div>
            </>
        )}
        {contact && Object.values(contact).includes(true) && (
            <>
                <div style={sectionHeaderStyle}>Contact</div>
                <div style={sectionBodyStyle}>
                    <PointOfSaleContact fields={contact} />
                </div>
            </>
        )}
        {media && Object.values(media).includes(true)  && (
            <>
                <div style={sectionHeaderStyle}>Photos</div>
                <div style={sectionBodyStyle}>
                    <PointOfSalePictures fields={media} />
                </div>
            </>
        )}
        {hours && Object.values(hours).includes(true) && (
            <>
                <div style={sectionHeaderStyle}>Horaires</div>
                <div style={sectionBodyStyle}>
                    <PointOfSaleHoursInformation fields={hours} />
                </div>
            </>
        )}
    </>
);