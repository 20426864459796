import React from "react";
import loadingIcon from '.././../../../ressources/images/loading-white.svg'

const containerStyle = {
    height: 85,
    background: '#273138',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0
};
const	buttonBackStyle = {
    border: '1px solid #838383',
    borderRadius: 5,
    fontFamily: 'Barlow',
    fontWeight: '600',
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.5)',
    background: 'transparent',
    padding: '10px 80px',
    height: '60%'
};
const	nextButtonStyleOff = {background: 'rgba(255, 255, 255, 0.3)'};
const	nextButtonStyleOn = {background: '#28B446', color: '#FFFFFF'};

export const MultiEditNavigation = ({ prevStep, nextStep, endStep, canPrev, canNext, canEnd, totalPointOfSales, loading }) => (
    <div style={containerStyle}>
        <button disabled={canPrev} onClick={prevStep} style={{...buttonBackStyle, marginRight: 10}}>Précédent</button>
        {!canEnd && <button disabled={!canNext} onClick={nextStep} style={{...buttonBackStyle, ...(canNext ? nextButtonStyleOn : nextButtonStyleOff), marginLeft: 10}}>Suivant</button>}
        {canEnd && <button disabled={loading} onClick={endStep} style={{...buttonBackStyle, ...(!loading ? nextButtonStyleOn : nextButtonStyleOff), marginLeft: 10}}>
            {loading && <img style={{marginRight: 8}} src={loadingIcon} alt="" />}
            Mettre à jour pour {totalPointOfSales} établissement(s)
        </button>}
    </div>
);