import React from 'react'
import styled from 'styled-components'
import cross from '../../../ressources/images/cross.svg'

const DialogWrapper = styled.div`
    display: ${props => props.isOpen ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0,0,0,.25);
`

const DialogContent = styled.div`
    background: #f5f7fc;
    width: 70%;
    border-radius: 10px;
    max-width: 700px;
    width: 700px;
    max-height: 90%;
    margin: 20px;
    overflow-y: auto;
`

const DialogHeader = styled.header`
    background: #fff;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid #ced6dd;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dialog__title {
        font-weight: bold;
    }
    .dialog__btn-close {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
    }
`

const DialogMessage = styled.div`
    padding: 1.5rem;
    overflow-y: auto;
    text-align: ${(props) => props.align};
`

const DialogAction = styled.div`
    padding: 1rem;
    border-top: 1px solid #ced6dd;
    text-align: right;
    button {
        margin-left: 15px;
        cursor: pointer;
    }
    .dialog__action__btn {
        &-cancel {
            color: #6d77dc;
        }
        &-confirm {
            background: #6d77dc;
            border: none;
            padding: 15px;
            color: #fff;
            font-weight: bold;
        }
    }
`

export const Dialog = ({ title, align, cancelLabel = 'Annuler', confirmLabel = 'Enregister', type, isOpen, onClose, onConfirm, children }) => (
    <DialogWrapper isOpen={isOpen} onClick={onClose}>
        <DialogContent onClick={e => e.stopPropagation()}>
            <DialogHeader>
                <h4 className="dialog__title">{title}</h4>
                <button type="button" className="dialog__btn-close" onClick={onClose}>
                    <img src={cross} alt="Fermer" />
                </button>
            </DialogHeader>
            <DialogMessage align={align}>
                {children}
            </DialogMessage>
            <DialogAction>
                <button className="dialog__action__btn-cancel" type="button" onClick={onClose}>{cancelLabel}</button>
                <button className="dialog__action__btn-confirm" type="button" onClick={onConfirm}>{confirmLabel}</button>
            </DialogAction>
        </DialogContent>
    </DialogWrapper>
)
