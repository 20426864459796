import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { ListPointOfSale, NewPointOfSale } from "../containers";

export const PointOfSaleRoutes = ({ match }) => (
    <Switch>
        <Route exact path={[`${match.path}/new`, `${match.path}/:id/edit`]} component={NewPointOfSale} />
        <Route exact path={match.path} component={ListPointOfSale} />
        <Redirect to={match.path} />
    </Switch>
);