import { combineReducers } from "redux";
import { reducer as FormReducer } from "redux-form";
import { PointOfSaleReducer } from "../modules/point-of-sale";
import { PostReducer } from "../modules/post";
import authModule from "../modules/auth";

export const reducers = combineReducers({
  PointOfSaleReducer,
  PostReducer,
  ...authModule.reducers,
  form: FormReducer,
});
