import React from 'react'

export const PrivacyPolicies = () => (
    <article>
        <section>
            <h2>Glossaire</h2>
            <p>Chaque terme débutant par une majuscule a le sens qui lui est donné ci-après.</p>
            <p><strong>« Politique de confidentialité et de protection des Données Personnelles »</strong> et <strong>« Politique »</strong> : désigne la présente Politique décrivant les mesures prises pour le traitement, l’exploitation et la gestion de vos Données Personnelles et vos droits en tant que personne concernée par le traitement.</p>
            <p><strong>« Données »</strong> ou <strong>« Données Personnelles »</strong> : Désigne toute information se rapportant à vous et permettant de vous identifier directement ou indirectement.</p>
            <p><strong>« Traitement »</strong> : Désigne toute opération ou tout ensemble d’opérations appliquées à vos Données Personnelles.</p>
        </section>
        <section>
            <h2>Préambule</h2>
            <p>Conscient de l’importance d’assurer la confidentialité des données, VISIPERF prend des engagements forts vis-à-vis de la protection des données à caractère personnel.</p>
            <p>Aussi, la présente Politique de Protection des Données a pour objectif de vous informer sur les engagements et mesures pratiques pris par VISIPERF afin de veiller au respect de vos données à caractère personnel.</p>
            <p>La présente Politique de Protection des Données pourra évoluer en fonction du contexte légal et réglementaire ainsi que des avis rendus tantôt par la Commission Nationale Informatique et Libertés tantôt par le Comité Européen sur la Protection des Données.</p>
            <p>Cette politique de confidentialité présente le traitement que nous faisons de vos données personnelles et la manière avec laquelle nous créons, organisons et mettons en œuvre nos activités en ligne et hors ligne.</p>
            <p>​La Gestion des cookies est disponible dans une page séparée.</p>
            <p>Nous attachons la plus grande importance au respect de la vie privée et des données individuelles de nos utilisateurs. Nous nous engageons à respecter et mettre à disposition les outils et démarches simplifiées vous permettant de faire valoir vos droits conformément à la Loi « Informatique et Libertés » n°78-17 du 06 janvier 1978 modifiée et au Règlement nᵒ 2016/679, dit Règlement Général sur la Protection des Données (RGPD). Afin de maintenir une protection maximale quant aux données à caractère personnel que nous traitons, notre site et les entités les administrant (hébergeur, prestataires informatiques) se conformeront aux principes désignés dans les textes nationaux et communautaires.</p>
        </section>
        <section>
            <h2>Données collectées et finalités de la collecte</h2>
            <p>Les pratiques spécifiques décrites dans cette déclaration de politique de la vie privée ne concernent que le Site Internet susmentionné. Si des liens externes d’autres organes/entités partenaires lié(e)s à VISIPERF sont présents sur notre Site, nous vous recommandons d’examiner leurs propres déclarations de politique de la vie privée et ne pouvons être tenus responsables de leur politique et contenus.</p>
            <p>En général, il vous est possible de consulter notre Site internet sans divulguer d’informations personnelles. ​Les seules données à caractères personnelles que nous collectons et conservons sont celles que vous fournissez lorsque vous remplissez le formulaire d’inscription.</p>
            <p>Dans le cadre formulaire du compte nous sommes susceptibles de collecter votre :</p>
            <ul>
                <li>Nom</li>
                <li>Prénom</li>
                <li>Téléphone</li>
                <li>Email</li>
            </ul>
            <p>Dans le cadre de l’usage de l’offre, nous le client référence les données publiques de ses points de vente telles que :</p>
            <ul>
                <li>Nom du point de vente</li>
                <li>Adresse postale</li>
                <li>Email</li>
                <li>Numéro de téléphone</li>
                <li>Horaires d’ouvertures</li>
            </ul>
            <p>Vos données ne sont utilisées que par VISIPERF en vue de traiter votre demande.</p>
            <p>La finalité des données collectées par VISIPERF est effectuée dans l’unique objectif de pouvoir constituer un dossier dans les règles de l’art dans le but de pouvoir accomplir la mission pour laquelle elle a été saisie.</p>
            <p>Conformément à l’article 5 du Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, nous traitons les données à caractère personnel de manière licite, loyale et transparente au regard de la personne concernée ; et nous collectons ces données des finalités déterminées, explicites et légitimes à savoir la constitution du dossier pour les différentes solutions d’intervention de la société VISIPERF.</p>
            <p>Nous collectons uniquement les données adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités des missions de la société VISIPERF.</p>
        </section>
        <section>
            <h2>Informations sur l’exigence de fourniture de données</h2>
            <p>A des fins contractuelles et pour mener à bien notre mission, la transmission de certaines de vos données à caractère personnel conditionne la conclusion du contrat qui va lier les services du VISIPERF avec ses futurs clients.</p>
        </section>
        <section>
            <h2>Conservation des données</h2>
            <p>VISIPERF conserve les données des utilisateurs non clients à des fins de prospection pour une durée de trois ans à compter de leur collecte ou du dernier contact.</p>
        </section>
        <section>
            <h2>Sécurité</h2>
            <p>VISIPERF assure la sécurité de vos données à caractère personnel en mettant en place une protection des données renforcée par l’utilisation de moyens de sécurisation physiques et logiques.</p>
        </section>
        <section>
            <h2>Vos droits</h2>
            <p>En application de la réglementation Informatique et libertés, vous disposez d’un droit d’accès, de rectification, d’effacement, de limitation du traitement, de portabilité, de retrait de votre consentement et de définir le sort de vos données après votre décès relativement à l’ensemble des données vous concernant qui s’exercent auprès du référent à la protection des données par courrier électronique à l’adresse suivante : hello@visiperf.io accompagné d’une copie d’un titre d’identité signé.</p>
            <p>Vous pouvez également introduire une réclamation auprès d'une autorité de contrôle, la Commission Nationale Informatique et Libertés.</p>
        </section>
        <section>
            <h2>Droit d’opposition</h2>
            <p>Conformément aux dispositions de l’article 21 du Règlement Général sur la Protection des Données (RGPD), Règlement UE n°2016/679 du 27 avril 2016, vous disposez d’un droit d’opposition.</p>
            <p>Vous pouvez à tout moment vous opposer au traitement de vos données en nous contactant par le biais de notre site internet à l’adresse suivante hello@visiperf.io</p>
            <p>Votre demande devra préciser les motifs tenant à votre situation particulière.</p>
            <p>VISIPERF pourra justifier son refus pour les raisons suivantes :</p>
            <ul>
                <li>Il existe des motifs légitimes et impérieux à traiter les données ou que celles-ci sont nécessaires à la constatation, exercice ou défense de droits en justice ;</li>
                <li>Vous avez consenti– vous devez alors retirer ce consentement et non vous opposer ;</li>
                <li>Un contrat vous lie avec VISIPERF;</li>
                <li>Une obligation légale qui nous impose de traiter vos données ;</li>
                <li>Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique.</li>
            </ul>
        </section>
        <section>
            <h2>Contact</h2>
            <p>Pour toutes demandes d’information concernant la politique de protection des données à caractère personnel mise en œuvre par VISIPERF vous pouvez vous adressez à hello@visiperf.io</p>
        </section>
    </article>
)