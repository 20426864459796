import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../../ressources/images/logo/visiretail2.svg'

const containerStyle = {
    maxWidth: 1240,
    margin: "0 auto",
    padding: "0 24px"
}

const RGPDHeader = styled.header`
    padding: 16px 24px
    position: fixed
    width: 100%
    z-index: 10
    background: #fff
    box-shadow: 0 2px 4px rgba(0,0,0,.06)
`

const RGPDMain = styled.main`
    ${containerStyle}
    background: #f5f7fc
    padding-top: 90px
    h2 {
        font-size: 2.2rem
        &:first-child {
            margin-top: 10px
        }
    }
    h3 {
        font-size: 1.8rem
    }
`

export const RGPDLayout = ({ children, title }) => (
    <div style={{position: "relative"}}>
        <RGPDHeader>
            <div style={{display: "flex", alignItems: "center", ...containerStyle}}>
                <Link to="/">
                    <img src={logo} alt="visiretail" />
                </Link>
                <h1 style={{fontSize: "2rem", margin: "0 0 0 16px"}}>{title}</h1>
                <Link style={{color: "#8e54e9"}} to="/">Retour</Link>
            </div>
        </RGPDHeader>
        <RGPDMain>
            {children}
        </RGPDMain>
    </div>
)