/*******************************************************************************
** @Author:					Thomas Bouder <Tbouder>
** @Email:					Tbouder@protonmail.com
** @Date:					Tuesday 16 April 2019 - 14:41:43
** @Filename:				HeaderStepper.js
**
** @Last modified by:		Florian
** @Last modified time:		Friday 23 August 2019 - 15:02:23
*******************************************************************************/

import	React			from 'react';

import	check			from	'../ressources/images/network_check_grey.svg';
import	checked			from	'../ressources/images/network_check_violet.svg';
import	next			from	'../ressources/images/network_next_grey.svg';
import	nexted			from	'../ressources/images/network_next_violet.svg';
import	letsGo			from	'../ressources/images/letsGo.svg';

// import									'./HeaderStepper.css';

function	HeaderStepper(props) {
	function	renderStep(step, index) {
		const	isLastStep = index + 1 === props.steps.length;

		if (step === 'C\'est parti !') {
			return (
				<div key={index} className={'headerStepper'}>
					<div className={'active'}>
						<img src={letsGo} alt={'checker'} className={'headerChecker'} width={40} />
						<p style={{margin: 0}}>{step}</p>
					</div>
				</div>
			);
		} else if (index === props.currentStep) {
			return (
				<div key={index} className={'headerStepper'}>
					<div className={'active'}>
						<img src={check} alt={'checker'} className={'headerChecker'} width={18} />
						<p>{step}</p>
					</div>
					{!isLastStep && <img src={next} alt={'chevron'} width={24} />}
				</div>
			);
		} else if (index < props.currentStep) {
			return (
				<div key={index} className={'headerStepper'}>
					<div className={'previous'}>
						<img src={checked} alt={'checker'} className={'headerChecker'} width={18} />
						<p style={{margin: 0}}>{step}</p>
					</div>
					{!isLastStep && <img src={nexted} alt={'chevron'} width={24} />}
				</div>
			);
		}

		return (
			<div key={index} className={'headerStepper'}>
				<div className={'none'}>
					<img src={check} alt={'checker'} className={'headerChecker'} width={18} />
					<p>{step}</p>
				</div>
				{!isLastStep && <img src={next} alt={'chevron'} width={24} />}
			</div>
		);
	}

	function	renderHeaderStepper() {
		return (
			<div className={'headerStepperMainContainer'}>
				{props.startWithIcon &&
					<div className={'headerStepperStarter'}>
						<img src={props.startIcon} alt={'startIcon'} width={30} />
					</div>
				}
				{props.steps.map((eachStep, index) => renderStep(eachStep, index))}
			</div>
		);
	}

	return renderHeaderStepper();
}
export default HeaderStepper;
