import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isAuthenticated } from "../helpers";
import { useQuery } from "../../../helpers";
import { REFRESH_TOKEN } from "../constants";
import { refreshTokens } from "../api";
import { whoAmIAction } from "../actions";
import { useDispatch } from "react-redux";

export const PrivateRoutes = (props) => {
  const token = useQuery().get("token");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) return;
    (async () => {
      localStorage.setItem(REFRESH_TOKEN, token);
      await refreshTokens();
      window.location = "/";
    })();
  }, [token]);

  useEffect(() => {
    dispatch(whoAmIAction());
  }, [isAuthenticated()]);

  return isAuthenticated() ? props.children : <Redirect to="/login" />;
};
