export const FACEBOOK_PREVIEW_GRID_TEMPLATE_AREAS = {
    1: '"m1 m1" "m1 m1"',
    2: '"m1 m1" "m2 m2"',
    3: '"m1 m1" "m2 m3"',
    4: '"m1 m1 m1" "m2 m3 m4"'
}
export const FACEBOOK_PREVIEW_MAX_MEDIA_SHOWING = 4

export const TWITTER_PREVIEW_GRID_TEMPLATE_AREAS = {
    1: '"m1 m1" "m1 m1"',
    2: '"m1 m2" "m1 m2"',
    3: '"m1 m2" "m1 m3"',
    4: '"m1 m2" "m3 m4"'
}
export const TWITTER_MAX_MEDIA = 4

const TWITTER_POST_CONFIG = {
    label: 'twitter',
    description: 280,
    video: {
        maxWeight: 15,
        maxDuration: 140,
        maxUpload: 1,
        minResolution: [32, 32],
        maxResolution: [1920, 1200]
    },
    image: {
        maxWeight: 5,
        maxUpload: 4,
        minResolution: undefined,
        maxGif: 1
    },
    multiMedia: false,
    maxMedia: 4
}

const GOOGLE_POST_CONFIG = {
    label: 'google',
    description: 1500,
    video: {
        maxWeight: 100,
        maxDuration: 3000,
        maxUpload: 10,
        minResolution: [1280, 720]
    },
    image: {
        maxWeight: 5,
        maxUpload: 10,
        maxGif: 0,
        minResolution: [250, 250]
    },
    multiMedia: true,
    maxMedia: 10
}

const FACEBOOK_POST_CONFIG = {
    label: 'facebook',
    description: 5000,
    video: {
        maxWeight: 4096,
        maxDuration: 14400,
        minResolution: undefined,
        maxUpload: 1
    },
    image: {
        maxWeight: 8,
        minResolution: undefined,
        maxGif: 1,
        maxUpload: undefined
    },
    multiMedia: false,
    maxMedia: undefined
}

export const ALL_POSTS_CONFIG = {
    facebook: FACEBOOK_POST_CONFIG,
    google: GOOGLE_POST_CONFIG,
    twitter: TWITTER_POST_CONFIG
}

export const NO_PUBLISHER_SELECTED = "Veuillez selectionner au moins un réseau social"