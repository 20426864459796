const	actual = 'fr';

const	Languages =
{
	// --- French
	fr:
	{
		facebook:					'Facebook',
		google:						'Google',
		twitter:					'Twitter',

		day:						'Jour',
		week:						'Semaine',
		month:						'Mois',
		year:						'Année',

		menu:
		{
			dashboard:				'Tableau de bord',
			businesses:				'Presence Management',
			publish:				'Publier',
			stats:					'Statistiques',
			rapport:				'Satisfaction clients',
			conversation:			'Review management\n(avis, commentaires)',
			reviews:				'Avis',
			comments:				'Commentaires',
			posts:					'Posts',
			settings:				'Paramètres',
			bill:					'Factures',
			postsHistory:			'Suivi publications',
			logout:					'Déconnexion'
		},

		mainConnectV2:
		{
			headerText:
			{
				login:				'Nouveau ici ?',
				loginLink:			'Contactez-nous',
				askDemo:			'Déjà inscrit ?',
				askDemoLink:		'Se connecter'
			},
			error: {
				emailEmpty:			'Email requis',
				passEmpty:			'Mot de passe requis',
				badEmailOrPass:		'Mauvais identifiants'
			},
			title:					'Perfectionner votre expérience client en quelques clics !',
			subtitle:				'Essayez gratuitement Shoki pendant 14 jours',
			email:					'Email',
			password:				'Mot de passe',
			lastname:				'Prénom (Obligatoire)',
			firstname:				'Nom (Obligatoire)',
			businessesName:			'Nom de votre commerce',
			connectButton:			'Se connecter',

			proEmail:				'E-mail professionnel (Obligatoire)',
			message:				'Commentaire',
			phone:					'Numéro de téléphone',
			job:					'Fonction',

			registerButton:			'S\'inscrire',
			support:				'Support',
			price:					'Prix',
			formule:				'Formule',
			connect:				'Connexion',
			site:					'Site',

			passwordForget:			'Mot de passe oublié',
			passwordSubtitle:		'Renseignez votre adresse ci-dessous et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
			changeMyPassword:		'Réinitialiser mon mot de passe',
			back:					'Retour à la page de connexion',

			newPassword:			'Nouveau mot de passe',
			confirmPassword:		'Confirmer le mot de passe'
		},

		mainStats: {
			stats:					'Statistiques',
			title:					'Vos actions analysées',
			titleSolo:				'Mes analyses',
			menu: {
				facebook:			'Facebook',
				twitter:			'Twitter',
				google:				'Google'
			},
			empty:					'Aucun compte à analyser pour le moment'
		},

		connectAccount:
		{
			name:					'Nom de l\'enseigne',
			nameFirstPlace:			'Nom de votre première enseigne',
			google:					'La connexion à Google autorisera la plateforme à voir vos commentaires et y répondre directement depuis Shoki Franchise',
			facebook:				'La connexion à Facebook autorisera la plateforme à voir vos commentaires et y répondre directement depuis Shoki Franchise',
			twitter:				'La connexion à Twitter autorisera la plateforme à voir vos commentaires et y répondre directement depuis Shoki Franchise',
			chooseFacebook:			'Choisir la page Facebook',
			chooseGoogle:			'Choisir le lieu Google',
			succes:					'Connexion réalisée avec succès à',
			continu:				'Continuer',
			delete:					'Supprimer l\'enseigne',
			addFacebook:			'Ajouter vos établissements avec Facebook',
			addGoogle:				'Ajouter vos établissements avec Google',
			cancel:					'Annuler',
			back:					'Retour'
		},

		connectAccountV2:
		{
			// Global
			title:					'Ajouter un établissement',
			myNetwork:				'Mon réseau',
			newNetwork:				'Ajouter un établissement',
			step: {
				1:					'Choix de la plateforme sociale',
				2:					'Sélection des comptes à ajouter',
				3:					'Importation des données'
			},
			// Step 1
			step1Title:				'Plateforme sociale',
			step1Subtitle:			'Pour constituer votre réseau et gérer facilement tous vos comptes, ajoutez les plateformes sociales sur lesquelles vous êtes présents.',
			addPage:				'Ajouter une page',
			addProfil:				'Ajouter un profil',
			networkInfoPart1:		'Se connecter à mon compte et accepter les autorisations',
			networkInfoPart2:		'pour que Shoki puisse centraliser ici toutes vos données.',
			// Step 2
			pages:					'Vos pages',
			account:				'Vos profils',
			yourPage:				'Voici les pages rattachées à votre compte',
			selectOnePage:			'Sélectionnez celle que vous voulez gérer depuis votre interface Shoki.',
			selectPage:				'Sélectionnez celles que vous voulez gérer depuis votre interface Shoki.',
			selectAll:				'Tout sélectionner',
			connect:				'Vous aller connecter',
			page:					'page',
			next:					'Suivant',
			// Step 3
			getData:				'Nous importons vos données.',
			canTakeFewMinutes:		'Cela peut prendre quelques minutes'
		},

		publish: {
			error: {
				messageEmpty:		'Le message est vide',
				pageIdEmpty:		'Aucune page selectionné',
				videoNotAllow:		'Les vidéos ne sont pas disponible sur Google'
			},
			step: {
				1:					'Rédigez votre publication, et choisissez vos réseaux de diffusion'
			}
		},

		Publish: {
			newPublish:				'Nouvelle publication',
			writeYourPublish:		'Rédigez votre publication, et choisissez vos réseaux de diffusion',
			letsGo:					'C\'est parti !',
			placeHolderDate:		'Définir une date',
			allNetwork:				'Tout mes réseaux',
			lastname:				'Prénom',
			firstname:				'Nom',
			now:					'Maintenant',
			previewDescription:		'Sélectionnez les réseaux sur lesquels publier et rédigez votre message pour découvrir comment il s’affichera.',
			publishNow:				'Publier maintenant',
			publishDate:			'Programmer le post pour le',
			at:						'à',
			save:					'Enregistrer en brouillon',
			publish:				'Publier',
			addFile:				'Ajouter une photo ou vidéo',
			title: {
				network:			'Réseaux de diffusion',
				message:			'Message',
				preview:			'Aperçu',
				date:				'Date de publication',
				messageSend:		'Votre message est envoyé !'
			},
			error: {
				error:				'Des erreurs sont présentes',
				noDate:				'Erreur dans la date de publication',
				dateShort:			'La programmation doit se faire dans un minimum de 20 minutes'
			},
			youPublish:				'Vous venez de poster sur',
			yourNetwork:			'de vos réseaux.',
			finish:					'Terminer'
		},

		dashboard:
		{
			title:					'Accueil',
			period:					'Période',
			place:					'Établissement',
			facebookEngagement:		'J\'aime',
			facebookRating:			'Note',
			twitterEngagement:		'Followers',
			googleRating:			'Note',
			addNewPlace:			'Ajouter un établissement',
			modalTitle:				'Bienvenue dans votre espace shoki !',
			modalSubtitle:			'Votre nouveau compagnon digital, pour une expérience client à la hauteur de vos services.',
			modalButton:			'C\'est parti !',
			modalItemTitle:
			{
				1:					'Répondre à votre communauté',
				2:					'S’informer sur ce qu’on dit de vous',
				3:					'Diffuser du contenus',
				4:					'Des analyses poussées'
			},
			modalItemText:
			{
				1:					'Dialoguez avec votre communauté, toute plateforme sociale confondue',
				2:					'Garder l’oreille tendue sur ce que l’on dit de vous sur internet',
				3:					'Programmez des posts sur les plateformes de vos choix',
				4:					'Consultez vos performances et idetifiez vos clients et prospects'
			}
		},

		conversationWrapper: {
			communication:			'Conversation',
			menu: {
				reviews:			'Avis clients',
				comments:			'Commentaires clients',
				posts:				'Posts clients'
			}
		},

		commentsV2:
		{
			title:					'Les commentaires sur vos réseaux',
			titleReview:			'Les notes sur vos réseaux',
			titlePost:				'Les posts sur vos réseaux',
			titlePublish:			'Vos publications sur vos réseaux',
			writeAnswer:			'Rédigez votre réponse',
			answer:					'Répondre',
			seeAnswer:				'Voir la réponse',
			filter:					'Filtres',
			showMore:				'voir la suite...',
			showLess:				'réduire',
			showOriginPost:			'Afficher la publication d\'origine',
			showComments:			'Afficher les commentaires',
			hideComments:			'Masquer les commentaires',
			closeOriginPost:		'Fermer la publication d\'origine',
			hide:					'Masquer',
			confirm:				'Valider',
			allPlace:				'Tous les établissements',
			placeholderPlace:		'Sélectionnez les réseaux sociaux sur lesquels publier',
			filterList:
			{
				possitive:			'Positif',
				negative:			'Négatif',
				facebook:			'Facebook',
				twitter:			'Twitter',
				google:				'Google',
				noAnswer:			'Non traité',
				answer:				'Traité',
				experience:			'Experience',
				place:				'Accueil',
				price:				'Prix',
				personnel:			'Service',
				noKeyword:			'Sans mots-clés'
			},
			filterTitle:
			{
				platform:			'Plateformes',
				state:				'État',
				keyword:			'Mots-clés',
				rating:				'Note'
			}
		},

		posts:
		{
			newPosts:				'Nouveau post',
			writeHere:				'Écrivez votre post...',
			continu:				'Envoyer',
			search:					'Rechercher une enseigne...',
			selectAll:				'Sélectionner tout',
			showPreview:			'voir l\'aperçu',
			allTitle:				'Statistiques globales',
			editTitle:				'Modifier la planification',
			editTitleLoading:		'Modification en cours...',
			editPlanif:				'Modifier votre publication',
			editSuccess:			'La publication a été modifié avec succès !',
			editError:				'Erreur lors de la modification',
			deletePlanif:			'Annuler votre publication',
			deleteSuccess:			'La publication a été supprimé avec succès !',
			deleteError:			'Erreur lors de la suppression',
			error: {
				videoNotUpload:		'Veuillez attendre la fin de l\'upload de la vidéo',
				fileSize:			'La vidéo ne doit pas dépasser 200mb',
				noMessage:			'Le message est vide',
				noPlace:			'Veuillez sélectionner au moins 1 profil pour publier',
				lengthTwitter:		'Le post est trop long pour Twitter',
				lengthFacebook:		'Le post est trop long pour Facebook',
				videoNotTwitter:	'La publication de vidéo n\'est pas disponible sur Twitter pour le moment',
				tagsNotTwitter:		'Les tags ne sont pas disponibles sur Twitter',
				videoTooShort:		'La durée de la vidéo doit être au minimum d\'une seconde',
				videoTooLong:		'La durée de la vidéo doit être au maxium de 2h'
			}
		},

		placeData: {
			myNetwork:				'Mon réseau',
			place:					'Profil établissement',
			notEnable:				'La modification des informations de votre établissement sera bientôt disponible !',
			myAccount:				'Mes comptes',
			addAccount:				'Ajouter un compte',
			variableTitle:			'Variables attribuées à cet établissement',
			addVariable:			'Créer une variable',
			accountTitle:			'Utilisateurs ayant une vue sur cet établissement',
			menu: {
				network:			'Paramètres',
				about:				'Informations profil',
				contact:			'Contact',
				facebook:			'Facebook',
				twitter:			'Twitter',
				google:				'Google'
			}
		},

		postHistory: {
			error: {
				error:									'Erreur de publication sur',
				unknown:								'Erreur inconnue',

				'Invalid parameter':					'Invalid parameter',
				'Duplicate status message':				'Publication déjà existante sur la page',
				'Missing or invalid image file':		'Erreur avec votre image',
				'Unable to fetch video file from URL.':	'Erreur avec votre fichier vidéo.',
				'(#32) Page request limit reached':		'Trop de requêtes sur cette page',
				'There was a problem uploading your video file. Please try again with another file.':	'Erreur avec votre fichier vidéo.',
				'This status update is identical to the last one you posted. Try posting something different, or delete your previous update.': 'Publication déjà existante sur la page',
				'Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons.': 'Compte déconnecté'
			},
			menu: {
				calendar:			'Calendrier',
				list:				'Liste'
			},
			publish:				'Publier',
			allPublish:				'Toutes vos publications'
		},

		preview: {
			now:			'Maintenant',
			error: {
				facebook: {
					empty:		'Votre message est vide',
					length:		'Votre message est trop long pour Facebook',
					tagError:	'n\'est pas un tag valide'
				},
				twitter: {
					empty:		'Votre message est vide',
					length:		'Votre message est trop long pour Twitter',
					otherTag:	'Les tags Facebook ne sont pas compatibles avec Twitter'
				},
				google: {
					empty:		'Votre message est vide',
					videoFile:	'Vidéo non disponible sur Google',
					length:		'Votre message est trop long pour Google',
					otherTag:	'Les tags Facebook ne sont pas compatibles avec Google',
					gif:		'Vous ne pouvez pas publier de gif sur Google'
				}
			}
		},

		settings: {
			settings:			'Paramètres',
			title:				'Votre compte',

			firstname:			'Prénom',
			lastname:			'Nom',
			email:				'Email',
			password:			'Mot de passe',
			permissions:		'Gérer les permissions',
			newPassword:		'Nouveau mot de passe',
			currentPassword:	'Mot de passe actuel',
			place:				'Établissement à gérer',

			menu: {
				profile:		'Profil',
				team:			'Équipe',
				billing:		'Facturation'
			},
			containerName: {
				profile:		'Information de compte',
				team:			'Membres de l\'équipe',
				billing:		'Ma formule'
			}
		}
	},
	// --- English
	en:		// Need update
	{
		facebook:					'Facebook',
		google:						'Google',
		twitter:					'Twitter'
	}
};

export default Languages[actual];
