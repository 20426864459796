import	React, {PureComponent}			from	'react';
import	CheckEmoji					from	'./checkEmoji';

/****	renderMessage **********************************************
**	Render text input with color for # and @
*******************************************************************/
export default class	renderMessage extends PureComponent
{
	render = () =>
	{
		const	message = this.props.message;
		const	tags = this.props.tags;

		if (!message)
			return (null);

		const	split = message.split(/\n/);
		const	render = [];
		let		count = 0;
		let		end = 0;

		if (tags)
			tags.sort((a, b) => a.offset - b.offset);

		for (let i = 0; i < split.length; i += 1)
		{
			if (split[i] === '' && split[i + 1] === '')
				split.splice(i + 1, 1);
		}

		split.forEach((line, index) =>
		{
			const	renderLine = [];
			line = line.split(/ /);

			if (index > 0)
				render.push(<br key={`${this.props.id}br${index}`} />);

			line.forEach((each, i) =>
			{
				if (i > 0)
				{
					renderLine.push(<span key={`${this.props.id}word${i}space`}>{' '}</span>);
					count += 1;
				}

				while (each.startsWith(' '))
				{
					renderLine.push(<span key={`${this.props.id}word${i}2space${count}`}>&nbsp;</span>);
					each = each.substr(1);
					count += 1;
				}

				const	indexFind = tags ? tags.findIndex(e => e.offset === count) : -1;

				if (indexFind !== -1)
				{
					renderLine.push(<span key={`${this.props.id}word${i}`} style={{color: '#0088FE'}}>{each}</span>);
					end = tags[indexFind].offset + tags[indexFind].length;
				}
				else if (count <= end && end !== 0)
					renderLine.push(<span key={`${this.props.id}word${i}`} style={{color: '#0088FE'}}>{each}</span>);
				else if (each.indexOf('#') === 0 || each.indexOf('@') === 0)
					renderLine.push(<span key={`${this.props.id}word${i}`} style={{color: '#0088FE'}}>{each}</span>);
				else if (each.indexOf('http://') === 0 || each.indexOf('https://') === 0)
					renderLine.push(<a key={`${this.props.id}word${i}`} href={each} target={'blank'}>{each}</a>);
				else {
					const	check = CheckEmoji(each);
					count -= check.count;
					renderLine.push(<span key={`${this.props.id}word${i}`}>{check.data}</span>);
				}

				count += each.length;
			});
			render.push(renderLine);
		});

		return (<span>{render}</span>);
	}
}
