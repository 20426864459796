export const FORM_NAME = "PointOfSale";

export const COUNTRIES = [{ label: "France", value: "FRANCE" }];

export const PUBLISHER_STATE = {
  IS_SYNC: {
    label: "Synchronisés",
    icon: require("../../../ressources/images/PlacesView/icon_businessOk.svg"),
    corner: require("../../../ressources/images/PlacesView/cornerOk.svg")
  },
  IS_UNSYNC: {
    label: "Désynchronisés",
    icon: require("../../../ressources/images/PlacesView/icon_businessKo.svg"),
    corner: require("../../../ressources/images/PlacesView/cornerKo.svg")
  },
  NOT_CONNECTED: {
    label: "Non connectés",
    icon: require("../../../ressources/images/PlacesView/icon_businessNotConnected.svg"),
    corner: require("../../../ressources/images/PlacesView/cornernotConnected.svg")
  }
};

export const SPECIFIC_FIELD = [
  "businessDetails.businessName",
  "businessDetails.address",
  "businessDetails.addressAdditionalInfos",
  "businessDetails.zipCode",
  "businessDetails.city",
  "businessDetails.country",
  "publishersDescription.websiteUrl",
  "contact.phoneNumbers",
  "hours.businessHours",
  "hours.isTemporarilyClosed",
  "upload"
];

export const SPECIFIC_FIELD_SELECTED = [
  "publishersDescription.hasWebsiteUrl",
  "contact.hasPhone",
  "hours.hasMonday",
  "hours.hasTuesday",
  "hours.hasWednesday",
  "hours.hasThursday",
  "hours.hasFriday",
  "hours.hasSaturday",
  "hours.hasSunday",
  "hours.hasIsTemporarilyClosed",
  "media"
];
