import React from "react"
import icon_pen from "../../../ressources/images/PlacesEdits/icon_pen.svg";

export const MultiSelectorPanel = props => {
    const containerStyle = {
        height: 85,
        background: '#273138',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 250,
        paddingLeft: '6vw',
        paddingRight: '6vw'
    };
    const buttonStyle = {
        borderRadius: 5,
        fontFamily: 'Barlow',
        fontWeight: '600',
        fontSize: 15,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        padding: '10px 20px',
        height: '60%'
    };
    const numberStyle = {
        background: '#6D77DC',
        borderRadius: '4px',
        fontFamily: 'Barlow',
        fontWeight: '600',
        fontSize: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        width: 28,
        height: 28,
        marginRight: 12
    };
    const selectedTextStyle = {
        fontFamily: 'Barlow',
        fontWeight: '600',
        fontSize: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#FFFFFF'
    };

    return (
        <div style={containerStyle}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={numberStyle}>{props.numberOfBusinesses}</div>
                <div style={selectedTextStyle}>Établissements sélectionné(s)</div>
                <div
                    onClick={props.selectNone}
                    style={{paddingLeft: 12, color: 'rgba(255, 255, 255, 0.3)', cursor: 'pointer'}}>
                    {'Déséléctionner tout'}
                </div>
            </div>
            <button onClick={props.onButtonClick} disabled={props.numberOfBusinesses === 0} style={{...buttonStyle, background: props.numberOfBusinesses > 0 ? "#28B446" : "#c9cacc"}}>
                <img
                    src={icon_pen}
                    style={{marginRight: 12}}
                    alt={'Modifier les informations'} />
                {'Modifier les informations'}
            </button>
        </div>
    );
};