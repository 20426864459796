import React from "react";
import { PointOfSaleStatePublisher } from "./PointOfSaleStatePublisher";
import facebookLogo from "../../../ressources/images/logo/facebookRound.svg";
import gmbLogo from "../../../ressources/images/logo/googleRound.svg";
import styled from "styled-components";

const PointOfSaleStatePublisherWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 20px;
  }
`;

export const PointOfSalePublisherStats = ({ stats }) => (
  <PointOfSaleStatePublisherWrapper>
    <PointOfSaleStatePublisher
      publisher="Facebook"
      logo={facebookLogo}
      {...stats.facebook}
    />
    <PointOfSaleStatePublisher
      publisher="Google My Business"
      logo={gmbLogo}
      {...stats.gmb}
    />
  </PointOfSaleStatePublisherWrapper>
);
