import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { NewPost, Posts } from '../containers'

export const PostRoutes = ({ match }) => (
    <Switch>
        <Route exact path={[`${match.path}/new`, `${match.path}/:id/edit`]} component={NewPost} />
        <Route exact path={match.path} component={Posts} />
        <Redirect to={`${match.path}?status=published`} />
    </Switch>
);