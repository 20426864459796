import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LayoutRoute, RGPDLayout } from "../modules/layouts";
import { Login, PrivateRoutes } from "../modules/auth";
import { PointOfSaleRoutes } from "../modules/point-of-sale";
import { MultiEditPointOfSale } from "../modules/point-of-sale/containers";
import { PrivacyPolicies, TermsOfConditions } from "../modules/rgpd";
import { PostRoutes } from "../modules/post";

export const Routes = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <LayoutRoute
      path="/privacy-policies"
      title="Politique de confidentialité"
      layout={RGPDLayout}
      component={PrivacyPolicies}
    />
    <LayoutRoute
      path="/terms-of-conditions"
      title="Conditions générales de vente & d'utilisation"
      layout={RGPDLayout}
      component={TermsOfConditions}
    />
    <PrivateRoutes>
      <Switch>
        <Route path="/point-of-sales/edit" component={MultiEditPointOfSale} />
        <LayoutRoute path="/point-of-sales" component={PointOfSaleRoutes} />
        <LayoutRoute path="/posts" component={PostRoutes} />
        <Redirect to="/point-of-sales" />
      </Switch>
    </PrivateRoutes>
  </Switch>
);
