import React, { useState, useEffect } from "react"
import Select, { components, createFilter } from "react-select"
import { List } from "react-virtualized"
import TimeField from "react-simple-timefield";
import icon_addLine from "../../../ressources/images/PlacesView/icon_add_line.svg";
import icon_removeLine from "../../../ressources/images/PlacesView/icon_remove_line.svg";
import icon_mediaAdd from "../../../ressources/images/icon_media_add.svg";
import { Field, FieldArray } from "redux-form";
import cross from  "../../../ressources/images/cross.svg";
import { usePreviewImage, getCroppedImage, validFile, useToggle } from '../../../helpers'
import Cropper from 'react-easy-crop'
import { Dialog } from './Dialog'
import { useDropzone } from 'react-dropzone'
import Popup from '../../Popup'
import isEmpty from 'lodash/fp/isEmpty'

const DialogErrors = ({ errors, isErrorDialogOpen, toggleErrorDialog }) => (
    <Dialog title="Oops !" cancelLabel="" confirmLabel="Fermer" isOpen={isErrorDialogOpen} onClose={toggleErrorDialog} onConfirm={toggleErrorDialog}>
        <ul style={{margin: 0, padding: 0}}>
            {Object.entries(errors).map(([key, value]) => value && <li key={key} style={{listStyle: 'none', fontSize: 16}}>{value}</li>)}
        </ul>
    </Dialog>
) 

export const FieldWrapper = ({ title, label, description, isMandatory, meta, style, children }) => (
    <div className="networkDataFieldSelectorBox" style={{position: "relative", ...style}}>
        {title && <div className="networkDataFieldName">
            {title}
            {!label && isMandatory && <span>*</span>}
            {!label && <span>{meta.touched && meta.error}</span>}
        </div>}
        {label && <div className="networkDataFieldLabel" style={label && {marginTop: 20}}>
            {label}
            {isMandatory && <span>* {meta.touched && meta.error}</span>}
        </div>}
        {description && <div className="networkDataFieldDescription">
            {description}
        </div>}
        {children}
    </div>
);

export const renderCounterIcon = (icon, total, max) => (
    <div style={{display: "flex", alignItems: "center", position: "absolute", right: 0, marginTop: 8}}>
        {icon && <img src={icon} width={20} height={20} style={{marginRight: 8}}/>}
        <span>{max - total}</span>
    </div>
)

export const renderInput = ({ input, prefix, type, maxLength, ...props }) => (
    <FieldWrapper {...props}>
         <span className="networkDataInputContainer">
            {prefix && <div className="networkDataInputPrefix">{prefix}</div>}
             <input
                 {...input}
                 type={type || "text"}
                 className="networkDataInput"
                 maxLength={maxLength}
             />
        </span>
    </FieldWrapper>
);

export const renderTextArea = ({ input, placeholder, maxLength, counterIcon, counterEnable, ...props }) => (
    <FieldWrapper {...props}>
        <textarea
            style={{resize: "none"}}
            rows={4}
            className="networkDataInput"
            maxLength={maxLength}
            placeholder={placeholder}
            {...input} />
        {counterEnable && renderCounterIcon(counterIcon, input.value.length, maxLength)}
    </FieldWrapper>
);

export const renderCheckbox = ({ input, text, ...props }) => (
    <FieldWrapper {...props}>
        <label style={{display: "flex", alignItems: "center", marginTop: 6}}>
            <input {...input} type="checkbox" checked={input.value} />
            <span style={{color: "rgb(108, 108, 108)", paddingLeft: 12, cursor: "pointer", fontSize: 14}}>{text}</span>
        </label>
    </FieldWrapper>
)

export const renderRadioGroup = ({ input, options, ...props }) => (
    <FieldWrapper {...props}>
        <div style={{marginTop: 16}}>
            {options.map(({ label, value, afterComponent }) => (
                <div key={value} style={{display: "flex", alignItems: "center"}}>
                    <label style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                        <Field {...input} component="input" type="radio" value={value} />
                        <span style={{paddingLeft: 8, paddingRight: 8}}>{label}</span>
                    </label>
                    {afterComponent}
                </div>
            ))}
        </div>
    </FieldWrapper>
)

export const renderSelector = ({ input: { value, onChange, onBlur }, options, isMulti, isDisabled, max, placeholder, isLoading, isVirtual, ...props }) => {
    const noOptionsMessage = "Aucun résultat ne correspond à votre recherche"

    const singleChangeHandler = fn => newValue =>
        fn(newValue ? newValue.value : "");

    const multiChangeHandler = fn => newValues =>
        fn(newValues?.map(newValue => newValue.value));

    const transformValue = () => {
        if (isMulti && value instanceof String) return [];

        const filteredOptions = options.filter(option => (isMulti
            ? value.indexOf(option.value) !== -1
            : option.value === value));

        return isMulti ? filteredOptions : filteredOptions[0];
    };

    const Menu = props => (
        <components.Menu {...props}>
            {(props.getValue().length || 0) < props.selectProps.max ? (
                props.children
            ) : (
                <div style={{ margin: 10, textAlign: "center", color: "#acacac" }}>Limite maximale atteinte</div>
            )}
        </components.Menu>
    );

    const MenuList = ({ children }) => (
        <>
            {children.length ? (
                <List
                    style={{ width: '100%' }}
                    width={2000}
                    height={children.length > 10 ? 400 : children.length * 40}
                    rowHeight={40}
                    rowCount={children.length || 0}
                    rowRenderer={({ key, index, style }) =>
                        <div key={key} style={style}>{children[index]}</div>
                    }
                />
            ) : (
                <div style={{margin: 10, textAlign: "center", color: "#acacac"}}>{noOptionsMessage}</div>
            )}
        </>
    )

    return (
        <FieldWrapper {...props}>
            <Select
                valueKey="value"
                className="networkDataSelect"
                classNamePrefix="select"
                isClearable
                isSearchable
                components={isMulti && { Menu, ...(isVirtual && { MenuList })}}
                max={max}
                placeholder={placeholder || props.title}
                filterOption={createFilter({ ignoreCase: true, ignoreAccents: true })}
                isLoading={isLoading}
                isMulti={isMulti}
                isDisabled={isDisabled}
                onChange={isMulti ? multiChangeHandler(onChange) : singleChangeHandler(onChange)}
                onBlur={() => onBlur(value)}
                options={options}
                value={transformValue()}
                closeMenuOnSelect={!isMulti}
                noOptionsMessage={() => noOptionsMessage}
            />
        </FieldWrapper>
    );
};

export const renderTime = props => (
    <TimeField
        className="networkDataTimePicker"
        onChange={(e, value) => props.input.onChange(value)}
        value={props.input.value}
    />
);

export const renderHoursChecker = ({ fields, day, ...props }) => (
    <FieldWrapper {...props}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{display: "flex"}}>
                {day &&
                    <div style={{width: 120, height: 47, display: "flex", alignItems: "center"}}>
                        <label style={{display: "flex", alignItems: "center", marginBottom: 0}}>
                            <input type="checkbox" checked={fields.length > 0} onChange={e => e.target.checked ? fields.push({
                                from: '00:00',
                                to: '00:00'
                            }) : fields.removeAll()} />
                            <span style={{paddingLeft: 12, fontSize: 14, cursor: "pointer"}}>{day}</span>
                        </label>
                    </div>
                }
                <div>
                    {fields.map((hours, index) => (
                        <div key={index} style={{display: 'flex', alignItems: 'center', marginTop: index !== 0 ? 8 : 0}}>
                            <span style={{marginLeft: 10, marginRight: 10}}>de</span>
                            <Field name={`${hours}.from`} component={renderTime} />
                            <span style={{marginLeft: 10, marginRight: 10}}>à</span>
                            <Field name={`${hours}.to`} component={renderTime} />
                            {fields.length > 1 && <img style={{cursor: "pointer", marginLeft: 10}} src={cross} alt="remove" onClick={() => fields.remove(index)}/>}
                            {index === fields.length - 1 && <div style={{cursor: "pointer"}} className="networkDataTimePickerAdd" onClick={() => {
                                fields.push({
                                    from: '00:00',
                                    to: '00:00'
                                })
                            }}>
                                <img src={icon_addLine} alt="add" />
                                Ajouter une autre plage horaire
                            </div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
        {(props.meta.touched || props.meta.submitFailed) && props.meta.error && <div className="networkDataFieldLabel">
            <span>{props.meta.error}</span>
        </div>}
    </FieldWrapper>
);

export const renderDaysChecker = ({ fields, addLabel, ...props }) => (
    <FieldWrapper {...props}>
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <div className="networkDataChecker">
                <div>
                    {fields.map((day, index) => (
                        <div key={index} className="networkDataChecker">
                            <img src={icon_removeLine} alt="remove" width="20" height="20" onClick={() => fields.remove(index)} />
                            <Field name={`${day}.date`} component={renderInput} type="date" />
                            <FieldArray name={`${day}.timeSlots`} component={renderHoursChecker} />
                        </div>
                    ))}
                </div>
            </div>
            {props.meta.submitFailed && <div className="networkDataFieldLabel">
                <span>{props.meta.error}</span>
            </div>}
            <div className="networkDataTimePickerAdd" style={{marginLeft: 0}} onClick={() => fields.push({
                date: "",
                timeSlots: [{
                    from: '00:00',
                    to: '00:00'
                }]
            })}>
                <img src={icon_addLine} alt="add" />
                {addLabel}
            </div>
        </div>
    </FieldWrapper>
);

export const RenderFileInput = ({ input, defaultValue, accept, shape, rules, isCropable, onChange, ...props }) => {
    const [errors, setErrors] = useState({})
    const [isErrorDialogOpen, toggleErrorDialog] = useToggle()
    const preview = usePreviewImage(input.value)

    const handleFileChange = async e => {
        const file = e.target.files[0]
        const errors = await validFile(file, rules)
        if (isEmpty(errors)) {
            isCropable ? onChange(file) : input.onChange(file)
        } else {
            setErrors(errors)
            toggleErrorDialog()
        }
    }

    return (
        <>
            <FieldWrapper {...props}>
                {(preview || defaultValue) && ( 
                    <img 
                        style={{marginTop: 10, marginBottom: 10, borderRadius: shape === "round" ? "50%" : 0, height: 300}} 
                        src={preview || defaultValue} alt=""
                    />
                )}
                <div style={{position: "relative"}}>
                    <button className={`networkDataButton ${preview || defaultValue ? "stroke" : ""}`}>
                        {!preview && !defaultValue ? "Ajouter une image" : "Changer d'image"}
                    </button>
                    <input
                        style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'red', opacity: 0, cursor: 'pointer'}}
                        type="file"
                        accept={accept}
                        onChange={handleFileChange}
                    />
                </div>
            </FieldWrapper>
            <DialogErrors errors={errors} isErrorDialogOpen={isErrorDialogOpen} toggleErrorDialog={toggleErrorDialog} />
        </>
    );
};

export const RenderCropFile = ({ cropShape = 'square', cropAspect,...props }) => {
    const [cropImage, setCropImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [isCropDialogOpen, toggleCropDialog] = useToggle()

    const cropPreview = usePreviewImage(cropImage)

    const handleChange = file => {
        setCropImage(file)
        toggleCropDialog()
    }

    const handleDialogCancel = () => {
        setCrop({ x: 0, y: 0 })
        setZoom(1)
        setCroppedAreaPixels(null)
        toggleCropDialog()
    }

    const handleDialogConfirm = async () => {
        props.input.onChange(await getCroppedImage(cropPreview, croppedAreaPixels))
        handleDialogCancel()
    }

    return (
        <>
            <RenderFileInput {...props} onChange={handleChange} isCropable />
            <Dialog title={props.title} isOpen={isCropDialogOpen} onClose={handleDialogCancel} onConfirm={handleDialogConfirm}>
                <div style={{position: "relative", margin: "-1.5rem", height: 420, marginBottom: 20, padding: 20}}>
                    <Cropper 
                        image={cropPreview}
                        cropShape={cropShape}
                        showGrid={false}
                        aspect={cropAspect}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
                        onZoomChange={setZoom}
                    />
                </div>
                <input type="range" min="1" max="3" step="0.1" value={zoom} onChange={e => setZoom(e.target.value)} />
            </Dialog>
        </>
    )
}

export const RenderDropZone = ({ input, placeholder, accept, maxLength, counterIcon, rules, disable, ...props }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [error, setError] = useState("")

    useEffect(() => {
        if (error === "") return
        setIsPopupOpen(true)
    }, [error])

    const closePopup = () => {
        setIsPopupOpen(false)
        setError("")
    }

    const validSize = (size, maxSize) =>
        size <= maxSize

    const validResolution = (width, height, minWidth, minHeight) =>
        width >= minWidth && height >= minHeight

    const validDuration = (duration, maxDuration) =>
        duration <= maxDuration

    const handleChange = (mimeType, data) =>
        input.onChange([...input.value, {
            mimeType,
            data
        }])

    const onDrop = acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader()
            reader.onload = e => {
                const mimeType = file.type.split('/')[0]
                const size = file.size / 1024 / 1024
                switch (mimeType) {
                    case "image":
                        if (file.type !== "image/gif") {
                            if (!rules.image.maxWeight || validSize(size, rules.image.maxWeight)) {
                                const image = new Image()
                                image.onload = () => {
                                    const { width, height } = image
                                    const [minWidth, minHeight] = rules.image.minResolution
                                    if (validResolution(width, height, minWidth, minHeight)) {
                                        handleChange(file.type, btoa(e.target.result))
                                    } else {
                                        setError(`La résolution de l'image doit être au minimum de ${minWidth}x${minHeight}`)
                                    }
                                }
                                image.src = e.target.result
                            } else {
                                setError(`La taille de l'image ne doit pas dépasser ${rules.image.maxWeight} Mo`)
                            }
                        } else {

                        }
                        break
                    case "video":
                        const { maxWeight, maxDuration, minResolution, maxUpload } = rules.video
                        if (input.value.filter(({ mimeType }) => mimeType.startsWith("video")).length >= maxUpload) {
                            setError(`Vous ne pouvez ajouter que ${maxUpload} vidéo(s)`)
                            break
                        }
                        if (!maxWeight || validSize(size, maxWeight)) {
                            const video = document.createElement('video')
                            video.onloadedmetadata = () => {
                                const { duration, videoWidth, videoHeight } = video
                                if (validDuration(duration, maxDuration)) {
                                    const [minWidth, minHeight] = minResolution
                                    if (validResolution(videoWidth, videoHeight, minWidth, minHeight)) {
                                        handleChange(file.type, btoa(e.target.result))
                                    } else {
                                        setError(`La résolution de la vidéo doit être au minimum de ${minWidth}x${minHeight}`)
                                    }
                                } else {
                                    setError(`La durée de la vidéo ne doit pas excéder ${maxDuration} secondes`)
                                }
                            }
                            video.src = e.target.result
                        } else {
                            setError(`La taille la video ne doit pas dépasser ${rules.video.maxWeight} Mo`)
                        }
                        break
                }
            }
            reader.readAsDataURL(file)
        })
    }

    const onClick = e => {
        if (disable) {
            setError(disable)
            e.stopPropagation()
        }
    }

    const onRemove = index =>
        input.onChange(input.value.filter((_, i) => i !== index))

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        onDrop
    })

    return (
        <FieldWrapper {...props}>
            <div style={{display: "flex", height: 158, overflowX: "auto", overflowY: "hidden", scrollBarWidth: "thin", background: '#fff', padding: 8}}>
                {<div {...getRootProps({ onClick })} style={{borderRadius: 16, minWidth: 142, maxWidth: 142, cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", border: "3px dashed #6d77dc"}}>
                    <input {...getInputProps()} />
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", color: "#6d77dc"}}>
                        <img src={icon_mediaAdd} alt="mediaAdd"/>
                        <span style={{marginTop: 10}}>{placeholder}</span>
                    </div>
                </div>}
                {Array.from(input.value).map(({ mimeType, data }, index) => (
                    <div key={index} style={{position: "relative", marginLeft: 12, background: "#b4acad", lineHeight: "142px", borderRadius: 16}}>
                        {mimeType.startsWith("image") && <img src={atob(data)} style={{width: 142}}/>}
                        {mimeType.startsWith("video") && <video src={atob(data)} style={{width: 142, height: 142}} />}
                        <button type="button" style={{position: "absolute", top: 12, right: 12, width: 32, height: 32, cursor: "pointer", zIndex: 10, background: "rgba(32,33,36,.6)", borderRadius: "50%", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center",  fontSize: "1.5rem"}} onClick={() => onRemove(index)}>
                            X
                        </button>
                    </div>
                ))}
            </div>
            {maxLength > 0 && renderCounterIcon(counterIcon, input.value.length, maxLength)}
            {isPopupOpen && <Popup
                title="Oups !"
                subtitle={error}
                onConfirm={closePopup}
                onClose={closePopup}
                confirmText="Ok"
            />}
        </FieldWrapper>
    )
}
