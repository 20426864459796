import React from "react";
import icon_simpleCheck from "../../../../ressources/images/PlacesEdits/icon_simpleCheck.svg";

const menuStyle = {
    height: 70,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: '#E9EBEF'
};
const tabStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '33%',
    fontFamily: 'Roboto',
    fontSize: 15
};
const tabNumber = {
    border: '1px solid #6D77DC',
    borderRadius: '50%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 15,
    color: '#6D77DC',
    marginRight: 16
};
const tabNumberOff = {
    border: '1px solid #9E9E9E',
    borderRadius: '50%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 15,
    color: '#9E9E9E',
    marginRight: 16
};
const tabIconOff = {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    marginRight: 16
};

export const MultiEditStepper = ({ currentStep }) => {
    const renderTabIcon = (shouldCurrent, value) => {
        if (currentStep <= shouldCurrent)
            return (<div style={currentStep === 1 ? tabNumber : tabNumberOff}>{value}</div>);
        else if (currentStep > shouldCurrent)
            return (<img src={icon_simpleCheck} style={tabIconOff} alt={'done'} />);
    };

    return (
        <div style={menuStyle}>
            <div style={{...tabStyle, background: currentStep === 1 ? '#F5F7FC' : '#E9EBEF'}}>
                {renderTabIcon(0, '1')}
                <p style={{margin: 0, color: currentStep === 1 ? '#6D77DC' : '#9D9D9D'}}>{'Séléction des données'}</p>
            </div>
            <div style={{...tabStyle, background: currentStep === 2 ? '#F5F7FC' : '#E9EBEF'}}>
                {renderTabIcon(1, '2')}
                <p style={{margin: 0, color: currentStep === 2 ? '#6D77DC' : '#9D9D9D'}}>{'Modification'}</p>
            </div>
            <div style={{...tabStyle, background: currentStep === 3 ? '#F5F7FC' : '#E9EBEF'}}>
                {renderTabIcon(2, '3')}
                <p style={{margin: 0, color: currentStep === 3 ? '#6D77DC' : '#9D9D9D'}}>{'Confirmation'}</p>
            </div>
        </div>
    );
}