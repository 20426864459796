import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { ToastContainer, Slide } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const App = () => (
    <>
        <ToastContainer 
            autoClose={3000} 
            hideProgressBar={true} 
            transition={Slide}
            position="top-center" 
        />
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    </>
);