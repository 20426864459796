import React from "react";
import { usePreviewImage } from "../../../../helpers";

const sectionHeaderStyle = {
  background: "#FFFFFF",
  borderRadius: 2,
  padding: "14px 39px",
  paddingBottom: 0,
  display: "flex",
  flexDirection: "row",
  alignItem: "center",
  fontFamily: "Roboto",
  fontWeight: "bold",
  fontSize: 14,
  color: "#000000",
  cursor: "pointer"
};
const sectionBodyStyle = {
  background: "#FFFFFF",
  borderBottomLeftRadius: 2,
  borderBottomRightRadius: 2,
  padding: "26px 39px",
  paddingTop: "10px",
  paddingBottom: "9px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between"
};
const warningMessageStyle = {
  fontFamily: "Roboto",
  fontSize: 14,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: "#B7544D",
  marginTop: 24
};
const businessNameStyle = {
  fontFamily: "Roboto",
  fontSize: 15,
  color: "#494949",
  padding: "8px 0px",
  width: "48%"
};

const hoursToString = hours => hours?.map(({ from, to }) => `${from}-${to}`);

const datetimeToString = datetime =>
  datetime?.map(
    ({ date, timeSlots }) =>
      `${new Date(date).toLocaleDateString()} ${hoursToString(timeSlots)}`
  );

export const MultiEditSummary = ({
  values: { selected, publishersDescription, contact, media, hours, upload },
  GMBCategories,
  FBCategories,
  pointOfSales
}) => {
  const logoPreview = usePreviewImage(upload?.logo);
  const coverPicturePreview = usePreviewImage(upload?.coverPicture);

  const renderChangeList = () => {
    return (
      <>
        <div style={sectionHeaderStyle}>
          Nouvelle(s) donnée(s) à mettre à jour
        </div>
        <div style={sectionBodyStyle}>
          {selected.publishersDescription &&
            Object.values(selected.publishersDescription).includes(true) && (
              <>
                {selected.publishersDescription.hasGmbMainCategory && (
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 12 }}
                  >
                    <div style={{ flex: 2 }}>
                      Catégorie principale Google My Business
                    </div>
                    <div style={{ flex: 4 }}>
                      {
                        GMBCategories.find(
                          cat =>
                            cat.value ===
                            publishersDescription.gmbMainCategory[0]
                        ).label
                      }
                    </div>
                  </div>
                )}
                {selected.publishersDescription.hasGmbAdditionalCategories && (
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 12 }}
                  >
                    <div style={{ flex: 2 }}>
                      Catégories supplémentaires Google My Business
                    </div>
                    <div style={{ flex: 4 }}>
                      {GMBCategories.filter(cat =>
                        publishersDescription.gmbAdditionalCategories.includes(
                          cat.value
                        )
                      )
                        .map(cat => cat.label)
                        .join(",")}
                    </div>
                  </div>
                )}
                {selected.publishersDescription.hasFbCategories && (
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 12 }}
                  >
                    <div style={{ flex: 2 }}>Catégories Facebook</div>
                    <div style={{ flex: 4 }}>
                      {FBCategories.filter(cat =>
                        publishersDescription.fbCategories.includes(cat.value)
                      )
                        .map(cat => cat.label)
                        .join(",")}
                    </div>
                  </div>
                )}
                {selected.publishersDescription.hasWebsiteUrl && (
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 12 }}
                  >
                    <div style={{ flex: 2 }}>Site internet</div>
                    <div style={{ flex: 4 }}>
                      https://{publishersDescription.websiteUrl}
                    </div>
                  </div>
                )}
                {selected.publishersDescription.hasGmbDescription && (
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 12 }}
                  >
                    <div style={{ flex: 2 }}>Description longue Google</div>
                    <div style={{ flex: 4 }}>
                      {publishersDescription.gmbDescription}
                    </div>
                  </div>
                )}
                {selected.publishersDescription.hasFbDescription && (
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 12 }}
                  >
                    <div style={{ flex: 2 }}>Description courte Facebook</div>
                    <div style={{ flex: 4 }}>
                      {publishersDescription.fbDescription}
                    </div>
                  </div>
                )}
              </>
            )}
          {selected.contact && Object.values(selected.contact).includes(true) && (
            <>
              {selected.contact.hasEmail && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>Adresse e-mail du propriétaire</div>
                  <div style={{ flex: 4 }}>{contact.email}</div>
                </div>
              )}
              {selected.contact.hasPhone && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>Téléphone(s)</div>
                  <div style={{ flex: 4 }}>
                    {contact.phoneNumbers.join(", ")}
                  </div>
                </div>
              )}
            </>
          )}
          {selected.media && Object.values(selected.media).includes(true) && (
            <>
              {selected.media.hasLogo && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>Logo</div>
                  <div style={{ flex: 4 }}>
                    <img width="200" src={logoPreview} alt="" />
                  </div>
                </div>
              )}
              {selected.media.hasCoverPicture && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>Photo de couverture</div>
                  <div style={{ flex: 4 }}>
                    <img width="200" src={coverPicturePreview} alt="" />
                  </div>
                </div>
              )}
            </>
          )}
          {selected.hours && Object.values(selected.hours).includes(true) && (
            <>
              {selected.hours.hasMonday && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>{"Lundi"}</div>
                  <div style={{ flex: 4 }}>
                    {hoursToString(hours?.businessHours?.monday)}
                  </div>
                </div>
              )}
              {selected.hours.hasTuesday && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>{"Mardi"}</div>
                  <div style={{ flex: 4 }}>
                    {hoursToString(hours?.businessHours?.tuesday)}
                  </div>
                </div>
              )}
              {selected.hours.hasWednesday && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>{"Mercredi"}</div>
                  <div style={{ flex: 4 }}>
                    {hoursToString(hours?.businessHours?.wednesday)}
                  </div>
                </div>
              )}
              {selected.hours.hasThursday && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>{"Jeudi"}</div>
                  <div style={{ flex: 4 }}>
                    {hoursToString(hours?.businessHours?.thursday)}
                  </div>
                </div>
              )}
              {selected.hours.hasFriday && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>{"Vendredi"}</div>
                  <div style={{ flex: 4 }}>
                    {hoursToString(hours?.businessHours?.friday)}
                  </div>
                </div>
              )}
              {selected.hours.hasSaturday && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>{"Samedi"}</div>
                  <div style={{ flex: 4 }}>
                    {hoursToString(hours?.businessHours?.saturday)}
                  </div>
                </div>
              )}
              {selected.hours.hasSunday && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>{"Dimanche"}</div>
                  <div style={{ flex: 4 }}>
                    {hoursToString(hours.businessHours?.sunday)}
                  </div>
                </div>
              )}
              {selected.hours.hasIsTemporarilyClosed && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>Fermeture temporaire</div>
                  <div style={{ flex: 4 }}>
                    {hours?.isTemporarilyClosed ? "Oui" : "Non"}
                  </div>
                </div>
              )}
              {selected.hours.hasExceptionalOpeningHours && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>
                    Horaires d'ouverture exceptionnels
                  </div>
                  <div style={{ flex: 4 }}>
                    {datetimeToString(hours?.exceptionalOpeningHours)}
                  </div>
                </div>
              )}
              {selected.hours.hasExceptionalClosingTimes && (
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 12 }}
                >
                  <div style={{ flex: 2 }}>
                    Horaires de fermeture exceptionnels
                  </div>
                  <div style={{ flex: 4 }}>
                    {datetimeToString(hours?.exceptionalClosingTimes)}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div style={sectionHeaderStyle}>
          {pointOfSales.length} établissement sélectionné(s)
        </div>
        <div style={sectionBodyStyle}>
          {pointOfSales.map((bp, index) => (
            <div key={index} style={businessNameStyle}>
              {bp.businessProfile.name}
            </div>
          ))}
        </div>
      </div>
      {renderChangeList()}
      <p style={warningMessageStyle}>
        Attention, cette modification <strong>&nbsp;impactera&nbsp;</strong>{" "}
        toutes les fiches des établissements séléctionnées sur les plateformes
        de Presence Management.
      </p>
    </>
  );
};
