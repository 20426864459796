import React from 'react';
import { render } from 'react-dom';
import { Provider } from "react-redux"
import { store } from "./store"

import { App } from './app';
import "./App.css"

import "moment/locale/fr"

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
