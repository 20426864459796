export const CREATE_POINT_OF_SALE = "shoki:create_point_of_sale";
export const UPDATE_POINT_OF_SALE = "shoki:update_point_of_sale";
export const UPDATE_POINT_OF_SALE_SUCCESS =
  "shoki:update_point_of_sale_success";
export const UPDATE_POINT_OF_SALE_FAILURE =
  "shoki:update_point_of_sale_failure";
export const FETCH_LIST_POINT_OF_SALE_REQUEST =
  "shoki:fetch_list_point_of_sale_request";
export const FETCH_LIST_POINT_OF_SALE_SUCCESS =
  "shoki:fetch_list_point_of_sale_success";
export const FETCH_POINT_OF_SALE_REQUEST = "shoki:fetch_point_of_sale_request";
export const FETCH_POINT_OF_SALE_SUCCESS = "shoki:fetch_point_of_sale_success";
export const RESET_LIST_POINT_OF_SALE = "shoki:reset_list_point_of_sale";
export const RESET_POINT_OF_SALE = "shoki:reset_point_of_sale";
export const UPDATE_POINT_OF_SALES = "shoki:update_point_of_sales";
export const UPDATE_POINT_OF_SALES_SUCCESS =
  "shoki:update_point_of_sales_success";
export const UPDATE_POINT_OF_SALES_FAILURE =
  "shoki:update_point_of_sales_failure";
export const UPDATE_SENSITIVE_FIELDS_REQUEST =
  "shoki:update_sensitive_fields_request";
export const UPDATE_SENSITIVE_FIELDS_SUCCESS =
  "shoki:update_sensitive_fields_success";
export const UPLOAD_MEDIAS_POINT_OF_SALE_REQUEST =
  "shoki:upload_medias_point_of_sale_request";
export const UPLOAD_MEDIAS_POINT_OF_SALE_SUCCESS =
  "shoki:upload_medias_point_of_sale_success";
export const UPLOAD_MEDIAS_POINT_OF_SALES_REQUEST =
  "shoki:upload_medias_point_of_sales_request";
export const FETCH_CATEGORIES_REQUEST = "shoki:fetch_categories_request";
export const FETCH_CATEGORIES_SUCCESS = "shoki:fetch_categories_success";
export const FETCH_POINT_OF_SALE_STATS_REQUEST =
  "shoki:fetch_point_of_sale_stats_request";
export const FETCH_POINT_OF_SALE_STATS_SUCCESS =
  "shoki:fetch_point_of_sale_stats_success";
export const BULK_UPDATE_SENSITIVE_FIELDS_REQUEST =
  "shoki:bulk_update_sensitive_fields_request";
export const BULK_UPDATE_SENSITIVE_FIELDS_FAILURE =
  "shoki:bulk_update_sensitive_fields_failure";
export const UPDATE_END = "shoki:update_end";
export const RESET_BULK_UPDATE = "shoki:reset_bulk_update";
