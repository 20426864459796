/*******************************************************************************
** @Author:					Benjamin Tollet <Btollet>
** @Email:					benjamin@shoki.io
** @Date:					Friday 24 May 2019 - 11:22:47
** @Filename:				checkEmoji.js
**
** @Last modified by:		Tbouder
** @Last modified time:		Monday 08 July 2019 - 17:30:48
*******************************************************************************/
import	React							from	'react';
import {Emoji}							from	'emoji-mart';
import EmojiDictionary					from	'emoji-dictionary';

/******************************************************************************
*    !checkEmoji
*	 On regarde les emoji présent dans le texte grace à une regex et on utilise
*	 les module "emoji-dictionary" et "emoji-mart" pour les réafficher si possible
*	 permet d'évité un maximum (pas totalement) le problème d'emoji qui ne
*	 s'affiche pas pour certain ordinateurs/navigateurs
******************************************************************************/
const	checkEmoji = (comment) => {
	const	regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
	let		count = 0;

	if (regex.test(comment)) {
		const	split = comment.split(regex);
		const	match = comment.match(regex);
		const	result = [];

		for (let i = 0; i < split.length; i += 1) {
			result.push(<span key={`emoji${i}`}>{split[i]}</span>);
			if (match[i]) {
				count += match[i].length - 1;
				const name = EmojiDictionary.getName(match[i]);
				if (name)
				{
					result.push(
						<Emoji
							size={16}
							emoji={name}
							key={`emoji${i}-1`}
							fallback={emoji => (emoji ? `:${emoji.short_names[0]}:` : match[i])} />
					);
				}
				else
					result.push(<span key={`emoji${i}-2`}>{match[i]}</span>);
			}
		}
		comment = result;
	} else
		comment = <span>{comment}</span>;
	return ({data: comment, count});
};

export default checkEmoji;
