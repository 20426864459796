import React from "react";
import { PUBLISHER_STATE } from "../constants";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PublisherWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px 20px 0;
`;

export const PointOfSaleStatePublisher = ({
  publisher,
  logo,
  sync,
  unsync,
  disconnected,
  count
}) => (
  <Container>
    <h4 style={{ marginBottom: 10, fontSize: 16 }}>{publisher}</h4>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <PublisherWrapper>
        <img src={logo} alt={publisher} width="42" />
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly"
          }}
        >
          <h5 className="dashboardBusinessesValue">
            {sync}
            <span>/{count}</span>
          </h5>
          <div className="dashboardBusinessesStatusText">
            <img
              src={PUBLISHER_STATE.IS_SYNC.icon}
              alt=""
              style={{ marginRight: 6 }}
            />
            {PUBLISHER_STATE.IS_SYNC.label}
          </div>
        </div>
      </PublisherWrapper>
      <PublisherWrapper>
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly"
          }}
        >
          <h5 className="dashboardBusinessesValue">
            {unsync}
            <span>/{count}</span>
          </h5>
          <div className="dashboardBusinessesStatusText">
            <img
              src={PUBLISHER_STATE.IS_UNSYNC.icon}
              alt=""
              style={{ marginRight: 6 }}
            />
            {PUBLISHER_STATE.IS_UNSYNC.label}
          </div>
        </div>
      </PublisherWrapper>
      <PublisherWrapper>
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly"
          }}
        >
          <h5 className="dashboardBusinessesValue">
            {disconnected}
            <span>/{count}</span>
          </h5>
          <div className="dashboardBusinessesStatusText">
            <img
              src={PUBLISHER_STATE.NOT_CONNECTED.icon}
              alt=""
              style={{ marginRight: 6 }}
            />
            {PUBLISHER_STATE.NOT_CONNECTED.label}
          </div>
        </div>
      </PublisherWrapper>
    </div>
  </Container>
);
