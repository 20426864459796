import { authenticationInstance } from "../../../api";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import { setTokenToLocalStorage } from "../helpers";

export const authentication = (credentials) =>
  authenticationInstance.post("/v2/auth", credentials);

export const refreshTokens = async () => {
  const { data: data } = await authenticationInstance.post("/v2/tokens", {
    token: localStorage.getItem(REFRESH_TOKEN),
  });
  setTokenToLocalStorage(data);
};

export const whoAmI = () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  };
  return authenticationInstance.get("/users/me", { headers });
};
