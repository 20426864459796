import { PointOfSaleCreateSagas } from "./createSaga";
import { PointOfSaleFetchSagas } from "./fetchSaga";
import { PointOfSaleMediaSagas } from "./mediaSaga";
import { PointOfSaleUpdateSagas } from "./updateSaga";
import { PointOfSaleBulkUpdateSagas } from "./bulkUpdateSaga";

export const PointOfSaleSagas = [
  ...PointOfSaleCreateSagas,
  ...PointOfSaleFetchSagas,
  ...PointOfSaleMediaSagas,
  ...PointOfSaleUpdateSagas,
  ...PointOfSaleBulkUpdateSagas
];
