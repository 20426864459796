import React, { useRef } from "react";
import { useOutsideClick, useToggle } from '../../../helpers'
import LOGO from '../../../ressources/functions/logo';
import warningIcon from '../../../ressources/images/warningPrimary.svg';
import Message from '../../../ressources/functions/renderMessage';
import moment from 'moment'
import { Link } from "react-router-dom"
import styled from 'styled-components'
import { Dialog } from '../../commons/components'

const MediasWrapper = styled.div`
    display: flex;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    padding-bottom: 5px;
    margin-bottom: 10px;
    img {
        min-width: 100px;
        max-width: 100px;
        height: 100px;
        margin-right: 15px;
        border-radius: 10px;
    }
`

export const PostListItem = ({ id, publisher, businessName, medias, description, createdAt, isMulti, onDeletePost }) => {
    const [isActionMenuOpen, toggleMenu] = useToggle()
    const [isDeleteDialogOpen, toggleDeleteDialog] = useToggle()

    const actionMenu = useRef()
    useOutsideClick(actionMenu, toggleMenu)

    const handleDelete = () => {
        onDeletePost(id)
        toggleDeleteDialog()
    }

    const renderActionMenu = () => (
        <div ref={actionMenu} className="popupPlanif absolute">
            <Link style={{color: "inherit", display: "block", opacity: .2}} className="popupText" to={`/posts/${id}/edit`}>
                Modifier la publication
            </Link>
            <div className="popupText" onClick={toggleDeleteDialog}>
                Supprimer la publication
            </div>
        </div>
    )

    return (
        <div className="containerPostMain">
            <div className="containerPost">
                <div className="topBoxContainer">
                    <img src={LOGO.Round[publisher]} className="iconComments" alt={publisher} width="35" height="35" />
                    <div className="nameContainerPost">{businessName}</div>
                    <div className="arrowBox absolute">
                        <div className="selectArrow" onClick={() => toggleMenu()} />
                    </div>
                    {isActionMenuOpen && renderActionMenu()}
                </div>
                <div className="containerMessage textContainerPost">
                    <MediasWrapper>
                        {medias.map((url, index) => <img key={`p${id}-m${index}`} src={url} alt="" />)}
                    </MediasWrapper>
                    <div className="containerMessageFlex">
                        <Message message={description} />
                        <p className="greyTextInfo">Le {moment(createdAt).format("DD/MM/YYYY à hh:mm")}</p>
                    </div>
                </div>
                {isMulti && <div className="showOriginTextComments">
                    <div className="showOriginTextCommentsPointer">Multipublication</div>
                </div>}
            </div>
            <div className="mainContainerBottomPost">
                <div className="containerBottomForBorder containerBottomBorder" />
                <div className="containerBottomPost">
                    <div className="containerInfo">
                        <div className="bottomRightContainer">
                            {publisher === "facebook" && <div className="infoContainer">
                                <img className="bottomSelectorIcon" src={require('../../../ressources/images/icon_vieux.svg')} alt="view" width="18" height="18" />
                                0 Vue(s)
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog title="Supprimer la publication" isOpen={isDeleteDialogOpen} onClose={toggleDeleteDialog} onConfirm={handleDelete} align="center" type="confirm">
                <img src={warningIcon} width="100" />
                <h4 style={{marginTop: 20}}>Êtes vous sûr de vouloir supprimer cette publication</h4>
            </Dialog>
        </div>
    );
}