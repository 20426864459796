import { call, put, takeEvery, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import { uploadMediasPointOfSale, uploadMediasPointOfSales } from "../api";
import {
  UPLOAD_MEDIAS_POINT_OF_SALE_REQUEST,
  UPLOAD_MEDIAS_POINT_OF_SALES_REQUEST
} from "../constants";
import { uploadMediasPointOfSaleSuccess } from "../actions";

function* uploadMediasPointOfSaleWorker({ id, values }) {
  const payload = new FormData();
  if (values.logo) payload.append("logo", values.logo);
  if (values.coverPicture) payload.append("coverPicture", values.coverPicture);
  try {
    const { data } = yield call(uploadMediasPointOfSale, id, payload);
    yield put(uploadMediasPointOfSaleSuccess(data));
  } catch (e) {
    toast.warn("Les photos n'ont pas été téléchargé vers le serveur");
    console.error(e);
  }
}

function* watchUploadMediasPointOfSale() {
  yield takeEvery(
    UPLOAD_MEDIAS_POINT_OF_SALE_REQUEST,
    uploadMediasPointOfSaleWorker
  );
}

function* uploadMediasPointOfSalesWorker({ ids, values }) {
  const payload = new FormData();
  if (values.logo) payload.append("logo", values.logo);
  if (values.coverPicture) payload.append("coverPicture", values.coverPicture);
  payload.set("ids", ids.join(" "));

  try {
    yield call(uploadMediasPointOfSales, payload);
  } catch (e) {
    toast.warn("Les photos n'ont pas été téléchargé vers le serveur");
    console.error(e);
  }
}

function* watchUploadMediasPointOfSales() {
  yield takeEvery(
    UPLOAD_MEDIAS_POINT_OF_SALES_REQUEST,
    uploadMediasPointOfSalesWorker
  );
}

export const PointOfSaleMediaSagas = [
  fork(watchUploadMediasPointOfSale),
  fork(watchUploadMediasPointOfSales)
];
