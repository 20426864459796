import React from "react"
import LANGUAGES from '../../../../ressources/Languages'
import Message from '../../../../ressources/functions/renderMessage'
import comment from '../../../../ressources/images/commentTwitter.svg'
import retweet from '../../../../ressources/images/retweetTwitter.svg'
import like from '../../../../ressources/images/likeTwitter.svg'
import { TWITTER_PREVIEW_GRID_TEMPLATE_AREAS } from '../../constants'

export const PreviewTwitter = ({ description, businessName, logo, medias }) => {
    const renderIcon = (icon) => (
        <div>
            <img src={icon} alt={'icon'} />
            <div>0</div>
        </div>
    )

    return (
        <div className={'previewTwitterContainer'}>
            <div className={'previewTwitterMain'}>
                <div>
                    <img src={atob(logo?.data || "")} className={'previewTwitterLogo'} alt={businessName} width={30} height={30} />
                </div>
                <div className={'previewContent'}>
					<span className={'previewTwitterTopName'}>
                        {businessName}
                        <span className={'previewTwitterTopDate'}>{` @ · ${LANGUAGES.preview.now}`}</span>
					</span><br />
                    <div className={'previewTwitterText'} style={{width: 500}}>
                        <Message message={description} />
                    </div>
                    <div style={{
                        display: 'grid',
                        gridGap: 2,
                        gridTemplateAreas: TWITTER_PREVIEW_GRID_TEMPLATE_AREAS[medias.length],
                        overflow: "hidden",
                        width: 500
                    }}>
                    {medias.map(({ mimeType, data }, index) => (
                        <div key={index} style={{gridArea: `m${index+1}`, overflow: "hidden", display: "flex", justifyContent: "center", border: "1px solid #E3E4E5"}}>
                            {mimeType.startsWith("image") && <img src={atob(data)} alt="" />}
                            {mimeType.startsWith("video") && <video src={atob(data)} controls />}
                        </div>
                    ))}
                    </div>
                    <div className={'previewTwitterBottomContainer'}>
                        {renderIcon(comment)}
                        {renderIcon(retweet)}
                        {renderIcon(like)}
                    </div>
                </div>
            </div>
        </div>
    )
}