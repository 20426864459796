import React from "react";
import { withRouter } from "react-router-dom";
import { Sidebar, Topbar } from "../components";
import { connect } from "react-redux";
import {logoutAction} from "../../../auth/actions";

const DefaultLayout = ({ children, logout }) => {
    return (
        <div className="mainReact">
            <Topbar logout={logout} />
            <Sidebar />
            <main className="mainBodyContainer">
                {children}
            </main>
        </div>
    );
}

const mapDispatchToProps = (dispatch, { history }) => ({
    logout: () => dispatch(logoutAction(history))
});

export const ConnectedDefaultLayout = withRouter(connect(
    null, mapDispatchToProps
)(DefaultLayout));