import { call, put, fork, takeLatest } from "redux-saga/effects";
import { WHO_AM_I, AUTHENTICATION_REQUEST, LOGOUT_REQUEST } from "../constants";
import { authentication, whoAmI } from "../api";
import {
  removeTokenFromLocalStorage,
  setTokenToLocalStorage
} from "../helpers";
import { userInfoAction } from "../storeChunk";
import { toast } from "react-toastify";

function* whoAmIWorker() {
  try {
    const { data } = yield call(whoAmI);
    yield put(userInfoAction.replace(data));
  } catch (e) {
    yield put(userInfoAction.reset());
  }
}

function* watchWhoAMI() {
  yield takeLatest(WHO_AM_I, whoAmIWorker);
}

function* authenticationWorker({ credentials, history }) {
  toast.dismiss();
  try {
    const { data } = yield call(authentication, credentials);
    setTokenToLocalStorage(data);
    history.push("/");
  } catch (e) {
    toast.error("Identifiant ou Mot de passe incorrect !");
  }
}

function* watchAuthenticationRequest() {
  yield takeLatest(AUTHENTICATION_REQUEST, authenticationWorker);
}

function* logoutWorker({ history }) {
  removeTokenFromLocalStorage();
  yield history.push("/");
}

function* watchLogout() {
  yield takeLatest(LOGOUT_REQUEST, logoutWorker);
}

export const AuthSagas = [
  fork(watchAuthenticationRequest),
  fork(watchLogout),
  fork(watchWhoAMI)
];
