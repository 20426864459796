import { FETCH_LIST_POST_REQUEST, FETCH_LIST_POST_SUCCESS, RESET_LIST_POST, SET_PUBLICATION_PERIOD_FILTER, SET_PUBLISHER_FITLTER, SET_POINT_OF_SALE_FILTER, RESET_FILTERS, FETCH_POST_SUCCESS, DELETE_POST_SUCCESS } from '../constants'
import { createSelector } from "reselect"
import groupBy from "lodash/fp/groupBy"
import moment from "moment"

const INITIAL_STATE = {
    posts: {
        data: [],
        pageCount: 0,
        fetched: false
    },
    post: {
        data: {},
        fetched: false
    },
    filters: {
        publicationPeriod: null,
        publishers: [],
        pointOfSales: []
    }
}

export const PostReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_LIST_POST_REQUEST:
            return { ...state, posts: { ...state.posts, fetched: false } }
        case FETCH_LIST_POST_SUCCESS:
            const { posts, pageCount } = action.data
            return { ...state, posts: { data: [...state.posts.data, ...posts], pageCount, fetched: true } }
        case FETCH_POST_SUCCESS:
            return { ...state, post: { ...state.post, data: action.data, fetched: true } }
        case DELETE_POST_SUCCESS:
            return { ...state, posts: { ...state.posts, data: state.posts.data.filter(post => post.id !== action.id) } }
        case RESET_LIST_POST:
            return { ...state, posts: INITIAL_STATE.posts }
        case SET_PUBLICATION_PERIOD_FILTER:
            return { ...state, filters: { ...state.filters, publicationPeriod: action.publicationPeriod } }
        case SET_PUBLISHER_FITLTER:
            return { ...state, filters: { ...state.filters, publishers: action.selected ? [...state.filters.publishers, action.publisher] : state.filters.publishers.filter(publisher => publisher !== action.publisher) } }
        case SET_POINT_OF_SALE_FILTER:
            return { ...state, filters: { ...state.filters, pointOfSales: action.selected ? [...state.filters.pointOfSales, action.pointOfSale] : state.filters.pointOfSales.filter(pointOfSale => pointOfSale.value !== action.pointOfSale) } }
        case RESET_FILTERS:
            return { ...state, filters: INITIAL_STATE.filters }
        default:
            return state
    }
}

export const selectPosts = ({ PostReducer }) => PostReducer.posts
export const selectPost = ({ PostReducer }) => PostReducer.post
export const selectFilters = ({ PostReducer }) => PostReducer.filters

export const selectPostsGroupByDate = createSelector(
    selectPosts,
    ({ data, ...rest }) => ({
        data: groupBy(({ createdAt }) => moment(createdAt).startOf("d"))(data),
        ...rest
    })
);
