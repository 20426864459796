import React from "react"
import head from '../../../../ressources/images/head.svg'
import LANGUAGES from '../../../../ressources/Languages'

export const PreviewDefault = () => (
    <div className="publishEmptyPreview">
        <div className="publishEmptyPreviewInner">
            <div className="publishEmptyPreviewTop">
                <div>
                    <img src={head} alt="preview" />
                </div>
                <div>
                    <span>{`${LANGUAGES.Publish.firstname} ${LANGUAGES.Publish.lastname}`}</span>
                    <span>{LANGUAGES.Publish.now}</span>
                </div>
            </div>
            <p>{LANGUAGES.Publish.previewDescription}</p>
        </div>
    </div>
)