import React from "react"
import icon_arrowRight from "../../../ressources/images/PlacesView/icon_arrow_right_white.svg";
import { REFRESH_TOKEN } from "../../auth/constants";
import { refreshTokens } from "../../auth"
import ContentLoader from "react-content-loader"
import { PUBLISHER_STATE } from "../constants"

const SOCIAL_NETWORKS = [
    {networkName: "google", description: "Connecter une fiche Google"},
    {networkName: "facebook", description: "Connecter une page Facebook"}
];

const NetworkLoader = () => (
    <ContentLoader 
        width="100%"
        height={75}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        viewBox="0 0 100% 75"
        style={{background: "#fff"}}
    >
        <circle cx="35" cy="35" r="15" /> 
        <rect x="70" y="18" rx="5" ry="5" width="80" height="10" /> 
        <rect x="70" y="43" rx="5" ry="5" width="110" height="10" />
        <circle cx="-100" cy="35" r="12" /> 
    </ContentLoader>
)

const NetworkItem = ({ pointOfSaleId, access, networkName, description }) => {
    const linkPublisher = async () => {
        if (access)
            return

        await refreshTokens()
        window.open(`https://connect.${process.env.REACT_APP_VISICONNECT_DOMAIN}/${networkName}-connect?businessID=${pointOfSaleId}&refreshToken=${localStorage.getItem(REFRESH_TOKEN)}&redirectUrl=${window.location.href}`)
    }

    return (
        <div style={{display: "flex", alignItems: "center", background: access ? "#fff" : "#6d77dc", cursor: access ? "" : "pointer", height: 75, padding: 25, marginBottom: 1}} onClick={linkPublisher}>
            {access ? (
                <>
                    {access.logo && <img src={access.logo} alt="" style={{width: 30, borderRadius: "50%"}}/>}
                    {!access.logo && <div style={{width: 30, height: 30, color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", background: "#8e54e9", fontWeight: "bold", textTransform: "capitalize"}}>{access.name[0]}</div>}
                    <div style={{marginLeft: 20}}>
                        <h4 style={{textTransform: "capitalize", fontWeight: "bold", fontSize: 16, marginTop: 5, marginBottom: 5}}>{networkName}</h4>
                        <span>{access.name}</span>
                    </div>
                    <img style={{marginLeft: 'auto'}} src={PUBLISHER_STATE[access.state]?.icon} alt=""/>
                </>
            ) : (
                <>
                    <span style={{color: '#FFFFFF', width: 37, height: 37, marginRight: 12, fontSize: 26, display: 'flex', justifyContent: 'center', alignContent: 'center'}}>{'+'}</span>
                    <span className="stickyNetworkEmtpyMessage">{description}</span>
                    <img style={{marginLeft: 'auto'}} src={icon_arrowRight} alt={`status ${networkName}`} />
                </>
            )}
        </div>
    )
}

export const SocialNetworks = ({ pointOfSaleId, publishersAccess, loading }) => (
    <div style={{position: "sticky", top: 20}}>
        <div className="networkDataFieldName">
            Liaison de mes fiches existantes
            <div style={{color: "#606060", marginTop: 8}}>Étape obligatoire pour mettre à jour vos données sur les réseaux</div>
        </div>
        <div style={{position: "relative", height: 152}}>
            {SOCIAL_NETWORKS.map(sn => 
                loading ? <NetworkLoader key={sn.networkName} /> : <NetworkItem key={sn.networkName} pointOfSaleId={pointOfSaleId} access={publishersAccess[sn.networkName]} {...sn} />
            )}
        </div>
    </div>
);