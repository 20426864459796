import React from "react"
import LANGUAGES from '../../../../ressources/Languages'
import share from '../../../../ressources/images/shareGoogle.svg'
import menu from '../../../../ressources/images/menuGoogle.svg'

export const PreviewGoogle = ({ description, businessName, logo, medias }) => {
    const renderHeader = () => (
        <div className={'flex'}>
            <div>
                <img src={logo} className={'previewGoogleLogo'} alt={businessName} width={30} height={30} />
            </div>
            <div className={'previewGoogleTopContainer'}>
                <span className={'previewGoogleTopName'}>{businessName}</span><br />
                <span className={'previewGoogleTopDate'}>{LANGUAGES.preview.now}</span>
            </div>

            <div className={'previewGoogleIcon'}>
                <div>
                    <img src={share} alt={'Share'} />
                    <img src={menu} alt={'Menu'} />
                </div>
            </div>
        </div>
    )

    return (
        <div className={'previewGoogleContainer'}>
            <div className={'previewGoogleMain'}>
                {renderHeader()}
                <div className={'previewAttachment'}>
                    {medias[0]?.mimeType.startsWith('image/') &&
                    <div className={'SoloImgContainer'}>
                        <img className={'imgPreview'} alt={'.'} src={atob(medias[0].data)} />
                    </div>
                    }
                    {medias[0]?.mimeType.startsWith('video/') &&
                    <video controls>
                        <source src={atob(medias[0].data)} />
                        Your browser does not support HTML5 video.
                    </video>
                    }
                </div>
                <div className={'previewGoogleText'}>
                    {description?.map((text, index) => <div key={index}>{text}</div>)}
                </div>
            </div>
        </div>
    )
}