import React from 'react'
import ContentLoader from 'react-content-loader'
import times from 'lodash/fp/times'

export const PostListItemLoader = () => (
    <ContentLoader 
        width="100%"
        height={250}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        viewBox="0 0 100% 75"
        style={{background: "#fff"}}
    >
        <circle cx="32" cy="32" r="17" /> 
        <rect x="62" y="27" rx="5" ry="5" width="140" height="10" /> 
        {times(n => <rect key={n} x={62 + 110 * n} y="62" rx="10" ry="10" width="100" height="100" />)(Math.random() * (5 - 1) + 1)}
        <rect x="62" y="182" rx="5" ry="5" width="340" height="10" /> 
        <rect x="62" y="207" rx="5" ry="5" width="220" height="10" /> 
    </ContentLoader>
)