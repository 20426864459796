import React from "react";
import { call, select, takeEvery, put, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import { omit, pick, isEqual } from "lodash/fp";
import { formatWebsiteUrl, fromJsonToState } from "../helpers";
import { selectPointOfSale } from "../reducers";
import {
  UPDATE_POINT_OF_SALE,
  UPDATE_SENSITIVE_FIELDS_REQUEST,
  SPECIFIC_FIELD,
  FORM_NAME
} from "../constants";
import {
  updatePointOfSaleName,
  updatePointOfSaleAddress,
  updatePointOfSaleWebsiteUrl,
  updatePointOfSalePhoneNumbers,
  updatePointOfSaleBusinessHours,
  updatePointOfSale,
  updatePointOfSaleIsTemporarilyClosed
} from "../api";
import {
  updatePointOfSaleSuccessAction,
  updatePointOfSaleFailure,
  updateSensitiveFieldsRequestAction,
  uploadMediasPointOfSaleRequest,
  updateSensitiveFieldsRequestSuccessAction,
  updateEndAction
} from "../actions";
import { reset } from "redux-form";

function* updateIsTemporarilyClosed(id, value) {
  try {
    const { data } = yield call(
      updatePointOfSaleIsTemporarilyClosed,
      id,
      value
    );
    yield put(updateSensitiveFieldsRequestSuccessAction(data));
  } catch (e) {
    toast.error(
      "Une erreur est survenue lors de la modification de la fermeture temporaire, veuillez réessayer plus tard"
    );
  }
}

function* updateSensitiveFieldsWorker({ id, values }) {
  const { businessProfile } = yield select(selectPointOfSale);
  const {
    businessDetails: {
      businessName,
      address,
      addressAdditionalInfos,
      zipCode,
      city,
      country
    },
    publishersDescription,
    contact,
    hours
  } = values;

  let fieldsError = [];

  if (businessProfile.name !== businessName) {
    try {
      const { data } = yield call(updatePointOfSaleName, id, businessName);
      yield put(updateSensitiveFieldsRequestSuccessAction(data));
    } catch (e) {
      fieldsError.push("Nom du commerce");
    }
  }
  if (
    businessProfile.address !== address ||
    businessProfile.addressAdditionalInfos !== addressAdditionalInfos ||
    businessProfile.zipCode !== zipCode ||
    businessProfile.city !== city ||
    businessProfile.country !== country
  ) {
    try {
      const { data } = yield call(updatePointOfSaleAddress, id, {
        address,
        addressAdditionalInfos: addressAdditionalInfos || "",
        zipCode,
        city,
        country
      });
      yield put(updateSensitiveFieldsRequestSuccessAction(data));
    } catch (e) {
      fieldsError.push("Adresse");
    }
  }
  if (
    businessProfile.websiteUrl !==
    formatWebsiteUrl(publishersDescription.websiteUrl)
  ) {
    try {
      const { data } = yield call(
        updatePointOfSaleWebsiteUrl,
        id,
        formatWebsiteUrl(publishersDescription.websiteUrl)
      );
      yield put(updateSensitiveFieldsRequestSuccessAction(data));
    } catch (e) {
      fieldsError.push("Site internet");
    }
  }
  if (!isEqual(businessProfile.phoneNumbers, contact.phoneNumbers)) {
    try {
      const { data } = yield call(
        updatePointOfSalePhoneNumbers,
        id,
        contact.phoneNumbers
      );
      yield put(updateSensitiveFieldsRequestSuccessAction(data));
    } catch (e) {
      fieldsError.push("Numéro(s) de téléphone");
    }
  }
  if (!isEqual(businessProfile.businessHours, hours.businessHours)) {
    try {
      const { data } = yield call(
        updatePointOfSaleBusinessHours,
        id,
        hours.businessHours
      );
      yield put(updateSensitiveFieldsRequestSuccessAction(data));
    } catch (e) {
      fieldsError.push("Horaires d'ouverture");
    }
  }

  if (fieldsError.length > 0) {
    toast.error(() => (
      <div>
        <p>
          En raison de la situation actuelle, Google a rejeté votre demande de
          modification pour les champs suivants:{" "}
        </p>
        <ul>
          {fieldsError.map(field => (
            <li>{field}</li>
          ))}
        </ul>
        <p>Veuillez réessayer plus tard</p>
      </div>
    ));
  }
}

function* watchUpdateSensitiveFields() {
  yield takeEvery(UPDATE_SENSITIVE_FIELDS_REQUEST, updateSensitiveFieldsWorker);
}

function* updatePointOfSaleWorker({ id, values }) {
  try {
    const { businessProfile } = yield select(selectPointOfSale);

    if (businessProfile.isTemporarilyClosed) {
      if (values.hours.isTemporarilyClosed) {
        yield put(updateEndAction());
        return toast.warn(
          "Attention: Votre établissement est coché “ fermeture temporaire” dans la section “horaires” . Décochez cette case avant de modifier vos informations."
        );
      } else {
        yield call(
          updateIsTemporarilyClosed,
          id,
          values.hours.isTemporarilyClosed
        );
      }
    }

    const oldValues = omit(SPECIFIC_FIELD)(fromJsonToState(businessProfile));
    const newValues = omit(SPECIFIC_FIELD)(values);

    yield put(
      updateSensitiveFieldsRequestAction(id, pick(SPECIFIC_FIELD)(values))
    );

    if (!isEqual(oldValues, newValues)) {
      const { data } = yield call(updatePointOfSale, id, newValues);
      yield put(updatePointOfSaleSuccessAction(omit(SPECIFIC_FIELD)(data)));
    }

    if (values.upload) {
      yield put(uploadMediasPointOfSaleRequest(id, values.upload));
    }

    if (
      businessProfile.isTemporarilyClosed !== values.hours.isTemporarilyClosed
    ) {
      yield call(
        updateIsTemporarilyClosed,
        id,
        values.hours.isTemporarilyClosed
      );
    }

    yield put(updateEndAction());
    toast.success("Établissement mis à jour");
  } catch (e) {
    yield put(updatePointOfSaleFailure());
    yield put(reset(FORM_NAME));
    toast.error("Une erreur est survenue, veuillez réessayer plus tard");
  }
}

function* watchUpdatePointOfSale() {
  yield takeEvery(UPDATE_POINT_OF_SALE, updatePointOfSaleWorker);
}

export const PointOfSaleUpdateSagas = [
  fork(watchUpdateSensitiveFields),
  fork(watchUpdatePointOfSale)
];
