import React, { useState } from "react";
import { useSelector } from "react-redux";
import alert from "../../../../ressources/images/alert.svg";
import arrow from "../../../../ressources/images/headerCollapse.png";
import { selectUserInfo } from "../../../auth";

export const Topbar = ({ logout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userInfo = useSelector(selectUserInfo);

  const renderNotification = () => {
    return (
      <div className="headerNotif" tabIndex="99">
        <span className="selectComments">
          <img
            src={alert}
            alt="Notification"
            className="shapeHeaders"
            width={20}
            height={20}
          />
          {/*<div className="headerNotifContainer"></div>*/}
        </span>
      </div>
    );
  };

  const userName =
    `${userInfo.name} ${userInfo.surname}` ||
    userInfo.username ||
    userInfo.email;

  const renderSettings = () => (
    <div
      className="headerSettingsContainer"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <div className="headerSettings">
        <span className="selectComments">
          {userName}
          <img
            src={arrow}
            className={isMenuOpen ? "arrowSettingsOpen" : "arrowSettingsClose"}
            alt="Settings"
            width={15}
            height={10}
          />
        </span>
        {isMenuOpen && (
          <div className="headerSettingsHide">
            <div
              className="prettyContainer"
              style={{ cursor: "default", opacity: 0.3 }}
            >
              <span>Aide</span>
            </div>
            <div className="prettyContainer" onClick={logout}>
              <span>Déconnexion</span>
            </div>
            <div className="settingBox" onClick={() => setIsMenuOpen(false)} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <header className="headers">
      <div className="headerRightContainer">
        <div className="headerPlaceName">Votre espace Visiretail</div>
        <div className="headerNameContainer">{renderSettings()}</div>
      </div>
    </header>
  );
};
