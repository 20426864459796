import { call, put, takeEvery, fork } from "redux-saga/effects";
import { parseQueryParams } from "../../../helpers";
import {
  FETCH_LIST_POINT_OF_SALE_REQUEST,
  FETCH_CATEGORIES_REQUEST,
  FETCH_POINT_OF_SALE_REQUEST,
  FETCH_POINT_OF_SALE_STATS_REQUEST
} from "../constants";
import {
  fetchListPointOfSale,
  fetchCategories,
  fetchPointOfSaleStats,
  fetchPointOfSale
} from "../api";
import {
  fetchCategoriesSuccessAction,
  fetchListPointOfSaleSuccessAction,
  fetchPointOfSaleSuccessAction,
  fetchPointOfSaleStatsSuccessAction
} from "../actions";
import { toast } from "react-toastify";

function* fetchListPointOfSaleWorker({ filters }) {
  try {
    const { data } = yield call(
      fetchListPointOfSale,
      parseQueryParams(filters)
    );
    yield put(fetchListPointOfSaleSuccessAction(data));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchListPointOfSaleWorker() {
  yield takeEvery(FETCH_LIST_POINT_OF_SALE_REQUEST, fetchListPointOfSaleWorker);
}

function* fetchPointOfSaleWorker({ id, history }) {
  try {
    const { data } = yield call(fetchPointOfSale, id);
    yield put(fetchPointOfSaleSuccessAction(data));
  } catch (e) {
    toast.error(
      "Une erreur est survenue lors de la récupération de votre établissement, veuillez réessayer plus tard"
    );
    history.push("point-of-sales");
    console.error(e);
  }
}

function* watchFetchPointOfSaleWorker() {
  yield takeEvery(FETCH_POINT_OF_SALE_REQUEST, fetchPointOfSaleWorker);
}

function* fetchCategoriesWorker({ publisher }) {
  try {
    const { data } = yield call(fetchCategories, publisher);
    yield put(fetchCategoriesSuccessAction(publisher, data));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchCategoriesWorker() {
  yield takeEvery(FETCH_CATEGORIES_REQUEST, fetchCategoriesWorker);
}

function* fetchPointOfSaleStatsWorker() {
  try {
    const { data } = yield call(fetchPointOfSaleStats);
    yield put(fetchPointOfSaleStatsSuccessAction(data));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchPointOfSaleStatsWorker() {
  yield takeEvery(
    FETCH_POINT_OF_SALE_STATS_REQUEST,
    fetchPointOfSaleStatsWorker
  );
}

export const PointOfSaleFetchSagas = [
  fork(watchFetchListPointOfSaleWorker),
  fork(watchFetchPointOfSaleWorker),
  fork(watchFetchCategoriesWorker),
  fork(watchFetchPointOfSaleStatsWorker)
];
