import React from "react"
import { Field, FieldArray } from 'redux-form'
import { renderInput } from "../../../commons";
import { emailValid, required, min } from "../../../../helpers";
import icon_addLine from '../../../../ressources/images/PlacesView/icon_add_line.svg'
import cross from '../../../../ressources/images/cross.svg'

const minPhone = min(10)

const renderPhones = ({ fields }) => (
    <>
        <div className="networkDataFieldName">Téléphone(s)</div>
        {fields.map((phone, index) => (
            <div key={index} style={{display: 'flex', alignItems: 'center', marginTop: index !== 0 ? -25 : 0}}>
                <Field
                    name={phone}
                    validate={index === 0 ? [required, minPhone] : minPhone}
                    component={renderInput}
                    maxLength={10}
                    label={`Téléphone (${index+1})`}
                    isMandatory={index === 0}
                />
                {index > 0 && <img style={{cursor: "pointer", marginLeft: 10, marginTop: 25}} src={cross} alt="remove" onClick={() => fields.remove(index)}/>}
                {index === fields.length - 1 &&
                    <div style={{cursor: "pointer", marginTop: 25}} className="networkDataTimePickerAdd"  onClick={() => fields.push("")}>
                        <img src={icon_addLine} alt="add" />
                        Ajouter un numéro de téléphone
                    </div>
                }
            </div>
        ))}
    </>
)

export const PointOfSaleContact = ({ fields }) => (
    <>
        {(!fields || fields.hasEmail) &&
            <Field name="contact.email" validate={[required, emailValid]} component={renderInput} title="Adresse e-mail du propriétaire" type="email" isMandatory />
        }
        {(!fields || fields.hasPhone) &&
            <FieldArray name="contact.phoneNumbers" component={renderPhones} />
        }
    </>
);