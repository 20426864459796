import React from "react"

const STEPS = [
    "Coordonnées",
    "Description",
    "Contact",
    "Photos",
    "Horaires",
    "Paramètres avancés"
];

export const Stepper = ({ activeStep }) => (
    <ul className="stepper">
        {STEPS.map((step, index) => (
            <li key={index} className={`step ${activeStep === index + 1 ? 'active' : ''} ${activeStep > index + 1 ? 'complete' : ''}`}>
                {step}
            </li>
        ))}
    </ul>
);