import	fbLogo						from	'../../ressources/images/logo/facebookColor.svg';
import	googleLogo					from	'../../ressources/images/logo/googleColor.svg';
import	twitterLogo					from	'../../ressources/images/logo/twitterColor.svg';

import	fbLogo_Round				from	'../../ressources/images/logo/facebookRound.svg';
import	googleLogo_Round			from	'../../ressources/images/logo/googleRound.svg';
import	twitterLogo_Round			from	'../../ressources/images/logo/twitterRound.svg';

import	fbLogo_Black				from	'../../ressources/images/logo/facebookBlack.svg';
import	googleLogo_Black			from	'../../ressources/images/logo/googleBlack.svg';
import	twitterLogo_Black			from	'../../ressources/images/logo/twitterBlack.svg';

import	fbLogo_White				from	'../../ressources/images/logo/facebookWhite.svg';
import	googleLogo_White			from	'../../ressources/images/logo/googleWhite.svg';
import	twitterLogo_White			from	'../../ressources/images/logo/twitterWhite.svg';

const	Default = {
	facebook:	fbLogo,
	twitter:	twitterLogo,
	google:		googleLogo
};

const	Round = {
	facebook:	fbLogo_Round,
	twitter:	twitterLogo_Round,
	google:		googleLogo_Round
};

const	Black = {
	facebook:	fbLogo_Black,
	twitter:	twitterLogo_Black,
	google:		googleLogo_Black
};
const	White = {
	facebook:	fbLogo_White,
	twitter:	twitterLogo_White,
	google:		googleLogo_White
};

export default {Default, Round, Black, White};
