export const SET_PUBLICATION_PERIOD_FILTER = "shoki:set_publication_period_filter"
export const SET_PUBLISHER_FITLTER = "shoki:set_publisher_filter"
export const SET_POINT_OF_SALE_FILTER = "shoki:set_point_of_sale_filter"
export const RESET_FILTERS = "shoki:reset_filters"

export const FETCH_LIST_POST_REQUEST = "shoki:fetch_list_post_request"
export const FETCH_LIST_POST_SUCCESS = "shoki:fetch_list_post_success"
export const FETCH_POST_REQUEST = "shoki:fetch_post_request"
export const FETCH_POST_SUCCESS = "shoki:fetch_post_success"
export const RESET_LIST_POST = "shoki:reset_list_post"
export const CREATE_POST = "shoki:create_post";
export const DELETE_POST = "shoki:delete_post";
export const DELETE_POST_SUCCESS = "shoki:delete_post_success";
