import { shokiInstance } from "../../../api";
import { fromStateToJson } from "../helpers";

export const createPointOfSale = values =>
  shokiInstance.post(`/v1/business-profiles`, fromStateToJson(values));

export const fetchListPointOfSale = filters =>
  shokiInstance.get(`/v1/business-profiles?${filters}`);

export const fetchPointOfSale = id =>
  shokiInstance.get(`/v1/business-profiles/${id}`);

export const updatePointOfSale = (id, values) =>
  shokiInstance.put(`/v1/business-profiles/${id}`, fromStateToJson(values));

export const bulkUpdatePointOfSales = values =>
  shokiInstance.put(`/v1/business-profiles`, values);

export const uploadMediasPointOfSale = (id, values) =>
  shokiInstance.post(`/v1/business-profiles/${id}/medias`, values);

export const uploadMediasPointOfSales = values =>
  shokiInstance.post(`/v1/business-profiles/medias`, values);

export const fetchPointOfSaleStats = () =>
  shokiInstance.get(`/v1/business-profiles/states`);

export const fetchCategories = publisher =>
  shokiInstance.get(`/v1/${publisher}/categories`);

export const updatePointOfSaleName = (id, name) =>
  shokiInstance.put(`/v1/business-profiles/${id}/name`, { name });

export const updatePointOfSaleAddress = (id, address) =>
  shokiInstance.put(`/v1/business-profiles/${id}/address`, { ...address });

export const updatePointOfSaleWebsiteUrl = (id, websiteUrl) =>
  shokiInstance.put(`/v1/business-profiles/${id}/website-url`, { websiteUrl });

export const bulkUpdatePointOfSaleWebsiteUrl = values =>
  shokiInstance.put(`/v1/business-profiles/website-url`, values);

export const updatePointOfSalePhoneNumbers = (id, phoneNumbers) =>
  shokiInstance.put(`/v1/business-profiles/${id}/phone-numbers`, {
    phoneNumbers
  });

export const bulkUpdatePointOfSalePhoneNumbers = values =>
  shokiInstance.put(`/v1/business-profiles/phone-numbers`, values);

export const updatePointOfSaleBusinessHours = (id, businessHours) =>
  shokiInstance.put(`/v1/business-profiles/${id}/business-hours`, {
    businessHours
  });

export const bulkUpdatePointOfSaleBusinessHours = values =>
  shokiInstance.put(`/v1/business-profiles/business-hours`, values);

export const updatePointOfSaleIsTemporarilyClosed = (id, isTemporarilyClosed) =>
  shokiInstance.put(`/v1/business-profiles/${id}/is-temporarily-closed`, {
    isTemporarilyClosed
  });

export const bulkUpdatePointOfSaleIsTemporarilyClosed = values =>
  shokiInstance.put(`/v1/business-profiles/is-temporarily-closed`, values);
