import React from "react"
import { Field } from "redux-form";
import { renderInput, renderSelector, renderTextArea } from "../../../commons";
import { required } from "../../../../helpers";

export const PointOfSaleDescription = ({ fields, GMBCategories, FBCategories }) => (
    <>
            {(!fields || fields.hasGmbMainCategory) &&
                    <Field
                        key={`google_main_${GMBCategories.length}`}
                        name="publishersDescription.gmbMainCategory"
                        validate={required}
                        component={renderSelector}
                        title="Catégories Google My Business"
                        label="Catégorie Principale"
                        placeholder="Séléctionnez 1 catégorie..."
                        max={1}
                        options={GMBCategories}
                        isLoading={GMBCategories.length === 0}
                        isMulti
                        isVirtual
                        isMandatory/>
            }
            {(!fields || fields.hasGmbAdditionalCategories) &&
                    <Field
                        key={`google_std_${GMBCategories.length}`}
                        name="publishersDescription.gmbAdditionalCategories"
                        component={renderSelector}
                        title=""
                        label="Catégorie(s) supplémentaire(s) (9 max.)"
                        placeholder="Séléctionnez jusqu’à 9 catégories..."
                        max={9}
                        options={GMBCategories}
                        isLoading={GMBCategories.length === 0}
                        isVirtual
                        isMulti />
            }
            {(!fields || fields.hasFbCategories) &&
                    <Field
                        key={`facebook_main_${FBCategories.length}`}
                        name="publishersDescription.fbCategories"
                        validate={required}
                        component={renderSelector}
                        title="Catégories Facebook"
                        label="Catégorie(s)"
                        placeholder="Séléctionnez 1 à 3 catégories..."
                        max={3}
                        options={FBCategories}
                        isLoading={FBCategories.length === 0}
                        isMulti
                        isVirtual
                        isMandatory />
            }
            {(!fields || fields.hasWebsiteUrl) &&
                <Field name="publishersDescription.websiteUrl" validate={required} component={renderInput} title="Site internet" label="Adresse du site web" prefix="http(s)://" isMandatory />
            }
            {(!fields || fields.hasFbDescription) &&
                <Field name="publishersDescription.fbDescription" validate={required} component={renderTextArea} title="Description" label="Description courte Facebook (255 caractères max)" maxLength={255} isMandatory />
            }
            {(!fields || fields.hasGmbDescription) &&
                    <Field
                        name="publishersDescription.gmbDescription"
                        validate={required}
                        component={renderTextArea}
                        label="Description longue Google (750 caractères max)"
                        description="La description longue est la description prise en compte par Google My Business ainsi que par la plupart des plateformes de Presence Management"
                        maxLength={755}
                        isMandatory />
            }
    </>
);